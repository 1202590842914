import { brandConstants } from '../constants/brand';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function brandLookup(domain, callback){

	return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get('/brand/domain', { params: { domain } });
										
			dispatch({ 
				type: brandConstants.BRAND_DETAILS,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}