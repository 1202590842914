import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../../components/chrome/loading";
import Toolbar from "../../components/chrome/toolbar";
import Table from "../../components/chrome/table";
import EmptySection from "../../components/chrome/empty";
import _ from "lodash";
import i18n from "../../helpers/i18n";
import { checkPermission } from "../../helpers/permissions";
import { imageResize } from "../../helpers/s3";

import { fetchProductList } from "../../actions/products";
import { setSectionOptions } from "../../actions/chrome";

class Products extends Component {
  constructor(props) {
    super(props);

    // MUST HAVE CORRECT PERMISSIONS!
    if (!checkPermission(this.props.account.permissions, "PRODUCTS")) {
      this.props.history.push("/");
    }

    this.props.setSectionOptions("products", i18n.t("products:title"));
    this.props.fetchProductList();

    this.state = {
      search: false,
      filters: {
        keyword: false,
        sku: false,
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  filterData(type) {
    let products = this.props.products.products;
    let data = products;

    // APPLY FILTERS
    if (this.state.search) {
      if (this.state.filters.keyword) {
        data = _.filter(data, (product) =>
          product.title
            .toLowerCase()
            .includes(this.state.filters.keyword.toLowerCase())
        );
      }

      if (this.state.filters.sku) {
        data = _.filter(data, (product) =>
          product.sku
            .toLowerCase()
            .includes(this.state.filters.sku.toLowerCase())
        );
      }
    }

    return data;
  }

  renderTable() {
    let data = this.filterData();

    if (_.isEmpty(data)) {
      return (
        <EmptySection
          icon="fa-exclamation-circle"
          title={i18n.t("products:empty_title")}
          description={i18n.t("products:empty_description")}
          cta={{
            label: i18n.t("products:action_add"),
            url: `${this.props.location.pathname}/product`,
          }}
        />
      );
    }

    return (
      <div className="c-card">
        <Table
          columns={[
            {
              key: "image",
              label: "",
              format: "image_circle",
              className: "tight",
            },
            {
              key: "title",
              label: "Title",
              sortable: true,
              format: "image_pre",
            },
            {
              key: "brand",
              label: "Brand",
              sortable: true,
            },
            {
              key: "sku",
              label: "SKU",
              sortable: true,
            },
            {
              key: "price",
              label: "Price",
              sortable: true,
              format: "price",
            },
            {
              key: "barcode",
              label: "UPC/EAN",
              sortable: false,
            },
            {
              key: "actions",
              label: "Actions",
              className: "tight right",
            },
          ]}
          data={_.map(data, (product, key) => {
            return {
              id: product.id,
              image: {
                src: product.image
                  ? imageResize(product.image, 100, 100, "contain")
                  : false,
                icon: "fal fa-bicycle",
                alt: product.title,
              },
              title: {
                value: product.title,
                icon: product.readonly === 1 ? "fal fa-inbox-in" : false,
                tooltip: product.readonly === 1 ? "Received product" : false,
              },
              brand: product.brand,
              sku: product.sku,
              price: {
                was: product.price_sale ? product.price : false,
                value: product.price_sale ? product.price_sale : product.price,
                currency: this.props.account.group.currency,
              },
              barcode: product.barcode,
              actions: ["edit"], //product.readonly === 1 ? ['view'] : ['edit']
            };
          })}
          actions={{
            edit: {
              tooltip: i18n.t("products:action_edit"),
              icon: "fal fa-edit",
              url: `${this.props.location.pathname}/product/[ID]`,
            },
            view: {
              tooltip: i18n.t("products:action_view"),
              icon: "fal fa-eye",
              url: `${this.props.location.pathname}/product/[ID]`,
            },
          }}
          sort={{
            column: "title",
            order: "asc",
          }}
          rowClick={(row) => {
            this.props.history.push(
              `${this.props.location.pathname}/product/${row.id}`
            );
          }}
        />
      </div>
    );
  }

  render() {
    let { products } = this.props;

    if (!products.products) {
      return <Loading />;
    }

    let buttons = [];

    if (
      checkPermission(this.props.account.permissions, "PRODUCTS_ADD") &&
      checkPermission(this.props.account.permissions, "PRODUCTS_SIM")
    ) {
      buttons.push({
        icon: "fa-upload",
        url: `${this.props.location.pathname}/sku`,
        outline: true,
        tooltip: i18n.t("products:import_tooltip"),
      });

      buttons.push({
        icon: "fa-file-import",
		url: `${this.props.location.pathname}/csv`,
        outline: true,
        tooltip: i18n.t("products:import_csv_tooltip"),
      });
    }

    buttons.push({
      label: i18n.t("products:action_add"),
      url: `${this.props.location.pathname}/product`,
    });

    return (
      <React.Fragment>
        <Toolbar
          buttons={buttons}
          filter={{
            toggleFunc: (status) => {
              this.setState({
                search: status,
              });
            },
            onChangeFunc: (event, type) => {
              let filters = this.state.filters;

              switch (type) {
                case "keyword":
                  filters.keyword = event.target.value;
                  break;

                case "sku":
                  filters.sku = event.target.value;
                  break;

                default:
                  return;
              }

              this.setState({
                filters: filters,
              });
            },
            fields: [
              {
                placeholder: i18n.t("products:filter_keyword"),
                name: "keyword",
                type: "text",
              },
              {
                placeholder: i18n.t("products:filter_sku"),
                name: "sku",
                type: "text",
              },
            ],
            focus: "keyword",
          }}
        />

        <div className="container">{this.renderTable()}</div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ products, account }, ownProps) {
  return {
    products,
    account,
  };
}

export default connect(mapStateToProps, {
  fetchProductList,
  setSectionOptions,
})(Products);
