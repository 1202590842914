import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n';
import InputField from '../../components/forms/field';
import Toolbar from '../../components/chrome/toolbar';
import Loading from '../../components/chrome/loading';
import { fetchCampaignsList, saveCampaignGeneral } from '../../actions/campaigns';
import { checkPermission } from '../../helpers/permissions';
import { startSubmission, stopSubmission } from '../../actions/forms';
import { Base64 } from 'js-base64';
import _ from 'lodash';
import moment from 'moment-timezone';
import 'moment/min/locales';

var formName = 'formCampaignsSetupWarning';

class CampaignsSetupWarnings extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS_EDIT')){
			this.props.history.push('/');
		}
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push('/campaigns');
		}
		
		moment.locale(i18n.language);
		
		if(!this.props.campaigns.warnings || this.props.campaigns.warnings.id !== id){
			this.props.history.push(`/campaigns/${id}`);
		}else{
											
			this.state = {
				id: id,
				warnings: _.map(this.props.campaigns.warnings.warnings, (warning, key) => {
					warning.selected = 'after';
					return warning;
				})
			}
		}
		
		this.props.fetchCampaignsList(id, () => {
		       	
	       	if(this.props.campaigns.campaigns && !this.props.campaigns.campaigns[id]){
				this.props.history.push('/campaigns');
			}else{
				this.updateDomElements();
			}
       	});
	}
	
	updateDomElements(){
		
		if(this.props.campaigns.campaigns){
		
			let campaign = this.props.campaigns.campaigns[this.state.id];			
			
			if(campaign.status !== 'draft'){
				this.props.history.push(`/campaigns/${this.state.id}`);
			}
		}
	}
	
	onSubmit(){
		
	  	this.props.startSubmission(formName);
	  	
	  	var posting = this.props.campaigns.warnings.posted;
	  	
	  	posting.events_update = _.map(this.state.warnings, (warning, key) => {	
		  	
		  	return {
			  	id: warning.event.id,
			  	ref: warning.type,
			  	value: warning[warning.selected]
		  	}
		});
	  	
	  	this.props.saveCampaignGeneral(formName, this.state.id, posting, (ret) => {
		  	
		  	const query = require('query-string');
			const queryString = query.parse(this.props.location.search);
						
			if(queryString.ret){
			  	this.props.history.push(Base64.decode(queryString.ret));
		  	}else{
			  	this.props.history.push(this.props.parentPath);
		  	}
		});
	}
	
	selectVersion(key, which){
		
		let warnings = this.state.warnings;
		
		warnings[key].selected = which;
		
		this.setState({
			warnings: warnings
		});
	}
	
	render() {
							
		const { handleSubmit } = this.props;
		
		if(!this.props.campaigns.warnings){

			return (
				<Loading />
			);
		}

		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<OverlaySettings closeURL={this.props.parentPath} />
			
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">{i18n.t('campaigns:warnings_title')}</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={[{
						label: i18n.t('campaigns:warnings_toolbar_settings'),
						url: `${this.props.parentPath}`,
						selected: true
					}]}
					buttons={[{
						label: i18n.t('campaigns:warnings_submit'),
						submit: formName
					}]}
				/>
				
				<div className="c-card c-alert u-mb-medium c-alert--danger">
	                <span className="c-alert__icon u-mb-small u-mr-small c-alert--danger">
						<i className="fal fa-exclamation-triangle" style={{ color: '#FFF' }}></i>
					</span>
				
					<div className="c-alert__content">
						<p className="u-mb-small">{i18n.t('campaigns:warnings_intro', { count: Object.keys(this.state.warnings).length })}</p>
					</div>
				</div>
									
				{_.map(this.state.warnings, (warning, key) => {						
					
					let type = '';
					let icon = '';
					
					switch(warning.event.type){
				
						case 'twitter':
							type = i18n.t('campaigns:event_type_twitter');
							icon = 'fab fa-x-twitter';
							break;
							
						case 'facebook':
							type = i18n.t('campaigns:event_type_facebook');
							icon = 'fab fa-facebook';
							break;
							
						case 'instagram':
							type = i18n.t('campaigns:event_type_instagram');
							icon = 'fab fa-instagram';
							break;
							
						case 'email':
							type = i18n.t('campaigns:event_type_email');
							icon = 'fal fa-envelope';
							break;
							
						case 'landing':
							type = i18n.t('campaigns:event_type_landing');
							icon = 'fal fa-browser';
							break;
							
						case 'slider':
							type = i18n.t('campaigns:event_type_slider');
							icon = 'fal fa-arrows-h';
							break;	
							
						case 'advert':
							type = i18n.t('campaigns:event_type_advert');
							icon = 'fal fa-ad';
							break;		
							
						default:
							break;
					}	
					
					return (
						<div className="c-card">
							<h4 className="u-mb-medium">
								<i className={icon}></i> {type} - {warning.type}
								<div className="options">
									{moment.utc(warning.event.start.date).tz(this.props.account.profile.timezone).format('LLL')}
								</div>
							</h4>
							
							<div className="row">
								<div className="col-md-6 col-sm-12">						
									<div 
										className={`c-card clickable equalize ${this.state.warnings[key].selected === 'before' ? 'selected' : ''}`}
										onClick={() => { this.selectVersion(key, 'before'); }}
									>
										<h5 className="u-mb-medium">
											Current Version
											
											<div className="options" style={{ marginRight: "-10px" }}>
												<Field
													name={`version_before_${key}`}
													type="switch"
													selected={this.state.warnings[key].selected === 'before' ? true : false}
													onChangeFunc={(event) => { this.selectVersion(key, 'before'); } }
													component={InputField}
												/>
											</div>
										</h5>
										
										{warning.before}
									</div>
								</div>
								<div className="col-md-6 col-sm-12">
									<div 
										className={`c-card clickable equalize ${this.state.warnings[key].selected === 'after' ? 'selected' : ''}`}
										onClick={() => { this.selectVersion(key, 'after'); }}
									>
										<h5 className="u-mb-medium">
											New Version
											
											<div className="options" style={{ marginRight: "-10px" }}>
												<Field
													name={`version_after_${key}`}
													type="switch"
													selected={this.state.warnings[key].selected === 'after' ? true : false}
													onChangeFunc={(event) => { this.selectVersion(key, 'after'); } }
													component={InputField}
												/>
											</div>
										</h5>
										
										{warning.after}
									</div>
								</div>
							</div>	
						</div>
					);
				})}
			</form>	
		);
	}
}

const validate = (values) => {	
	
	const errors = {};
	
	return errors;
}

function mapStateToProps({ campaigns, account }, ownProps){
	return {
		campaigns,
		account
	};
}

export default reduxForm({
	validate,
	form: formName
})(
	connect(mapStateToProps, { 
		saveCampaignGeneral,
		fetchCampaignsList,
		startSubmission, 
		stopSubmission
	} )(CampaignsSetupWarnings)
);