import React, { Component } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import i18n from "../../helpers/i18n";
import moment from "moment";
import "moment/min/locales";

// import "react-date-range/dist/styles.css"; // main style file
// import "react-date-range/dist/theme/default.css"; // theme css file

class DatePicker extends Component {
  constructor(props) {
    super(props);
    moment.locale(i18n.language);

    this.state = {
      selected: this.props.selected || null,
      datepickerPopup: "closed",
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick(e) {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({ datepickerPopup: "closed" });
    this.props.close();
  }

  handleDateChange(date) {
    this.setState({ selected: date });
    if (this.props.onChange) {
      this.props.onChange(moment(date).toDate());
    }
  }

  handleTimeChange(time) {
    const date = moment(this.state.selected).set({
      hour: time.hour(),
      minute: time.minute(),
      second: 0,
    });
    this.setState({ selected: date });
    if (this.props.onChange) {
      this.props.onChange(date.toDate());
    }
  }

  renderOptions() {
    const options = {};
    if (this.props.minDate) options.minDate = this.props.minDate;
    if (this.props.maxDate) options.maxDate = this.props.maxDate;
    return options;
  }

  render() {
    let popup = "";
    if (this.props.popup) {
      popup = `popup ${this.props.popup}`;
    }
    const { type, alignRight, timepicker } = this.props;
    const { selected } = this.state;

    return (
      <>
        <div className={`wrapper ${popup}`}>
          <div
            className={`datepicker ${alignRight ? "right" : ""}`}
            ref={(node) => (this.node = node)}
          >
            <Datetime
              value={selected}
              onChange={this.handleDateChange}
              inputProps={{
                readOnly: true,
                style: {
                  width: "100%",
                  height: "37px",
                  paddingLeft: "15px",
                  outlineColor: "#b9c6de",
                  borderColor: "#a3b0c4",
				  borderStyle: "solid",
				  borderRadius: "4px",
				  borderWidth: "1px",
				  outlineWidth: "1px",
				  fontSize: "12px",
				  marginBottom: "12px"
                },
              }}
              timeFormat={timepicker ? "HH:mm" : false}
              isValidDate={(currentDate) => {
                const { minDate, maxDate } = this.props;
                let valid = true;
                if (minDate)
                  valid = valid && currentDate.isAfter(moment(minDate));
                if (maxDate)
                  valid = valid && currentDate.isBefore(moment(maxDate));
                return valid;
              }}
              {...this.renderOptions()}
            />
            {/* {timepicker && (
                <Datetime
                  dateFormat={false}
                  timeFormat="HH:mm"
                  value={selected}
                  onChange={this.handleTimeChange}
                  inputProps={{ readOnly: true }}
                />
              )} */}
          </div>
        </div>
      </>
    );
  }
}

export default DatePicker;
