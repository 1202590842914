import React, { Component } from 'react';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import Button from '../../components/chrome/button';
import { fetchAssetsList, saveAssetCrop } from '../../actions/assets';
import Loading from '../../components/chrome/loading';
import AssetToolbar from './toolbar';
import FileViewer from 'react-file-viewer';
import ReactPlayer from 'react-player'
import { imageUrl, imageResize } from '../../helpers/s3';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import Cropper from 'react-easy-crop'
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css'
import Toolbar from '../../components/chrome/toolbar';

const cropSizes = {"square": { "h": 1080, "w": 1080 }, "landscape": { "h": 608, "w": 1080 }, "portrait": { "h": 1350, "w": 1080 }};

class AssetsCrop extends Component {

	constructor(props){
		super(props);
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push(this.props.parentPath);
		}		
			
		let campaign = false; //todo - pass if coming from a campaign!
			
		this.state = {
			id: id,
			campaign: campaign,
			cropper: false,
			zoom: 1,
			crop: { x: 0, y: 0 },
			cropped: false,
			// sizes: JSON.parse(process.env.REACT_APP_CROP_SIZES)
			sizes: cropSizes
		}
		
		this.props.fetchAssetsList(id, () => {
		       	
	       	if(this.props.assets.assets && !this.props.assets.assets[id]){
				this.props.history.push(this.props.parentPath);
			}else if(this.props.assets.assets && this.props.assets.assets[id].type !== 'image'){
				this.props.history.push(this.props.parentPath);
			}
       	});
	}
	
	onZoom(zoom){
		this.setState({
			zoom: zoom
		});
	}
	
	onCrop(crop){

		this.setState({
			crop: crop
		});
	}
	
	onCropped(crop, pixels){
				
		this.setState({
			cropped: pixels
		});
	}
	
	renderCrop(type){
		
		const asset  = this.props.assets.assets[this.state.id];
		const crop = _.find(asset.crops, { type: type, campaign_id: null });
		const url = imageResize(asset.filename, this.state.sizes[type].w, this.state.sizes[type].h, false, false, crop ? { x: crop.x, y: crop.y, width: crop.width, height: crop.height } : false);
		
		return (		
			<div className="col-xl-4 col-md-12">
				<div className="c-card">
					<h4 className="u-mb-medium">
						{i18n.t(`assets:crop_title_${type}`)} ({crop ? "Custom" : "Auto"})
						<div className="options">							
							<i className="fal fa-external-link"
								onClick={() => { 
									window.open(url);
								}}
							></i>
						</div>
					</h4>	
											
					<img src={url} alt={asset.name} style={{ borderWidth: '1px', borderColor: '#f4f6f9', borderStyle: 'solid' }} />
				
					{!asset.readonly && 
						<div  className="u-mt-small">
				
				            <Button
								label={crop ? "Edit Crop" : "Customise Crop"}
								onClick={() => { 
									
									this.setState({
										cropper: type,
										zoom: 1,
										cropped: crop ? { x: crop.x, y: crop.y, width: crop.width, height: crop.height } : false
									});
								}}
							/>
						
							{crop && 
								<Button
									label="Remove Custom Crop"
									outline={true}
									onClick={() => { 
										this.props.saveAssetCrop(this.state.id, this.state.campaign, type, false);
									}}
								/>
							}
						</div>
					}
				</div>
			</div>
		);
	}
	
	render() {
		
		if(!this.props.assets.assets || !this.props.assets.assets[this.state.id]){
			return (
				<Loading />
			);
		}
		
		const asset = this.props.assets.assets[this.state.id];

		return (
			<React.Fragment>
				<OverlaySettings closeURL={this.props.parentPath} />								
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{asset.name}
					</h2>
				</header>
				
				{this.state.cropper && 
					<React.Fragment>
						<Toolbar
							tabs={false}
							buttons={[
								{
									label: i18n.t(`assets:crop_action_cancel`),
									outline: true,
									onClick: () => {
										this.setState({
											cropper: false
										});
									}
								},
								{
									label: i18n.t(`assets:crop_action_save`),
									onClick: () => {
										this.props.saveAssetCrop(this.state.id, this.state.campaign, this.state.cropper, this.state.cropped, () => {
											this.setState({
												cropper: false
											});
										});
									}
								}
							]}
						/>
						
						<Cropper
				            image={imageUrl(asset.filename)}
				            crop={this.state.crop}
				            zoom={this.state.zoom}
				            aspect={this.state.sizes[this.state.cropper].w/this.state.sizes[this.state.cropper].h}
				            onCropChange={this.onCrop.bind(this)}
							onCropComplete={this.onCropped.bind(this)}
				           	onZoomChange={this.onZoom.bind(this)}
				           	showGrid={false}
				           	initialCroppedAreaPixels={this.state.cropped}
				          />
						
						<div id="cta" style={{ width: '100%', height: '85px' }}>
							<Slider
								value={this.state.zoom}
								onChange={this.onZoom.bind(this)}
								min={1}
					            max={5}
					            step={0.05}
					            tooltip={false}
							/>
						</div>
					</React.Fragment>
				||
					<React.Fragment>
						
						<AssetToolbar
							id={this.state.id}
							type={asset.type}
							section="crop"
							readonly={asset.readonly === 1 ? true : false}
							crops={asset.crops.length}
							{...this.props}
						/>
						
						<div className="row">
							
							{this.renderCrop('square')}
							{this.renderCrop('landscape')}
							{this.renderCrop('portrait')}
				            
						</div>
					</React.Fragment>
				}
			</React.Fragment>
		);
	}
}

function mapStateToProps({ assets }, ownProps){
	return {
		assets
	};
}

export default connect(mapStateToProps, { 
	fetchAssetsList,
	saveAssetCrop
})(AssetsCrop);