import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { LazyImage } from "react-lazy-images";
import AssetActions from "./actions";
import Truncate from 'react-truncate-string'
import { toggleAssetHeadline } from '../../actions/assets';
import { imageResize } from '../../helpers/s3';
import ReactTooltip from 'react-tooltip';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';

import LazyLoad from 'react-lazyload';
class AssetGridItem extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			hoverGrid: false,
			hoverThumb: false
		}
	}
	
	onSelectItem(id){
		
		if(this.props.onSelectItem === 'disabled'){
			return;
		}else if(this.props.onSelectItem){
			this.props.onSelectItem(id);
		}else{
			
			let { asset } = this.props;
			let url = false;
			
			switch(asset.type){
			
				case 'article':
					url = `${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/article/${asset.id}`;
					break;
					
				case 'product':
					url = `${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/product/${asset.id}`;
					break;
					
				default:
					url = `${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/asset/${asset.id}`;
					break;
			}
			
			if(url){
				this.props.history.push(url);
			}
		}
	}
	
	displayBar(){
		
		let { asset } = this.props;
		
		let icon = 'fa-image-polaroid';
		let type = 'asset';
		let title = asset.name;
		let actions = this.props.actions ? this.props.actions : false;
		let locked = '';
		let crops = false;
		
		switch(asset.type){
			
			case 'image':
				icon = 'fal fa-image-polaroid';
				
				if(asset.crops.length > 0){
					crops = asset.crops.length;
				}
				break;
			
			case 'video':
			case 'youtube':
			case 'vimeo':
				icon = 'fal fa-play';
				break;
				
			case 'document':
				icon = 'fal fa-file-word';
				break;
				
			case 'spreadsheet':
				icon = 'fal fa-file-excel';
				break;
				
			case 'presentation':
				icon = 'fal fa-file-powerpoint';
				break;
				
			case 'pdf':
				icon = 'fal fa-file-pdf';
				break;
				
			case 'zip':
				icon = 'fal fa-file-archive';
				break;
				
			case 'article':
				icon = 'fal fa-file-alt';
				type = 'article';
				title = asset.title;
				break;
				
			case 'product':
				icon = 'fal fa-bicycle';
				type = 'product';
				title = asset.title;
				break;
			
			default:
				icon = 'fal fa-question-square';
				break;
		}
		
		
		if(asset.readonly === 1){
			
			switch(asset.type){
			
				case 'article':
				case 'product':
					locked = (
						<i className="fal fa-inbox-in"></i>
					);
					break;
				
				default:
					locked = (
						<i className="fal fa-lock"></i>
					);
					break;
			}			
		}

		return (
			<div className={`info ${this.state.hoverGrid ? 'hover' : ''}`}>
				<h4>
					<Link 
						to={`${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/${type}/${asset.id}${this.props.back ? '?b=' + this.props.back : ''}`}
						className={locked !== '' ? 'locked' : ''}
					>
						{locked}
						<Truncate text={title} truncateAt={100} />
					</Link>
				</h4>
				<div>
					
					{this.state.hoverGrid && 
						<AssetActions 
							asset={asset}
							actions={actions}
							type={type}
							back={this.props.parentPath ? this.props.back : false}
							{...this.props}
						/>
					}
					
					{crops && 
						<Link 
							to={`${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/${type}/${asset.id}/crop${this.props.back ? '?b=' + this.props.back : ''}`}
						>
							<i className="fal fa-crop-alt"></i>
						</Link>
					}
					
					<Link 
						to={`${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/${type}/${asset.id}${this.props.back ? '?b=' + this.props.back : ''}`}
					>
						<i className={icon}></i>
					</Link>
				</div>
			</div>
		);	
	}
	
	displaySub() {
		
		if(this.props.sub){
			
			return (
				<div className="sub">
					{this.props.sub}
				</div>
			);
		}
	}
	
	render(){
		
		let { asset } = this.props;
		let size = 3;
		let image = false;
		
		switch(asset.type){
			
			case 'product':
				image = imageResize(asset.image, 500, 275, 'contain');
				break;
			
			default:
				let crop = false;
				
				if(asset.crops && asset.crops.length > 0){
					crop = _.find(asset.crops, { type: 'landscape', campaign_id: null });
				}

				// const cropSizes = JSON.parse(process.env.REACT_APP_CROP_SIZES);
				const cropSizes = {"square": { "h": 1080, "w": 1080 }, "landscape": { "h": 608, "w": 1080 }, "portrait": { "h": 1350, "w": 1080 }};
								
				image = imageResize(asset.thumbnail, cropSizes.landscape.w, cropSizes.landscape.h, false, false, crop ? { x: crop.x, y: crop.y, width: crop.width, height: crop.height } : false);
				break;
		}

		if(this.props.size){
			
			switch(this.props.size){
				
				case 'small':
					size = 2;
					break;
					
				case 'large':
					size = 4;
					break;
					
				case 'xlarge':
					size = 6;
					break;
					
				default:
				
					break;
			}
		}
		
		let tip = null;
		
		if((typeof asset.headlineSelection !== 'undefined' && this.props.headlineChange) || asset.readonly === 0){
			tip = asset.headline === 1 ? i18n.t('assets:tooltip_headline_unset') : i18n.t('assets:tooltip_headline_set')
		}
		
		let animated = 'is-animated';
		
		if(typeof this.props.animated !== 'undefined' && !this.props.animated){
			animated = '';
		}
		
		const content = (
			<div 
				className={`c-card c-card-asset ${animated} ${this.props.selected ? 'selected' : ''}`}
				onMouseEnter={() => { this.setState({ hoverGrid: true })}}
				onMouseLeave={() => { this.setState({ hoverGrid: false })}}
			>					
				<div
					className="thumbnail"
					onMouseEnter={() => { this.setState({ hoverThumb: true })}}
					onMouseLeave={() => { this.setState({ hoverThumb: false })}}
				>
					{asset.type === 'image' && 
						<React.Fragment>
							<i 
								ref={ref => this.tooltip = ref}
								className={`${asset.headline === 1 ? 'fas' : 'far'} ${this.state.hoverThumb ? 'highlight' : ''} ${asset.headlineSelection || asset.readonly === 0 ? 'change' : ''} fa-shield headline`}
								onClick={() => {										
									
									if(typeof asset.headlineSelection !== 'undefined'){
										
										if(this.props.headlineChange){
											this.props.headlineChange();
										}
										
									}else if(asset.readonly === 0){
										this.props.toggleAssetHeadline(asset.id, asset.headline === 1 ? 0 : 1);
									}
										
									ReactTooltip.hide(this.tooltip);
								}}
								data-tip={tip}
							></i>
							<ReactTooltip 
								effect="solid"
							/>
						</React.Fragment>
					}
					{/* <LazyImage
						src={image}
						alt={asset.title}
						placeholder={({ imageProps, ref }) => (
							<div ref={ref} className="image selectable" alt={imageProps.alt} onClick={() => { this.onSelectItem(asset.id) }}></div>
						)}
						actual={({ imageProps }) => (
							<div className={`image ${this.props.onSelectItem !== 'disabled' ? 'selectable' : ''}`} style={{ backgroundImage: `url(${imageProps.src})` }} onClick={() => { this.onSelectItem(asset.id) }}>
								{this.state.hoverThumb && this.props.onSelectItem !== 'disabled' &&
									<div className="hover">
										<div>
											<i className={`fal ${this.props.icon ? this.props.icon : 'fa-eye'}`}></i>
										</div>
									</div>
								}
							</div>
						)}
                    /> */}
					<LazyLoad 
						height={200}
						offset={100}
						placeholder={<div className="image selectable" onClick={() => { this.onSelectItem(asset.id) }}></div>}
					>
						<div className={`image ${this.props.onSelectItem !== 'disabled' ? 'selectable' : ''}`} style={{ backgroundImage: `url(${image})` }} onClick={() => { this.onSelectItem(asset.id) }}>
							{this.state.hoverThumb && this.props.onSelectItem !== 'disabled' &&
								<div className="hover">
									<div>
										<i className={`fal ${this.props.icon ? this.props.icon : 'fa-eye'}`}></i>
									</div>
								</div>
							}
						</div>
					</LazyLoad>
				</div>
				{this.displayBar()}
				{this.displaySub()}
			</div>
		);
		
		if(this.props.cols === false){
			return content;
		}else{
			return (
				<div className={`scroller-col col-sm-6 col-lg-4 col-xl-${size}`}>
					{content}
		        </div>
			);
		}		
	}
}

function mapStateToProps({ assets }, ownProps){
	return {
		assets
	};
}

export default withRouter(connect(mapStateToProps, { 
	toggleAssetHeadline
})(AssetGridItem));