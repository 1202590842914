import React, { Component } from "react";
import { imageResize, imageUrl } from "../../helpers/s3";

// TODO: FOLLOWING IS ONLY NEEDED FOR EDITING - BE NICE TO NOT INCLUDE THESE WHEN EDIT NOT REQUIRED!
import Editor from "react-medium-editor";
//import CharacterCounter from 'react-character-counter';
//import TextareaAutosize from 'react-autosize-textarea';
import Slider from "react-slick";
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
import _ from "lodash";
import ReactPlayer from "react-player";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment-timezone";
import "moment/min/locales";
import TemplateSub from "./template";
import clip from "text-clipper";
import NumberFormat from "react-number-format";
import getSymbolFromCurrency from "currency-symbol-map";

const nl2br = require("react-nl2br");
const HtmlToReact = require("html-to-react");
const HtmlToReactParser = require("html-to-react").Parser;
const MySwal = withReactContent(Swal);

const cropSizes = {"square": { "h": 1080, "w": 1080 }, "landscape": { "h": 608, "w": 1080 }, "portrait": { "h": 1350, "w": 1080 }};
export default class Template extends Component {
	constructor(props) {
		super(props);

		this.state = {
			maxed: false,
			hover: [],
			cropSizes: cropSizes
		};

		this.slider = [];
	}

	setHover(type, status) {
		let hover = this.state.hover;

		hover[type] = status;

		this.setState({
			hover: hover,
		});
	}

	setContent(section, block, type, value) {
		if (value.value) {
			value = value.value;
		} else if (value.target) {
			value = value.target.value;
		}

		let current = this.props.data.sections;

		current[section].data[block][type] = value;

		this.props.edit.onChange("sections", current);
	}

	setConfig(section, type, value) {
		let current = this.props.data.sections;

		if (!current[section].config) {
			current[section].config = {};
		}

		current[section].config[type] = value;

		this.props.edit.onChange("sections", current);
	}

	renderBranding(html) {
		Object.entries(this.props.branding).forEach(([key, value]) => {
			if (typeof value === "object") {
				Object.entries(value).forEach(([key2, value2]) => {
					if (value2) {
						switch (key2) {
							case "logo":
							case "icon":
								if (value2.substring(0, 10) !== "data:image") {
									value2 = imageResize(value2, 500);
								}
								break;

							default:
								break;
						}

						html = html.replace(new RegExp(`{{${key}.${key2}}}`, "g"), value2);
					} else {
						html = html.replace(new RegExp(`{{${key}.${key2}}}`, "g"), "");
					}
				});
			} else {
				if (value) {
					switch (key) {
						case "logo":
						case "icon":
							if (value.substring(0, 10) !== "data:image") {
								value = imageResize(value, 500);
							}
							break;

						default:
							break;
					}

					html = html.replace(new RegExp(`{{${key}}}`, "g"), value);
				} else {
					html = html.replace(new RegExp(`{{${key}}}`, "g"), "");
				}
			}
		});

		return html;
	}

	renderCSS(html) {
		html = html.replace(
			new RegExp(`{{font_heading}}`, "g"),
			this.props.branding.font_heading.family
		);
		html = html.replace(
			new RegExp(`{{font_text}}`, "g"),
			this.props.branding.font_text.family
		);

		return html;
	}

	renderStatic(html, data) {
		// IF DATA PASSED
		if (data) {
			_.forEach(data, (value, key) => {
				switch (key) {
					case "url":
						// SHOULD NEVER BE BLANK!
						if (value === "" || value === null) {
							if (this.props.branding.url_website) {
								value = this.props.branding.url_website;
							} else {
								value = this.props.branding.url_hub;
							}
						}
						break;

					default:
						break;
				}

				html = html.replace(new RegExp(`{{${key}}}`, "g"), value);
			});
		}

		// CTA
		let cta = this.props.branding.url_hub;

		if (this.props.data.cta) {
			cta = this.props.data.cta;
		}

		html = html.replace(new RegExp(`{{url_cta}}`, "g"), cta);

		// NOW FROM MAIN
		data = this.props.data;
		let settings = JSON.parse(this.props.template.settings);

		_.forEach(settings, (value, key) => {
			html = html.replace(new RegExp(`{{${key}}}`, "g"), data[key]);
		});

		return html;
	}

	renderElement(process, type, data, node, section, block, settings) {
		const SliderNext = (props) => {
			const { style, onClick } = props;

			return (
				<i
					className={`arrow next fas fa-angle-right click-allowed`}
					style={{ ...style }}
					onClick={onClick}
				/>
			);
		};

		const SliderPrevious = (props) => {
			const { style, onClick } = props;

			return (
				<i
					className={`arrow previous fas fa-angle-left click-allowed`}
					style={{ ...style }}
					onClick={onClick}
				/>
			);
		};

		const TruncateString = (str, limit) => {
			/*if(!str || str === null || str.length === 0){
					  return '';
				  }
			  	
				  let bits, i;
			    
				  bits = str.split('');
							 
				  if (bits.length > limit) {
					  for (i = bits.length - 1; i > -1; --i) {
						  if (i > limit) {
							  bits.length = i;
						  }
						  else if (' ' === bits[i]) {
							  bits.length = i;
							  break;
						  }
					  }
					  bits.push('...');
				  }
				  
				  return bits.join('');*/

			return clip(str, limit, { html: true });
		};

		let elements = [];
		let ret = "";
		let className = "no-margins";
		let attributes = [];

		if (node) {
			_.forEach(node.attribs, (value, key) => {
				switch (key) {
					case "data-element":
						break;

					case "class":
						className = `${className} ${value}`;
						break;

					default:
						attributes[key] = value;
						break;
				}
			});
		}

		let value = false;
		settings = settings[type];

		if (settings.source_sub && !data) {
			data = this.props.data.sections[section].data[block][type];
			settings.source = settings.source_sub;
		}

		if (data) {
			// FIXED TO JUST ECHO
			value = data[settings.source];

			switch (settings.type) {
				case "image":
					let image = imageResize(
						value,
						settings.width ? settings.width : null,
						settings.height ? settings.height : null,
						settings.fit ? settings.fit : false
					);

					if (settings.background) {
						ret = (
							<div
								className="image"
								style={{ backgroundImage: `url(${image})` }}
							></div>
						);
					} else {
						if (attributes.style) {
							delete attributes.style;
						}

						let alt = "";

						if (attributes.alt) {
							alt = attributes.alt;
							delete attributes.alt;
						}

						ret = (
							<img
								src={image}
								className={`${className}`}
								alt={alt}
								{...attributes}
							/>
						);
					}

					if (data.imagePlaceholder) {
						ret = (
							<div className="placeholder_container">
								{ret}
								<div className="placeholder_label">{data.imagePlaceholder}</div>
							</div>
						);
					}

					break;

				case "url":
					ret = value;
					break;

				case "text":
					if (settings.max) {
						value = TruncateString(value, settings.max);
					}

					value = value.replace(/(?:\r\n|\r|\n)/g, "<br/>");
					ret = this.renderHtml(value);
					break;

				case "date":
					let format = "LLL";

					if (settings.format) {
						format = settings.format;
					}

					if (value && value.date) {
						value = value.date;
					}

					ret = this.renderHtml(moment.utc(value).local().format(format));
					break;

				case "price":
					ret = (
						<NumberFormat
							value={value}
							displayType="text"
							thousandSeparator={true}
							prefix={getSymbolFromCurrency(this.props.currency)}
						/>
					);
					break;

				default:
					break;
			}

			elements.push(ret);
		} else {
			// PRESET THE VALUE WE NEED
			switch (settings.type) {
				case "logo":
					value = true;
					break;

				case "links":
					if (this.props.branding.url_footer) {
						value = this.props.branding.url_footer;

						if (typeof value === "string") {
							value = JSON.parse(value);
						}
					} else {
						value = false;
					}

					break;

				case "template":
					if (this.props.data.sections[section].data[block][type].data) {
						if (
							!this.props.view &&
							this.props.data.sections[section].data[block][type].template
								.type === "slider"
						) {
							value = <iframe title="Slider" src="/slider"></iframe>;
						} else {
							value = (
								<TemplateSub
									template={
										this.props.data.sections[section].data[block][type].template
									}
									branding={this.props.branding}
									data={
										this.props.data.sections[section].data[block][type].data
									}
									child={true}
								/>
							);
						}
					}
					break;

				default:
					value = this.props.data.sections[section].data[block][type];
					break;
			}

			// IF CHECKING FOR DISPLAY ONLY, LETS RETURN HERE
			if (process === "display") {
				if (value) {
					return true;
				} else {
					return false;
				}
			}

			let selected = false;
			let i = 0;

			// DYNAMIC ELEMENTS
			switch (settings.type) {
				case "template":
					elements.push(value);
					break;

				case "logo":
					if (this.props.branding.logo) {
						if (attributes.style) {
							delete attributes.style;
						}

						let logo = this.props.branding.logo;

						if (logo.substring(0, 10) !== "data:image") {
							logo = imageResize(logo, settings.width, settings.height);
						}

						let alt = "";

						if (attributes.alt) {
							alt = attributes.alt;
							delete attributes.alt;
						}

						ret = <img src={logo} alt={alt} {...attributes} />;
					} else {
						ret = <div>{this.props.branding.name}</div>;
					}

					elements.push(ret);
					break;

				case "links":
					if (value && typeof value === "object") {
						ret = [];
						let block_data = this.props.template.blocks[settings.block];

						value.forEach((link, key) => {
							// WHAT IS THE LAYOUT FOR THIS?
							let html = block_data.html;

							html = html.replace(new RegExp("{{url}}", "g"), link.url);
							html = html.replace(new RegExp("{{label}}", "g"), link.label);

							let htmlToReactParser = new HtmlToReactParser();

							ret.push(htmlToReactParser.parse(html));
						});

						elements.push(ret);
					}
					break;

				case "image":
					let images = [];
					selected = false;
					i = 0;

					let value_image = value;
					let value_crop = false;

					if (settings.crop && settings.crop.default) {
						value_crop = settings.crop.default;
					}

					if (value && typeof value == "object") {
						value_image = value.image;

						if (value.crop) {
							value_crop = value.crop;
						}
					}

					let imageWidth = settings.width;
					let imageHeight = settings.height;

					if (value_crop) {
						if (this.state.cropSizes[value_crop]) {
							imageWidth = this.state.cropSizes[value_crop].w;
							imageHeight = this.state.cropSizes[value_crop].h;
						}
					}

					if (this.props.edit) {
						if (this.props.items && this.props.items[settings.source]) {
							this.props.items[settings.source].forEach((image, key) => {
								let imageCrop = false;

								if (value_crop && image.crops && image.crops.length > 0) {
									imageCrop = _.find(image.crops, {
										type: value_crop,
										campaign_id: null,
									});
								}

								images.push({
									id: image.id ? image.id : false,
									src: imageResize(
										image.image,
										imageWidth,
										imageHeight,
										false,
										false,
										imageCrop
											? {
												x: imageCrop.x,
												y: imageCrop.y,
												width: imageCrop.width,
												height: imageCrop.height,
											}
											: false
									),
								});

								if (value_image && value_image === image.id) {
									selected = i;
								}

								++i;
							});
						}
					} else {
						let imageCrop = false;

						if (
							value_crop &&
							value_image.crops &&
							value_image.crops.length > 0
						) {
							imageCrop = _.find(value_image.crops, {
								type: value_crop,
								campaign_id: null,
							});
						}

						images = [
							{
								src: imageResize(
									value_image.image,
									imageWidth,
									imageHeight,
									false,
									false,
									imageCrop
										? {
											x: imageCrop.x,
											y: imageCrop.y,
											width: imageCrop.width,
											height: imageCrop.height,
										}
										: false
								),
								placeholder: value_image.imagePlaceholder,
							},
						];

						selected = 0;
					}

					if (this.props.edit && this.props.edit.enabled) {
						let ref = `${section}_image_${type}`;
						this.slider[ref] = [];

						ret = (
							<div
								className={`slider-container ${settings.background ? "slider-resizer" : ""
									}`}
								onMouseEnter={() => {
									this.setHover(ref, true);
								}}
								onMouseLeave={() => {
									this.setHover(ref, false);
								}}
							>
								<Slider
									ref={(slider) => (this.slider[ref] = slider)}
									slidesToShow="1"
									slidesToScroll="1"
									fade={true}
									infinite={true}
									initialSlide={selected}
									adaptiveHeight={true}
									className={`${className} ${this.state.hover[ref] ? "hover" : ""
										}`}
									beforeChange={(current, next) => {
										setTimeout(() => {
											if (images[next]) {
												let image_data = images[next].id;

												if (value_crop) {
													image_data = {
														image: images[next].id,
														crop: value_crop,
													};
												}

												this.setContent(section, block, type, image_data);
											}
										}, 250);
									}}
									nextArrow={<SliderNext />}
									prevArrow={<SliderPrevious />}
								>
									{images.map((image, key) => {
										if (settings.background) {
											return (
												<div key={`${ref}_${key}_background`}>
													<div
														className="image"
														style={{ backgroundImage: `url(${image.src})` }}
													></div>
												</div>
											);
										} else {
											return (
												<div key={`${ref}_${key}_standard`}>
													<img src={image.src} alt="" />

													{settings.crop && settings.crop.options && (
														<div className="crop">
															{_.map(settings.crop.options, (crop, cropKey) => {
																let cropIcon = "square";

																switch (crop) {
																	case "landscape":
																	case "portrait":
																		cropIcon = `rectangle-${crop}`;
																		break;

																	default:
																		break;
																}

																return (
																	<i
																		key={cropKey}
																		className={`fal fa-${cropIcon} click-allowed version ${value_crop == crop ? "selected" : null
																			}`}
																		onClick={() => {
																			const image_data = {
																				image: value_image,
																				crop: crop,
																			};

																			this.setContent(
																				section,
																				block,
																				type,
																				image_data
																			);
																		}}
																	/>
																);
															})}
														</div>
													)}
												</div>
											);
										}
									})}
								</Slider>
							</div>
						);
					} else if (selected !== false) {
						/*ret = (
										<img 
											src={images[selected].src}
											{...attributes}
										/>
									);*/

						if (settings.background) {
							/*ret = (
											  <div className={`image ${className}`} style={{ backgroundImage: `url(${images[selected].src})` }}></div>
										  );*/

							ret = (
								<div
									className="image"
									style={{ backgroundImage: `url(${images[selected].src})` }}
								></div>
							);
						} else {
							if (attributes.style) {
								delete attributes.style;
							}

							let alt = "";

							if (attributes.alt) {
								alt = attributes.alt;
								delete attributes.alt;
							}

							ret = (
								<img
									src={images[selected].src}
									alt={alt}
									className={`${className}`}
									{...attributes}
								/>
							);
						}

						if (images[selected].placeholder) {
							ret = (
								<div className="placeholder_container">
									{ret}
									<div className="placeholder_label">
										{images[selected].placeholder}
									</div>
								</div>
							);
						}
					}

					elements.push(ret);
					break;

				case "video":
					let videos = [];
					selected = false;
					i = 0;

					if (this.props.edit) {
						this.props.items[settings.source].forEach((video, key) => {
							videos.push({
								id: video.id ? video.id : false,
								thumbnail: imageResize(
									video.thumbnail,
									settings.width,
									settings.height,
									false,
									settings.embed ? false : "play"
								),
								url: video.url,
								page: video.page,
							});

							if (value && value === video.id) {
								selected = i;
							}

							++i;
						});
					} else {
						videos = [
							{
								thumbnail: imageResize(
									value.thumbnail,
									settings.width,
									settings.height,
									false,
									settings.embed ? false : "play"
								),
								url: value.url,
								placeholder: value.imagePlaceholder,
								page: value.page,
							},
						];
						selected = 0;
					}

					if (this.props.edit && this.props.edit.enabled) {
						let ref = `${section}_video_${type}`;
						this.slider[ref] = [];

						ret = (
							<div
								className={`slider-container`}
								onMouseEnter={() => {
									this.setHover(ref, true);
								}}
								onMouseLeave={() => {
									this.setHover(ref, false);
								}}
							>
								<Slider
									ref={(slider) => (this.slider[ref] = slider)}
									slidesToShow="1"
									slidesToScroll="1"
									fade={true}
									infinite={true}
									initialSlide={selected}
									adaptiveHeight={true}
									className={`${className} ${this.state.hover[ref] ? "hover" : ""
										}`}
									beforeChange={(current, next) => {
										setTimeout(() => {
											this.setContent(section, block, type, videos[next].id);
										}, 250);
									}}
									nextArrow={<SliderNext />}
									prevArrow={<SliderPrevious />}
								>
									{videos.map((video, key) => {
										if (settings.embed) {
											let playIcon = (
												<img
													src={imageUrl("general/play.png")}
													width="75"
													className="play-button"
													alt="Play video"
												/>
											);

											return (
												<div className="player-wrapper">
													<ReactPlayer
														className="player"
														url={video.url}
														playing={true}
														controls={true}
														width={"100%"}
														height={"100%"}
														light={imageUrl(video.thumbnail)}
														config={{
															youtube: {
																playerVars: {
																	modestbranding: 1,
																},
															},
															vimeo: {
																title: false,
															},
														}}
														playIcon={playIcon}
													/>
												</div>
											);
										} else {
											return (
												<a
													href={`${this.props.branding.url_hub}${video.page}`}
													key={`${ref}_${key}_thumbnail`}
												>
													<img src={video.thumbnail} alt="" />
												</a>
											);
										}
									})}
								</Slider>
							</div>
						);
					} else if (selected !== false) {
						if (attributes.style) {
							delete attributes.style;
						}

						if (!attributes.alt) {
							attributes.alt = "";
						}

						if (settings.embed) {
							let playIcon = (
								<img
									src={imageUrl("general/play.png")}
									width="75"
									className="play-button"
									alt="Play video"
								/>
							);

							ret = (
								<div className="player-wrapper">
									<ReactPlayer
										className="player"
										url={videos[selected].url}
										playing={true}
										controls={true}
										width={"100%"}
										height={"100%"}
										light={imageUrl(videos[selected].thumbnail)}
										config={{
											youtube: {
												playerVars: {
													modestbranding: 1,
												},
											},
											vimeo: {
												title: false,
											},
										}}
										playIcon={playIcon}
									/>
								</div>
							);
						} else {
							ret = (
								<a
									href={`${this.props.branding.url_hub}${videos[selected].page}`}
								>
									<img src={videos[selected].thumbnail} alt="" />
								</a>
							);
						}

						if (videos[selected].placeholder) {
							ret = (
								<div className="placeholder_container">
									{ret}
									<div className="placeholder_label">
										{videos[selected].placeholder}
									</div>
								</div>
							);
						}
					}

					elements.push(ret);
					break;

				case "text":
					value = value.replace(/(?:\r\n|\r|\n)/g, "<br/>");

					if (value && settings.max) {
						value = TruncateString(value, settings.max);
					}

					if (this.props.edit && this.props.edit.enabled) {
						/*ret = (
										<TextareaAutosize
											name={type}
											className={className}
											onChange={(e) => {
												
												let value = e.target.value;
												let maxed = false;
												
												if(settings.max){
																							
													value = TruncateString(value, settings.max);
													
													if(value.length === settings.max){
														maxed = true;
													}
												}
																				
												this.props.edit.onChange(type, value);
											}}
											maxLength={settings.max ? settings.max : null}
											value={value}
										/>
									);*/

						let editorClass = "";
						let html = true;
						let toolbar = {
							buttons: ["bold", "italic", "underline"],
						};

						if (typeof settings.html !== "undefined") {
							if (!settings.html) {
								editorClass = "textOnly";
								html = false;
								toolbar = false;
							}
						}

						ret = (
							<Editor
								text={value}
								className={editorClass}
								options={{
									toolbar: toolbar,
									placeholder: {
										text: settings.placeholder
											? settings.placeholder
											: "Enter text",
									},
									contentWindow:
										document.getElementById("previewIframe").contentWindow,
									ownerDocument:
										document.getElementById("previewIframe").contentWindow
											.document,
									elementsContainer:
										document.getElementById("previewIframe").contentWindow
											.document.body,
								}}
								onChange={(text, medium) => {
									if (!html) {
										//let doc = new DOMParser().parseFromString(text, 'text/html');
										//text = doc.body.textContent || "";
									}

									//let maxed = false;

									if (settings.max) {
										text = TruncateString(text, settings.max);

										/*if(text.length === settings.max){
																maxed = true;
															}*/
									}

									this.setContent(section, block, type, text);
								}}
							/>
						);

						/*if(settings.max){
															
										ret = (
											<React.Fragment>
												{ret}
												<div className={`counter ${this.state.maxed ? 'maxed' : ''}`}>
													<CharacterCounter 
														value={value} 
														maxLength={parseFloat(settings.max)} 
														overrideStyle={true}
													/>
												</div>
											</React.Fragment>
										);
										
									}*/
					} else if (value) {
						ret = this.renderHtml(nl2br(value));
					}

					elements.push(ret);
					break;

				case "repeat":
					let rows = [];

					if (settings.settings.perrow.max) {
						rows = _.chunk(value, settings.settings.perrow.max);
					} else {
						rows = [value];
					}

					_.forEach(rows, (row, index) => {
						let block_id = false;

						if (!block_id) {
							_.forEach(
								settings.settings.perrow.iteration,
								(row_settings, index2) => {
									if (index2 === "odd" || index2 === "even") {
										if (index % 2 == 0) {
											if (index2 === "even") {
												block_id = row_settings.block;
											}
										} else if (index2 === "odd") {
											block_id = row_settings.block;
										}
									} else if (index2 <= row.length) {
										block_id = row_settings.block;
									}
								}
							);
						}

						let cols = 2;

						if (settings.settings.perrow.max) {
							cols = settings.settings.perrow.max;
						}

						let colspan = 1;
						let rowRet = [];

						if (
							settings.settings.perrow.max &&
							settings.settings.perrow.max > row.length
						) {
							colspan = settings.settings.perrow.max;
							cols = 1;
						}

						_.forEach(row, (col, key) => {
							let ref = `${section}_${type}_${index}_${key}`;

							if (this.props.edit) {
								this.slider[ref] = [];
							}

							// HERE WE DEAL WITH SUB BLOCKS AND THEIR CONTENT
							let block_data = this.props.template.blocks[block_id];
							let block_settings = JSON.parse(block_data.settings);
							let block_dom = document.createElement("tr"); // SET AS TR, AS LI AND TD WORK UNDER THIS!
							let block_html = block_data.html;

							block_dom.innerHTML = block_html;
							block_html = block_dom.firstChild.innerHTML;

							let selected = 0;
							let slide = "";
							let slideFlat = false;

							if (this.props.edit) {
								if (this.props.items[settings.source]) {
									selected = parseFloat(
										_.findKey(this.props.items[settings.source], { id: col })
									);
									slideFlat = this.props.items[settings.source][selected];
								}
							} else {
								slideFlat = col;
							}

							if (this.props.edit && this.props.edit.enabled) {
								if (this.props.items && this.props.items[settings.source]) {
									slide = (
										<div
											className={`grid slider-container ${className}`}
											onMouseEnter={() => {
												this.setHover(ref, true);
											}}
											onMouseLeave={() => {
												this.setHover(ref, false);
											}}
										>
											<Slider
												ref={(slider) => (this.slider[ref] = slider)}
												slidesToShow="1"
												slidesToScroll="1"
												fade={true}
												infinite={true}
												initialSlide={selected}
												adaptiveHeight={true}
												className={`${className} ${this.state.hover[ref] ? "hover" : ""
													}`}
												beforeChange={(current, next) => {
													setTimeout(() => {
														let items =
															this.props.data.sections[section].data[block][
															type
															];
														items[key] =
															this.props.items[settings.source][next].id;
														this.setContent(section, block, type, items);
													}, 250);
												}}
												nextArrow={<SliderNext />}
												prevArrow={<SliderPrevious />}
											>
												{this.props.items[settings.source].map((item, key2) => {
													return (
														<div className="item" key={`${ref}_${key2}_repeat`}>
															{value.length && (
																<i
																	className="delete fas fa-trash-alt click-allowed"
																	onClick={() => {
																		let items =
																			this.props.data.sections[section].data[
																			block
																			][type];
																		items.splice(key, 1);
																		this.setContent(
																			section,
																			block,
																			type,
																			items
																		);
																	}}
																/>
															)}

															{this.parseHtml(
																block_html,
																section,
																block,
																block_settings.data,
																item,
																false,
																ref
															)}
														</div>
													);
												})}
											</Slider>
										</div>
									);
								}
							} else {
								slide = (
									<div className={`grid slider-container ${className}`}>
										<div className="item">
											{this.parseHtml(
												block_html,
												section,
												block,
												block_settings.data,
												slideFlat,
												false,
												ref
											)}
										</div>
									</div>
								);
							}

							let parentAtts = {};

							_.forEach(block_dom.firstChild.attributes, (att, index) => {
								parentAtts[att.nodeName] = this.renderBranding(att.nodeValue);
							});

							switch (block_dom.firstChild.tagName.toLowerCase()) {
								case "td":
								case "th":
									if (!parentAtts.colspan) {
										parentAtts.colspan = "";
									}

									parentAtts.colspan = colspan;

									if (block_dom.firstChild.tagName.toLowerCase() === "th") {
										ret = (
											<th key={ref} {...parentAtts}>
												{slide}
											</th>
										);
									} else {
										ret = (
											<td key={ref} {...parentAtts}>
												{slide}
											</td>
										);
									}
									break;

								default:
									let liClass = `cols-${cols}`;

									if (parentAtts.className) {
										liClass = `${parentAtts.className} ${liClass}`;
									}

									ret = (
										<li key={ref} {...parentAtts} className={liClass}>
											{slide}
										</li>
									);
									break;
							}

							rowRet.push(ret);
						});

						elements.push(rowRet);
					});
					break;

				default:
					break;
			}
		}

		if (node) {
			return elements;
		} else {
			return true;
		}
	}

	renderOptions(section) {
		let options = [];

		/*options.push({
				icon: 'fa-arrows-alt',
				onClick: () => { alert('draggable blocks coming soon!')}
			});*/

		/*options.push({
				icon: 'fa-trash-alt',
				onClick: () => { alert('delete blocks coming soon!')}
			});	*/

		let sectionData = this.props.data.sections[section];
		let settings = JSON.parse(this.props.template.settings);

		_.forEach(settings.sections[sectionData.section].blocks, (block, id) => {
			_.forEach(block, (value, key) => {
				switch (value.type) {
					case "repeat":
						options.push({
							icon: "fa-plus",
							onClick: () => {
								let items =
									this.props.data.sections[section].data[id][value.source];
								items.push(this.props.items[value.source][0].id);
								this.setContent(section, id, value.source, items);
							},
						});
						break;

					default:
						break;
				}
			});
		});

		let manageSections = false; // TODO: this toggles the options to add sections above and below!

		if (settings.sections[sectionData.section].config) {
			options.push({
				icon: "fa-cog",
				onClick: () => {
					let html = "";

					_.forEach(
						settings.sections[sectionData.section].config,
						(config, key) => {
							switch (config.type) {
								case "select":
									/*let selected = false;
											  	
												  if(this.props.data.sections[section].config && this.props.data.sections[section].config[key]){
													  selected = this.props.data.sections[section].config[key];									
												  }else if(config.default){
													  selected = config.default;
												  }*/

									let selected = false;

									if (config.default) {
										selected = config.default;
									}

									if (
										this.props.data.sections[section].config &&
										this.props.data.sections[section].config[key]
									) {
										selected = this.props.data.sections[section].config[key];
									}

									html = (
										<React.Fragment>
											{html}

											<label className="c-field__label">{config.label}</label>

											<select
												onChange={(e) => {
													this.setConfig(section, key, e.target.value);
												}}
												className={`u-mb-small c-input`}
											//value={selected}
											>
												{_.map(config.options, (option, key2) => {
													return (
														<option
															value={option.value}
															key={`select_options_${key2}`}
															selected={
																selected && selected === option.value
																	? true
																	: false
															}
														>
															{option.label}
														</option>
													);
												})}
											</select>
										</React.Fragment>
									);
									break;

								default:
									break;
							}
						}
					);

					MySwal.fire({
						title: "Settings",
						html: html,
						showCancelButton: false,
						confirmButtonText: "Finish",
						focusConfirm: false,
					}).then((result) => {
						if (result.value) {
						}
					});
				},
			});
		}

		return (
			<React.Fragment>
				<div className="edit-options">
					{this.state.hover.section === section && options.length > 0 && (
						<div className="section-general">
							{options.map((option, key) => {
								return (
									<i
										key={`option_${key}`}
										className={`fas ${option.icon} click-allowed`}
										onClick={option.onClick}
									/>
								);
							})}
						</div>
					)}

					{manageSections && (
						<React.Fragment>
							<div className="section-prepend">
								<i
									className="fas fa-plus-square click-allowed"
									onClick={() => {
										alert("add section above coming soon!");
									}}
								/>
							</div>
							<div className="section-append">
								<i
									className="fas fa-plus-square click-allowed"
									onClick={() => {
										alert("add section above coming soon!");
									}}
								/>
							</div>
						</React.Fragment>
					)}
				</div>
			</React.Fragment>
		);
	}

	renderHtml(html) {
		if (
			Array.isArray(html) &&
			html.length === 1 &&
			typeof html[0] === "string"
		) {
			html = html[0]; // Convert the array with a single string item to a string
		}
		if (typeof html !== "string") {
			console.error("Invalid HTML content:", html);
			return <div>Invalid HTML content</div>;
		}

		try {
			const htmlToReactParser = new HtmlToReactParser();
			const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(
				React
			);

			const processingInstructions = [
				{
					shouldProcessNode: () => true,
					processNode: processNodeDefinitions.processDefaultNode,
				},
			];

			return htmlToReactParser.parseWithInstructions(
				html,
				() => true,
				processingInstructions
			);
		} catch (error) {
			console.error("Error rendering HTML:", error);
			return <div>Error rendering HTML</div>;
		}
	}

	parseHtml(html, section, block, settings, data, wrap, ref) {
		// APPLY BRANDING
		html = this.renderBranding(html);

		// APPLY CSS
		html = this.renderCSS(html);

		// REPLACE ANY STATIC DATA (ie. no editor needed)
		html = this.renderStatic(html, data);

		// REMOVE ALL LINKS
		/*html = html.replace(/href="(.*?)"/g, function(m, $1) {
				return 'href="javascript:;"';
			});*/

		// REMOVE WHITE SPACE
		html = html.replace(/>\s+</g, "><");

		let item;
		let processingInstructions = [];
		let _react = this;

		// FIND ALL ELEMENTS
		let regex = /data-element=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/gim;

		while ((item = regex.exec(html)) !== null) {
			if (item.index === regex.lastIndex) {
				regex.lastIndex++;
			}

			item.forEach((match, groupIndex) => {
				if (groupIndex === 1) {
					processingInstructions.push({
						replaceChildren: false,
						shouldProcessNode: function (node) {
							let valid = false;

							if (
								_react.renderElement(
									"display",
									match,
									data,
									node,
									section,
									block,
									settings
								)
							) {
								valid = true;
							}

							return (
								node.attribs && node.attribs["data-element"] === match && valid
							);
						},
						processNode: function (node, children, index) {
							let className = [];

							let options = {
								key: `${match}_${index}`,
							};

							if (ref) {
								options.key = `${ref}_${options.key}`;
							}

							if (node.attribs) {
								if (node.attribs.class) {
									className.push(node.attribs.class);
								}
							}

							if (_react.props.edit) {
								if (_react.props.edit.errors.includes(match)) {
									className.push("error");
								}
							}

							if (className.length > 0) {
								options.className = className.join(" ");
							}

							let elements = _react.renderElement(
								"replace",
								match,
								data,
								node,
								section,
								block,
								settings
							);

							return elements.map((element, key) => {
								return React.createElement(node.tagName, options, element);
							});
						},
					});
				}
			});
		}

		let htmlToReactParser = new HtmlToReactParser();
		let processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

		processingInstructions.push({
			shouldProcessNode: function (node) {
				return true;
			},
			processNode: processNodeDefinitions.processDefaultNode,
		});

		let htmlComponent = htmlToReactParser.parseWithInstructions(
			html,
			() => {
				return true;
			},
			processingInstructions
		);

		let sectionClass = "";

		if (this.props.data.sections && this.props.data.sections[section]) {
			let sectionSettings = JSON.parse(this.props.template.settings);
			let sectionName = this.props.data.sections[section].section;

			if (
				sectionSettings.sections[sectionName] &&
				sectionSettings.sections[sectionName].config
			) {
				_.forEach(
					sectionSettings.sections[sectionName].config,
					(config, key) => {
						let selected = false;

						if (
							this.props.data.sections[section].config &&
							this.props.data.sections[section].config[key]
						) {
							selected = _.filter(config.options, {
								value: this.props.data.sections[section].config[key],
							});
						} else if (config.default) {
							selected = _.filter(config.options, { value: config.default });
						}

						if (selected && selected[0].class) {
							sectionClass = `${sectionClass} ${selected[0].class}`;
						}
					}
				);
			}
		}

		if (this.props.edit && this.props.edit.enabled && wrap) {
			let className = "";

			if (this.state.hover.section) {
				if (this.state.hover.section === section) {
					className = "hover";
				} else {
					className = "opaque";
				}
			}

			return (
				<section
					onMouseEnter={() => {
						this.setHover("section", section);
					}}
					onMouseLeave={() => {
						this.setHover("section", false);
					}}
					className={`${className} ${sectionClass}`}
				>
					{this.renderOptions(section)}
					{htmlComponent}
				</section>
			);
		} else if (sectionClass !== "") {
			return <div className={sectionClass}>{htmlComponent}</div>;
		} else {
			return <React.Fragment>{htmlComponent}</React.Fragment>;
		}
	}

	getBlockSettings(id, section) {
		let settings = JSON.parse(this.props.template.settings);
		let block = this.props.template.blocks[id];
		let block_settings = settings.sections[section].blocks[id];

		if (block.settings) {
			_.forEach(JSON.parse(block.settings), (value2, key2) => {
				if (block_settings[key2]) {
					block_settings[key2] = Object.assign(block_settings[key2], value2);
				} else {
					block_settings[key2] = value2;
				}
			});
		}

		return block_settings;
	}

	render() {
		let settings = JSON.parse(this.props.template.settings);
		let data = this.props.data;
		let ret = [];
		let css_inline = [];

		if (this.props.template.css.length > 0) {
			css_inline.push(this.props.template.css);
		}

		let sections = data.sections; // TODO, IF NOT SET, WE CAN PRELOAD WITH EMPTY FOR ADDING 'NEW' EVENTS IN FUTURE

		_.forEach(sections, (section, key) => {
			_.forEach(section.data, (value, id) => {
				let block = this.props.template.blocks[id];
				let block_settings = this.getBlockSettings(id, section.section); //settings.sections[section.section].blocks[id];

				/*if(block.settings){
																			
							_.forEach(JSON.parse(block.settings), (value2, key2) => {
							
								if(block_settings[key2]){
									block_settings[key2] = Object.assign(block_settings[key2], value2);
								}else{
									block_settings[key2] = value2;
								}
							});
						}*/

				let block_html = block.html;
				let item;
				let _react = this;

				// CHECK ACTIVE ELEMENTS BEFORE INCLUDING
				let includeSection = true;
				let includeSectionFallback = false;

				if (!settings.sections[section.section].fixed) {
					let regex =
						/data-element=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/gim;
					let processingInstructions = [];

					if (regex.exec(block_html) !== null) {
						regex = /data-element=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/gim;

						while ((item = regex.exec(block_html)) !== null) {
							includeSection = true;

							if (item.index === regex.lastIndex) {
								regex.lastIndex++;
							}

							item.forEach((match, groupIndex) => {
								if (groupIndex === 1) {
									processingInstructions.push({
										replaceChildren: false,
										shouldProcessNode: function (node) {
											let valid = false;

											if (
												_react.renderElement(
													"display",
													match,
													false,
													node,
													key,
													id,
													block_settings
												)
											) {
												valid = true;
											} else if (
												block_settings[section.section] &&
												block_settings[section.section].fallback
											) {
												valid = true;
												includeSectionFallback =
													block_settings[section.section].fallback;
											}

											return (
												node.attribs &&
												node.attribs["data-element"] === match &&
												!valid
											);
										},
										processNode: function (node, children, index) {
											includeSection = false;
											return "";
										},
									});
								}
							});
						}
					} else {
						includeSection = true;
					}

					let htmlToReactParser = new HtmlToReactParser();
					let processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(
						React
					);

					processingInstructions.push({
						shouldProcessNode: function (node) {
							return true;
						},
						processNode: processNodeDefinitions.processDefaultNode,
					});

					htmlToReactParser.parseWithInstructions(
						block_html,
						() => {
							return true;
						},
						processingInstructions
					);
				}

				if (includeSection) {
					if (includeSectionFallback) {
						block = this.props.template.blocks[includeSectionFallback];
						block_settings = this.getBlockSettings(
							includeSectionFallback,
							section.section
						);
						block_html = block.html;
					}

					if (block.css !== "") {
						css_inline.push(block.css);
					}

					ret.push(
						this.parseHtml(
							block_html,
							key,
							id,
							block_settings,
							false,
							section.section,
							key
						)
					);
				}
			});
		});

		// LOAD IN FONTS
		let css = "";

		if (!this.props.child) {
			css += `<link href="${this.props.branding.font_heading.url}" rel="stylesheet">`;

			if (
				this.props.branding.font_heading.id !== this.props.branding.font_text.id
			) {
				css += `<link href="${this.props.branding.font_text.url}" rel="stylesheet">`;
			}

			if (this.props.edit && this.props.edit.enabled) {
				css +=
					'<link href="//pro.fontawesome.com/releases/v5.15.4/css/all.css" type="text/css" media="all" rel="stylesheet" integrity="sha384-rqn26AG5Pj86AF4SO72RK5fyefcQ/x32DNQfChxWvbXIyXFePlEktwD18fEz+kQU" crossorigin="anonymous">';
				css +=
					'<link href="//cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick.min.css" type="text/css" media="all" rel="stylesheet">';
				css +=
					'<link href="//cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick-theme.css" type="text/css" media="all" rel="stylesheet">';
				css +=
					'<link href="//cdnjs.cloudflare.com/ajax/libs/medium-editor/5.23.3/css/medium-editor.min.css" type="text/css" media="all" rel="stylesheet">';
				css +=
					'<link href="//cdnjs.cloudflare.com/ajax/libs/medium-editor/5.23.3/css/themes/default.min.css" type="text/css" media="all" rel="stylesheet">';
			}
		}

		if (css_inline.length > 0) {
			css_inline = this.renderCSS(css_inline.join("\r\n"));
			css += `<style type="text/css"> ${css_inline} </style>`;
		}

		if (this.props.css) {
			this.props.css.forEach((value) => {
				css += `<link href="${value}" type="text/css" media="all" rel="stylesheet">`;
			});
		}

		ret.unshift(this.parseHtml(css));
		return ret;
	}
}
