import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import Loading from '../../components/chrome/loading';
import CampaignsToolbar from './toolbar';
import { fetchCampaignsList, launchCampaign, saveCampaignEvent } from '../../actions/campaigns';
import { setSectionOptions } from '../../actions/chrome';
import _ from 'lodash';
import moment from 'moment-timezone';
import 'moment/min/locales';
import Table from '../../components/chrome/table';
import ReactTooltip from 'react-tooltip';
import { dateString } from '../../helpers/dates';

import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';

import EmptySection from '../../components/chrome/empty';
import Swal from 'sweetalert2';
import { showNotification } from '../../actions/notifications';
import CountUp from 'react-countup';
import { imageResize } from '../../helpers/s3';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import { checkPermission } from '../../helpers/permissions';

class CampaignsSchedule extends Component {

	calendarRef = React.createRef();
	
	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS_EDIT')){
			this.props.history.push('/');
		}
		
		moment.locale(i18n.language);
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push('/campaigns');
		}		
		
		this.timedRefresh = false;
	
		this.state = {
			id: id,
			events: [],
			view: 'schedule',
			readonly: true,
			issues: false,
			scrolled: false,
			title: '',
			hover: false
		}		
	}

	componentDidMount(){
		window.scrollTo(0,0);
				
		this.props.fetchCampaignsList(this.state.id, () => {
		    
	       	if(this.props.campaigns.campaigns && !this.props.campaigns.campaigns[this.state.id]){
				this.props.history.push('/campaigns');
			}else{
				this.updateDomElements(true);
			}
       	});
       	       			
        this.updateDomElements(false);
	}
	
	componentDidUpdate(){
		
		if(this.props.campaigns.campaigns && this.props.campaigns.campaigns[this.state.id]){
			
			const campaign = this.props.campaigns.campaigns[this.state.id];
			
			_.forEach(campaign.events, (event, key) => {		
				
				if(!event.screenshot && !this.timedRefresh){
			
					// PULL IN LATEST EVENTS ARRAY
					this.timedRefresh = setTimeout(() => {
						this.props.fetchCampaignsList(this.state.id);
						this.timedRefresh = false;
					}, 20000);
				}
			});			
		}
	}
	
	componentWillUnmount(){
		clearInterval(this.timedRefresh);
	}
	
	updateDomElements(loaded){
				
		if(this.props.campaigns.campaigns && this.props.campaigns.campaigns[this.state.id]){
			
			const campaign = this.props.campaigns.campaigns[this.state.id];	
									
			if(campaign.content.length > 0){
				
				let required = [];
				let social = _.find(campaign.distribution, { type: 'social' });
				
				if(_.find(campaign.distribution, { type: 'email' })){
					required.push('email');
				}
				
				if(social){
										
					if(!social.settings || social.settings.facebook){
						required.push('facebook');
					}
					
					if(!social.settings || social.settings.instagram){
						required.push('instagram');
					}
					
					if(!social.settings || social.settings.twitter){
						required.push('twitter');
					}
				}
				
				_.forEach(campaign.content, (value, key) => {
					
					let content = JSON.parse(value.content);
					
					_.forEach(required, (req, key2) => {
						
						if(!content[req] && loaded){
							this.props.history.push(`/campaigns/${this.state.id}/content`);
						}
					});
					
				});
								
			}else if(loaded){
				this.props.history.push(`/campaigns/${this.state.id}/content`);
			}
			
			this.props.setSectionOptions('campaigns', campaign.internal_name, `/campaigns/${this.state.id}/schedule`);
			
			let issues = []

			if(!_.isEmpty(campaign.events)){	
				
				_.forEach(campaign.events, (event, key) => {		
					if(event.issues){
						issues.push(event.id);
					}
				});
			}
			
			this.setState({
				readonly: campaign.status === 'draft' ? false : true,
				issues: issues.length > 0 ? true : false
			});		
			
		}else{
			this.props.setSectionOptions('campaigns', i18n.t('campaigns:schedule_title'), `/campaigns/${this.state.id}/assets`);
		}
	}
	
	setView(view){
		
		if(this.calendarRef.current){
		
			switch(view){
				
				case 'month':
					this.calendarRef.current.getApi().changeView('dayGridMonth');
					break;
					
				case 'week':
					this.calendarRef.current.getApi().changeView('dayGridWeek');
					break;
					
				case 'schedule':
					this.calendarRef.current.getApi().changeView('customTimeline');
					break;
					
				default:
				
					break;
			}
		}
						
		this.setState({
			view: view
		});
	}
	
	renderSchedule(){
		
		let campaign = this.props.campaigns.campaigns[this.state.id];
		let issues = [];

		if(!_.isEmpty(campaign.events)){
		
			let start = false;
	
			// WHEN CAN WE ALLOW EDIT/PLACEMENTS?
			if(moment.utc().format('YYYY-MM-DD') > moment.utc(campaign.date_start).tz(this.props.account.profile.timezone).format('YYYY-MM-DD')){
				start = moment().startOf('day').toDate();
			}else{
				start = moment.utc(campaign.date_start).tz(this.props.account.profile.timezone).toDate()
			}
					
			let events = [];
			let eventsPrepend = [{
				groupId: 'future',
				start: start,
				end: moment.utc(campaign.date_end).tz(this.props.account.profile.timezone).add(1, 'day').toDate(), // we always allow to midnight
	        },
	        {
				groupId: 'past',
				start: moment.utc(campaign.date_start).tz(this.props.account.profile.timezone).toDate(),
				end: moment().subtract(1, 'day').toDate(),
				rendering: 'background',
				overlap: false,
				color: '#CCC'
	        }];
	        
	        let calendar = '';  
			let resources = [];
			let type = '';
			let end = '';
			let color = '';
			let startEditable = false;
			let durationEditable = false;
			let icon = '';
			let group = '';

			_.forEach(campaign.events, (event, key) => {
							
				switch(event.type){
					
					case 'twitter':
						type = i18n.t('campaigns:event_type_twitter');
						//color = '#38A1F3';
						icon = 'fab fa-x-twitter';
						group = 'social';
						break;
						
					case 'facebook':
						type = i18n.t('campaigns:event_type_facebook');
						//color = '#4267b2';
						icon = 'fab fa-facebook';
						group = 'social';
						break;
						
					case 'instagram':
						type = i18n.t('campaigns:event_type_instagram');
						//color = '#231F20';
						icon = 'fab fa-instagram';
						group = 'social';
						break;
						
					case 'email':
						type = i18n.t('campaigns:event_type_email');
						//color = '#BD081C';
						icon = 'fal fa-envelope';
						group = 'email';
						break;
						
					case 'landing':
						type = i18n.t('campaigns:event_type_landing');
						///color = '#5F9EA0';
						icon = 'fal fa-browser';
						group = 'landing';
						break;
						
					case 'slider':
						type = i18n.t('campaigns:event_type_slider');
						//color = '#5F9EA0';
						icon = 'fal fa-arrows-h';
						group = 'website';
						break;	
						
					case 'advert':
						type = i18n.t('campaigns:event_type_advert');
						//color = '#5F9EA0';
						icon = 'fal fa-ad';
						group = 'website';
						break;		
						
					default:
						type = 'Unknown';
						//color = '#CCCCCC';
						icon = '';
						group = 'other';
						break;
				}	
				
				switch(event.status){
					
					case 'pending':
						color = '#f5a155';
						break;
						
					case 'active':
						color = '#28c76f';
						break;
						
					case 'failed':
						color = '#ee4343';
						break;
						
					case 'complete':
						color = '#768093';
						break;		
						
					default:
					
						break;			
				}
				
				switch(event.type){
					
					case 'landing':
					case 'slider':
					case 'advert':
						durationEditable = true;
						end = moment(event.end).format('YYYY-MM-DD HH:mm:ss');
						break;		
						
					default:
						durationEditable = false;
						end = false;
						break;
				}
	
				if(moment.utc() > moment.utc(event.start).tz(this.props.account.profile.timezone)){
					startEditable = false;
				}else{
					startEditable = true;
				}
				
				let group_name = '';
				let order = 100;
				
				switch(group){
					
					case 'social':
						group_name = 'Social Media Posts';
						order = 1;
						break;
						
					case 'website':
						group_name = 'On-Site Advertising';
						order = 5;
						break;
						
					case 'email':
						group_name = 'E-mail Marketing';
						order = 2;
						break;
						
					case 'landing':
						group_name = 'Responsive Landing Page';
						order = 3;
						break;
						
					default:
						group_name = 'Other';
						break;
				}
				
				if(!_.find(resources, { id: event.type })){
					resources.push({
						id: event.type,
						title: type,
						group: group_name,
						icon: icon,
						order: parseFloat(order)
					});
				}
												
				// WE SET AS FALSE, SINCE DAY BY DAY VIEW IS A LITTLE CLUNKY!
				//startEditable = false;
				//durationEditable = false;
								
				events.push({ 
					id: event.id, 
					title: type, 
					start: moment(event.start).format('YYYY-MM-DD HH:mm:ss'), 
					end: end, 
					resourceId: event.type,
					type: event.type,
					status: event.status,
					color: color,
					startEditable: startEditable,
					durationEditable: durationEditable,
					constraint: {
						resourceIds: [event.type],
						groupId: 'future'
	      			},
	      			icon: icon,
	      			issues: event.issues ? event.issues : false,
	      			screenshot: event.screenshot ? event.screenshot : false
				});	
				
				if(event.issues){
					
					if(event.status === 'pending'){
						issues.push(event.id);
					}
				}
			});
											
			if(this.state.view === 'schedule' || this.state.view === 'month' || this.state.view === 'week'){
				
				let initialView = 'customTimeline';
				
				switch(this.state.view){
			
					case 'month':
						initialView = 'dayGridMonth';
						break;
						
					case 'week':
						initialView = 'dayGridWeek';
						break;
						
					case 'schedule':
						initialView = 'customTimeline';
						break;
					
					default:
					
						break;
				}	
				
				const range = {
					start: moment.utc(campaign.date_start).tz(this.props.account.profile.timezone).format('YYYY-MM-DD'),
					end: moment.utc(campaign.date_end).tz(this.props.account.profile.timezone).add(1, 'day').format('YYYY-MM-DD')
				}
											
				calendar = (
					<FullCalendar 
						ref={this.calendarRef}
						now={moment().tz(this.props.account.profile.timezone).toDate()}
						schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_SCHEDULER_LICENSE}
						initialView={initialView}
						views={{
							customTimeline: {
						      	type: 'resourceTimeline',
							  	slotDuration: { days: 1 },
							  	slotLabelInterval: { days: 1 }
						    }
						}}
						headerToolbar={false}
						visibleRange={range}
						validRange={range}
						plugins={[
							resourceTimelinePlugin,
							dayGridPlugin, 
							interactionPlugin
						]}
						//resourceGroupField="group"
						expandRows={true}
						nowIndicator={false}
						editable={true}
						eventClick={(info)=> { 
							
							if(info.event.id){
								this.props.history.push(`${this.props.location.pathname}/event/${this.state.id}/${info.event.id}`);
							}
						}}
						eventDrop={(info) => {							
							this.props.saveCampaignEvent(false, info.event._def.publicId, { date_start: moment(info.event.start).format('YYYY-MM-DD HH:mm') });
						}}
						slotLabelDidMount={(info) => {
							document.querySelectorAll(".fc-timeline-header-row a").forEach(a => a.outerHTML = `<div class="fc-timeline-slot-cushion">${a.innerHTML}</div>`);
						}}
						eventDisplay='block'
						resourceAreaWidth="180px"
						resourceAreaHeaderContent={i18n.t('campaigns:schedule_resource_event_title')}
						resources={resources}	
						resourceOrder="order,title"
						resourceLabelContent={(info) => {
													
							let resourceIcon = document.createElement('i');
							resourceIcon.className = info.resource.extendedProps.icon;
							
							let resourceTitle = document.createElement('span');
							resourceTitle.innerHTML = info.resource.title;
							
							/*if(!this.state.readonly){
								
								let addEvent = document.createElement('i');
						        addEvent.className = 'fal fa-plus-square';
						        addEvent.onclick = () => { 
									this.props.history.push(`${this.props.location.pathname}/event/${this.state.id}?type=${info.resource.id}`);
							    }
							    
							    // TODO: Enable this when add events possible
						        //info.el.querySelector('.fc-cell-text').prepend(addEvent);	
						        
						        let iconEvent = document.createElement('i');
						        iconEvent.className = info.resource.extendedProps.icon;
						        info.el.querySelector('.fc-cell-text').prepend(iconEvent);					        
					        }*/
					        											
							return { domNodes: [ resourceIcon, resourceTitle ] } 
						}}	
						resourceLabelDidMount={() => {
							
							if(!this.state.scrolled){
						       					    
								setTimeout(() => {
									
									this.setState({
										scrolled: true
									});
									
									let scrollTo = document.getElementsByClassName('fc-time-area');
									
									if(scrollTo.length > 0){
										
										scrollTo = scrollTo[0].getElementsByClassName('fc-today');
									
										if(scrollTo.length === 0){
											scrollTo = 0;
										}else if(typeof scrollTo !== 'undefined'){
											scrollTo = scrollTo[0].offsetLeft;
										}else{
											scrollTo = document.getElementsByClassName('fc-time-area')[0].getElementsByClassName('fc-widget-header');
											scrollTo = document.getElementsByClassName('fc-time-area')[0].getElementsByClassName('fc-widget-header')[scrollTo-1];
										}
																				
										document.getElementsByClassName('fc-time-area')[0].getElementsByClassName('fc-scroller')[0].scrollLeft = scrollTo;
									}
								}, 2000);
							}
						}}					
						events={this.state.view === 'schedule' ? [...eventsPrepend, ...events] : events}
						selectable={false} // TODO: Enable this when add events possible
						selectMirror={true}
						selectConstraint="future"
						select={(info) => {
							this.props.history.push(`${this.props.location.pathname}/event/${this.state.id}?type=${info.resource.id}&start=${info.startStr}&end=${info.endStr}`);
							let calendarApi = this.calendarRef.current.getApi()
							calendarApi.unselect()
						}}
						locales={allLocales}
						locale={i18n.language}
						timeZone={this.props.account.profile.timezone}
						height={this.state.view === 'schedule' ? 'auto' : "calc(100% - 60px)"}
						eventContent={(info) => {
																												
							let eventItem = document.createElement('div');
							
							if(this.state.view !== 'schedule'){																				
								let icon = document.createElement('i');
								icon.setAttribute('aria-hidden', "true");
								icon.setAttribute('class', `type ${info.event.extendedProps.icon}`);
								eventItem.appendChild(icon);
							}
							
							let time = document.createElement('span');
							time.setAttribute('class', `time`);
							time.innerHTML = moment(info.event.start).format('HH:mm');
							eventItem.appendChild(time);		
							
							eventItem.setAttribute("data-tip", info.event.id);					
	
							if(issues.includes(parseFloat(info.event.id))){
								eventItem.classList.add('issue');
							}
							
							//<i className={`fal fa-chevron-left`}
							
							return { domNodes: [ eventItem ] } 
						}}	
						eventDidMount={() => {
							ReactTooltip.rebuild();
						}}
						slotLabelFormat={[
							{ month: 'long', year: 'numeric' }, // top level of text
							{ weekday: 'short', day: 'numeric' } // lower level of text
						]}
						datesSet={(dateInfo) => {
							
							if(dateInfo.view !== 'customTimeline'){
								
								let title = dateInfo.view.title;
							
								if(dateInfo.view === 'dayGridWeek'){
									title = dateString('date', moment(dateInfo.startStr).format('YYYY-MM-DD'), moment(dateInfo.endStr).subtract(1, 'day').format('YYYY-MM-DD'))
								}
								
								if(this.state.title !== title){
									this.setState({
										title: title
									});
								}
							}
						}}
						eventClassNames={(info) => {
						  
						  	if(this.state.hover && this.state.hover == info.event.id){
							  	return ['hover'];
						  	}else{
							  	return [];
						  	}
						}}
						eventMouseEnter={(info)=> { 
							if(!this.state.hover || this.state.hover !== info.event.id){
								this.setState({
									hover: info.event.id
								});
							}
						}}
						eventMouseLeave={(info)=> { 
							if(this.state.hover){						
								this.setState({
									hover: false
								});
							}
						}}
						dayHeaderDidMount={(info) => {
					
							let content = info.el.innerHTML;
							content = content.replace('<a class=\"fc-col-header-cell-cushion \">', '');
							content = content.replace('</a>', '');
							
							info.el.innerHTML = content;
						}}
						dateClick={(info) => {
							this.setView('week');
							this.calendarRef.current.getApi().gotoDate(info.date);
						}}
					/>	
				);
			
			}else if(this.state.view === 'timeline'){

				events = _.orderBy(events, ['start'],['asc']); 

				calendar = (
					<VerticalTimeline>
					
						{_.map(events, (event, key) => {	
							
							let status = 'Unknown';
											
							switch(event.status){
					
								case 'pending':									
									if(issues.includes(event.id)){
										status = 'Pending - with issues';
									}else{
										status = 'Pending';
									}
									break;
									
								case 'active':
									status = 'Active';
									break;
									
								case 'failed':
									status = 'Failed';
									break;
									
								case 'complete':
									status = 'Completed';
									break;	
									
								default:
					
									break;			
							}						
							
							let date = moment.utc(event.start).tz(this.props.account.profile.timezone).format('MMM D, HH:mm');
							
							if(event.end){
								date = `${date} - ${moment.utc(event.end).tz(this.props.account.profile.timezone).format('MMM D, HH:mm')}`;
							}	
							
							date = (
								<React.Fragment>
									{date}
									<br/>
									<small>{status}</small>
								</React.Fragment>
							);
							
							let screenshot = 'Preview Queued';
															
							if(event.screenshot){
								screenshot = (
									<img src={imageResize(event.screenshot, 1000)} width="1000" />
								);
							}

							return (
								<VerticalTimelineElement
									key={key}
									date={date}
									iconStyle={{ background: event.color }}
									icon={<i className={event.icon} />}
									iconOnClick={() => {
										this.props.history.push(`${this.props.location.pathname}/event/${this.state.id}/${event.id}`);
									}}
									onTimelineElementClick={() => {
										this.props.history.push(`${this.props.location.pathname}/event/${this.state.id}/${event.id}`);
									}}
								>
									{screenshot}
								</VerticalTimelineElement>
							);
						})}
					</VerticalTimeline>
				)
				
			}else if(this.state.view === 'list'){
				
				calendar = (
					<Table 
						columns={[
						{
							key: "type",
							label: "Type",
							sortable: true,
							format: "image_pre"
						},
						{
							key: "screenshot",
							label: "Preview",
							format: 'screenshot',
							width: 60
						},
						{
							key: "date",
							label: "Start",
							sortable: true,
							format: 'datetime'
						},
						{
							key: "date2",
							label: "End",
							sortable: true,
							format: 'datetime'
						},
						{
							key: "status",
							label: "Status",
							sortable: true,
							format: "image_pre"
						},
						{
							key: "actions",
							label: "Actions",
							className: "tight right"
						}]}
						data={_.map(events, (event, key) => {
	
							let status = 'Unknown';
							let icon = false;
							let color = false;
							let action = 'view';
																
							switch(event.status){
					
								case 'pending':									
									if(issues.includes(event.id)){
										icon = 'fal fa-exclamation-triangle';
										color = '#FF3333';
										action = 'fix';
										status = 'Pending - with issues';
									}else{
										action = 'edit';
										status = 'Pending';
									}
									break;
									
								case 'active':
									status = 'Active';
									action = 'view';
									break;
									
								case 'failed':
									status = 'Failed';
									action = 'view';
									break;
									
								case 'complete':
									status = 'Completed';
									action = 'view';
									break;	
									
								default:
					
									break;			
							}
															
							return ({
								id: event.id,
								status: {
									value: status,
									icon: icon,
									color: color
								},
								type: {
									value: event.title,
									icon: event.icon
								},
								screenshot: event.screenshot,
								date: event.start,
								date2: event.end ? event.end : false,
								time: moment.utc(event.start).tz(this.props.account.profile.timezone).format('HH:mm'),
								actions: [action]
							});
						})}
						sort={{
							column: 'date',
							order: 'asc'
						}}
						actions={{
							edit: {
								tooltip: i18n.t('campaigns:schedule_action_edit'),
								icon: 'fal fa-edit',
								url: `${this.props.location.pathname}/event/${this.state.id}/[ID]`
							},
							view: {
								tooltip: i18n.t('campaigns:schedule_action_view'),
								icon: 'fal fa-eye',
								url: `${this.props.location.pathname}/event/${this.state.id}/[ID]`
							},
							fix: {
								tooltip: i18n.t('campaigns:schedule_action_fix'),
								icon: 'fal fa-wrench',
								url:  `${this.props.location.pathname}/event/${this.state.id}/[ID]`
							}
						}}
						rowClick={(row) => {
							this.props.history.push(`${this.props.location.pathname}/event/${this.state.id}/${row.id}`);
						}}
					/>
				);
			}	
						
			return (
				<div className="c-card u-mb-zero flex-strech">
					<h4 className="u-mb-medium">
						
						{(this.state.view === 'month' || this.state.view === 'week') &&
							this.state.title
						||
							dateString('date', moment.utc(campaign.date_start).tz(this.props.account.profile.timezone).format('YYYY-MM-DD'), moment.utc(campaign.date_end).tz(this.props.account.profile.timezone).add(1, 'day').format('YYYY-MM-DD'))
						}
						
						<div className="options">
						
							{(this.state.view === 'month' || this.state.view === 'week') && 
								<React.Fragment>
									<i className={`fal fa-chevron-left`} onClick={() => { this.setPeriod('previous'); }} data-tip={i18n.t(`calendar:tooltip_period_previous_${this.state.view}`)}></i>
									<i className={`fal fa-dot-circle`} onClick={() => { this.setPeriod('today'); }} data-tip={i18n.t(`calendar:tooltip_period_current_${this.state.view}`)}></i>
									<i className={`fal fa-chevron-right`} onClick={() => { this.setPeriod('next'); }} data-tip={i18n.t(`calendar:tooltip_period_next_${this.state.view}`)}></i>
									<div className="spacer"></div>
								</React.Fragment>
							}							
							
							<i className={`fal fa-stream ${this.state.view === 'schedule' ? 'selected' : ''}`} onClick={() => { this.setView('schedule'); }} data-tip={i18n.t('campaigns:schedule_tooltip_view_schedule')}></i>
							
							<i className={`fal fa-calendar ${this.state.view === 'month' ? 'selected' : ''}`} onClick={() => { this.setView('month'); }} data-tip={i18n.t('campaigns:schedule_tooltip_view_month')}></i>
							<i className={`fal fa-calendar-week ${this.state.view === 'week' ? 'selected' : ''}`} onClick={() => { this.setView('week'); }} data-tip={i18n.t('campaigns:schedule_tooltip_view_week')}></i>
							
							<i className={`fal fa-list-ul ${this.state.view === 'timeline' ? 'selected' : ''}`} onClick={() => { this.setView('timeline'); }} data-tip={i18n.t('campaigns:schedule_tooltip_view_timeline')}></i>
							
							<i className={`fal fa-th-list ${this.state.view === 'list' ? 'selected' : ''}`} onClick={() => { this.setView('list'); }} data-tip={i18n.t('campaigns:schedule_tooltip_view_list')}></i>
							
							
						</div>
					</h4>
					{calendar}
					
					<ReactTooltip 
						// effect="solid"
						effect="float"
						delayShow={100}
						getContent={(id) => { 
							
							let event = _.find(this.state.view === 'schedule' ? [...eventsPrepend, ...events] : events, { id: parseFloat(id) });
							
							if(event){
								
								let tooltip = ``;
								
								if(event.screenshot){
									tooltip = (
										<img 
											src={imageResize(event.screenshot, 250)}
											width="250" 
										/>
									);
								}else{
									tooltip = (
										<div className="soon">
											Preview Queued
										</div>
									);
								}
								
								return (
									<div className="preview-popup">
										<i className={event.icon}></i>
										{moment(event.start).format('MMM D, HH:mm')}
										
										{event.end && 
											<React.Fragment>
												{` - ${moment(event.end).format('MMM D, HH:mm')}`}
											</React.Fragment>
										}
										
										<br/><br/>
										{tooltip}
									</div>
								);
							}
							
							return null;
						}}
					/>
				</div>
			)
		}else{
			
			let cta = false;
			
			if(!this.state.readonly){
				/*cta = {
					label: i18n.t('campaigns:schedule_action_add'),
					url: `${this.props.location.pathname}/event/${this.state.id}`
				};*/
			}
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t(`campaigns:schedule_empty_title`)}
					description={i18n.t('campaigns:schedule_empty_description')}
					cta={cta}
				/>
			);
		}	
	}
	
	renderOverview(){
		
		let campaign = this.props.campaigns.campaigns[this.state.id];
		let items = {};
		let issues = 0;
		
		switch(campaign.status){
			
			case 'draft':
				items.total = 0;
				items.issues = 0;
				break;
				
			case 'launched':
				items.pending = 0;
				items.active = 0;
				items.failed = 0;
				items.completed = 0;
				break;
				
			case 'deployed':
				items.total = 0;
				items.issues = 0;
				break;
				
			default:
			
				break;
		}

		if(!_.isEmpty(campaign.events)){
					
			_.forEach(campaign.events, (event, key) => {
							
				if(typeof items.total !== "undefined"){
					++items.total;
				}

				switch(event.status){
					
					case 'pending':
						if(typeof items.pending !== "undefined"){
							++items.pending;
							
							if(event.issues){
								++issues;
							}
						}
						
						if(event.issues && typeof items.issues !== "undefined"){
							++items.issues;
						}
						break;
						
					case 'active':
						if(typeof items.active !== "undefined"){
							++items.active;
						}
						break;
						
					case 'failed':
						if(typeof items.failed !== "undefined"){
							++items.failed;
						}
						break;
						
					case 'complete':
						if(typeof items.completed !== "undefined"){
							++items.completed;
						}
						break;	
						
					default:
					
						break;		
				}	
				
			});
		}
			
		let title = '';
		let icon = '';
		let className = '';
		let sub = '';
		let width = 12;
		
		switch(Object.keys(items).length){
			
			case 1:
				width = 12;
				break;
				
			case 2:
				width = 6;
				break;
				
			case 3:
				width = 4;
				break;
				
			case 4:
				width = 3;
				break;
				
			case 5:
				width = 2;
				break;
				
			case 6:
				width = 2;
				break;
				
			default:
			
				break;
		}
						
		return _.map(items, (value, key) => {
			
			sub = '';
			
			switch(key){
				
				case 'total':
					title = 'Total';
					icon = 'tally';
					className = 'c-icon--info';
					break;
					
				case 'pending':
					title = 'Pending';
					icon = 'stopwatch';
					className = 'c-icon--warning';
					
					// HOW MANY HAVE ISSUES?
					if(issues > 0){
												
						sub = (
							<CountUp 
								className="sub"
								delay={0} 
								duration={1} 
								end={issues} 
								preserveValue={true}
								suffix=" with issues"
							/>							
						);
					}
					
					break;
				
				case 'active':
					title = 'Active';
					icon = 'eye';
					className = 'c-icon--success';
					break;
					
				case 'failed':
					title = 'Failed';
					icon = 'times';
					className = 'c-icon--danger';					
					break;
					
				case 'completed':
					title = 'Completed';
					icon = 'check';
					className = 'c-icon--primary';
					break;
					
				case 'issues':
					title = 'Issues';
					icon = 'exclamation-triangle';
					className = 'c-icon--danger';					
					break;
					
				default:
				
					break;
			}		
			
			return (
				<div className={`col-md-6 col-xl-${width}`} key={key}>
					<div className="c-card equalize stats-box">
						<span className={`c-icon u-mb-small ${className}`}>
							<i className={`fal fa-${icon}`}></i>
						</span>
								
						<h3 className="c-text--subtitle">{title}</h3>
						<h2 className="counter">
							<CountUp 
								delay={0} 
								duration={1} 
								end={value} 
								preserveValue={true}
							/>
							
							{sub}
						</h2>
					</div>
				</div>
			);
		});
	}
	
	setPeriod(dir){
		
		const calendarApi = this.calendarRef.current.getApi();
		
		switch(dir){
			
			case 'previous':
				calendarApi.prev();
				break;
				
			case 'next':
				calendarApi.next();
				break;
				
			case 'today':
				calendarApi.today();
				break;
		}
	}
	
	render() {

		if(!this.props.campaigns.campaigns || !this.props.campaigns.campaigns[this.state.id]){
			return (
				<Loading />
			);
		}
		
		let campaign = this.props.campaigns.campaigns[this.state.id];
			
		return (

			<React.Fragment>
				<CampaignsToolbar
					id={this.state.id}
					section="schedule"
					onClick={{
						add: () => {
							this.props.history.push(`${this.props.location.pathname}/event/${this.state.id}`);
						},
						deploy: () => {
							this.props.history.push(`${this.props.location.pathname}/deploy/${this.state.id}`);
						},
						launch: () => {
							
							Swal.fire({
								title: i18n.t('campaigns:schedule_launch_dialog_title'),
								text: i18n.t('campaigns:schedule_launch_dialog_description'),
								icon: 'warning',
								showCancelButton: true,
								confirmButtonText: i18n.t('dialog_confirm'),
								cancelButtonText: i18n.t('dialog_cancel'),
								focusConfirm: false
							}).then((result) => {

								if(result.value){

									this.props.launchCampaign(this.state.id, () => {
										
										this.props.showNotification(
											'success',
											i18n.t('campaigns:notification_launched_title'),
											i18n.t('campaigns:notification_launched_description', { date: moment.utc(campaign.date_start).tz(this.props.account.profile.timezone).format('LL') })
										);
										
										this.props.history.push('/campaigns');
									});
								}
							});
						}
					}}
					events={campaign.events}
					readonly={this.state.readonly}
					continue={(!campaign.events || Object.keys(campaign.events).length === 0) ? false : true}
					campaign={campaign}
					{...this.props}
				/>
				
				<div className="container flex-fill">	
				
					<div className="c-card u-pb-zero">
						<h4 className="u-mb-medium">
			         		{i18n.t('campaigns:schedule_overview_title')}
			         	</h4>	
						<div className="row">
							{this.renderOverview()}
						</div>
					</div>
					{this.renderSchedule()}		
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ campaigns, account }, ownProps){
	return {
		campaigns,
		account
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchCampaignsList,
	launchCampaign,
	showNotification,
	saveCampaignEvent
})(CampaignsSchedule);