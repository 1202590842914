import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import Loading from '../../components/chrome/loading';
import CampaignsToolbar from './toolbar';
import { fetchCampaignsList } from '../../actions/campaigns';
import { fetchAnalytics, clearAnalytics } from '../../actions/stats';
import { setSectionOptions } from '../../actions/chrome';
import CountUp from 'react-countup';
import InputField from '../../components/forms/field.js';
import moment from 'moment';
import 'moment/min/locales';
import { Line } from 'react-chartjs-2';
import { checkPermission } from '../../helpers/permissions';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { fetchRetailersList, fetchRetailersGroups } from '../../actions/retailers';

//import ReactTimeAgo from 'react-time-ago'
//import JavascriptTimeAgo from 'javascript-time-ago'

class CampaignsAnalytics extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS_ANALYTICS')){
			this.props.history.push('/');
		}
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push('/campaigns');
		}		

		this.props.clearAnalytics();
		
		moment.locale(i18n.language);
		//JavascriptTimeAgo.locale(i18n.language);
		
		let startDate = moment().subtract(7,'days').toDate();
		let endDate = moment().toDate();
		
		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		
		if(queryString.from){
			startDate = moment(queryString.from).toDate();
		}
		
		if(queryString.to){
			endDate = moment(queryString.to).toDate();
		}
				
		this.state = {
			id: id,
			date: {
	            startDate: startDate,
	            endDate: endDate
	        },
	        retailers: [],
	        types: false
		}
		
		this.props.fetchCampaignsList(id, () => {
		       	
	       	if(!this.props.campaigns.campaigns[id]){
				this.props.history.push('/campaigns');
			}else{
				this.updateDomElements();
			}
       	});
       	
       	if(checkPermission(this.props.account.permissions, 'RETAILERS')){
			this.props.fetchRetailersList();
		}
		
		if(checkPermission(this.props.account.permissions, 'RETAILERS_GROUPS')){
			this.props.fetchRetailersGroups();
		}
       	       			
        this.updateDomElements();
	}
	
	componentDidMount(){
		window.scrollTo(0,0);

		// 12/05/2021 - Disabled due to FB limits usage
		/*this.statsRefreshTimer = setInterval(() => {
			this.onInputDateChange();
		}, 30000); // EVERY 30 SEC*/
	}
	
	componentWillUnmount(){
		clearInterval(this.statsRefreshTimer);
	}
	
	updateDomElements(){
				
		if(this.props.campaigns.campaigns && this.props.campaigns.campaigns[this.state.id]){
			let campaign = this.props.campaigns.campaigns[this.state.id];	
			
			if(campaign.status === 'draft'){
				this.props.history.push(`/campaigns/${this.state.id}/schedule`);
			}
					
			this.onInputDateChange({
	            startDate: moment(campaign.date_start).toDate(),
	            endDate: moment(campaign.date_end).toDate()
	        });
			
			this.props.setSectionOptions('campaigns', campaign.internal_name, `/campaigns/${this.state.id}`);
			
			let types = {
				active: [],
				inactive: ['facebook', 'instagram', 'twitter', 'email', 'landing', 'slider', 'advert']
			}
			
			_.forEach(campaign.events, (event, key) => {
				
				if(!types.active.includes(event.type)){
					types.active.push(event.type);
					types.inactive.splice(types.inactive.indexOf(event.type), 1);
				}
			});			
			
			this.setState({
				types: types
			});
			
		}else{
			this.props.setSectionOptions('campaigns', i18n.t('campaigns:analytics_title'), `/campaigns/${this.state.id}`);
		}
	}	
	
	onInputDateChange(date){
		
		if(date){
			this.setState({
				date: date
			});
		}else{
			date = this.state.date;
		}
		
		let retailers = [];
		
		if(this.state.retailers && this.state.retailers.length > 0){
			
			_.forEach(this.state.retailers, (item, key) => {

				let value = item.value.split('|');
				
				switch(value[0]){
					
					case 'group':
						let group = this.props.retailers.groups[value[1]];

						if(group.retailers && group.retailers.length > 0){
							retailers = [...retailers, ...group.retailers]
						}
						break;
						
					case 'retailer':
						retailers.push(parseFloat(value[1]));
						break;
						
					default:
					
						break;
				}
			});
			
			retailers = _.uniq(retailers);
		}
				
		this.props.fetchAnalytics(
			moment(date.startDate).format('YYYY-MM-DD'),
			moment(date.endDate).format('YYYY-MM-DD'),
			this.state.id,
			false, 
			retailers
		);
	}
	
	onInputRetailerChange(selected){
				
		this.setState({
			retailers: selected
		}, () => {
			this.onInputDateChange();
		});		
	}
	
	renderOverview(){
				
		let labels = [];
		let datasets = [];
		
		let data = {
			impressions: {},
			clicks: {},
			engagement: {},
			conversion: {},
			value: {}
		}
			
		let types = {}
				
		_.forEach(this.props.stats.analytics, (value, key) => {
						
			let dates = [];
					
			_.forEach(value, (day, key2) => {
				
				if(labels.length === 0){
					dates.push(moment(key2).format('D/M'));
				}
											
				if(day.impression){
					
					if(data.impressions[key2]){
						data.impressions[key2] = data.impressions[key2] + day.impression;
					}else{
						data.impressions[key2] = day.impression;
					}
					
				}else if(!data.impressions[key2]){
					data.impressions[key2] = 0
				}
				
				if(day.click){
					
					if(data.clicks[key2]){
						data.clicks[key2] = data.clicks[key2] + day.click;
					}else{
						data.clicks[key2] = day.click;
					}
					
				}else if(!data.clicks[key2]){
					data.clicks[key2] = 0
				}
				
				if(day.engagement){
					
					if(data.engagement[key2]){
						data.engagement[key2] = data.engagement[key2] + day.engagement;
					}else{
						data.engagement[key2] = day.engagement;
					}
					
				}else if(!data.engagement[key2]){
					data.engagement[key2] = 0
				}
				
				if(day.conversion){
					
					if(data.conversion[key2]){
						data.conversion[key2] = data.conversion[key2] + day.conversion;
					}else{
						data.conversion[key2] = day.conversion;
					}
					
				}else if(!data.conversion[key2]){
					data.conversion[key2] = 0
				}
				
				if(day.value){
					
					if(data.value[key2]){
						data.value[key2] = data.value[key2] + day.value;
					}else{
						data.value[key2] = day.value;
					}
					
				}else if(!data.value[key2]){
					data.value[key2] = 0
				}
			});
			
			if(labels.length === 0){
				labels = dates;
			}
		});
		
		types = {
			impressions: 'Impressions',
			clicks: 'Clicks',
			engagement: 'Engagement',
			conversion: 'Conversions',
			value: 'Revenue'
		}
		
		if(types.impressions){
			datasets.push({
				label: types.impressions,
				backgroundColor: '#2083fe',
				borderColor: '#2083fe',
				borderWidth: 2,
				data: _.values(data.impressions),
				fill: false,
				pointRadius: 0
			})
		}
		
		if(types.clicks){
			datasets.push({
				label: types.clicks,
				fill: false,
				backgroundColor: '#28c76f',
				borderColor: '#28c76f',
				borderWidth: 2,
				data: _.values(data.clicks),
				pointRadius: 0
			})
		}
		
		if(types.engagement){
			datasets.push({
				label: types.engagement,
				fill: false,
				backgroundColor: '#ffc343',
				borderColor: '#ffc343',
				borderWidth: 2,
				data: _.values(data.engagement),
				pointRadius: 0
			})
		}
		
		if(types.conversion){
			datasets.push({
				label: types.conversion,
				fill: false,
				backgroundColor: '#768093',
				borderColor: '#768093',
				borderWidth: 2,
				data: _.values(data.conversion),
				pointRadius: 0
			})
		}
		if(types.value){
			datasets.push({
				label: types.value,
				fill: false,
				backgroundColor: '#ee4343',
				borderColor: '#ee4343',
				borderWidth: 2,
				data: _.values(data.value),
				pointRadius: 0
			})
		}			
		return (
			<div className="c-card">				
				<div style={{ height: '350px', width: 'calc(100% + 20px)', marginLeft: '-10px'  }}>
					<Line
						data={{
							labels: labels,
							datasets: datasets
						}}
						options={{
							responsive: true,
							maintainAspectRatio: false,
							title: {
								display: false,
							},
							legend: {
								display: false,
							},
							layout: {
					            padding: {
					                left: 10,
					                right: 10,
					                top: 30,
					                bottom: 30
					            }
					        },
							tooltips: {
								mode: 'index',
								intersect: false,
								callbacks: {
					                title: (tooltipItem, data) => {
						                return moment(tooltipItem[0].xLabel, 'D/M').format('LL');
						            }
							    }
							},
							hover: {
								mode: 'nearest',
								intersect: true
							},
							scales: {
								xAxes: [{
									display: true,
									scaleLabel: {
										display: false,
										scaleLabel: {
											display: false
										}
									}
								}],
								yAxes: [{
									display: false,
									scaleLabel: {
										display: false
									},
									ticks: {
										min: 0
									}
								}]
							}
						}}
					/>
				</div>														
			</div>
		);
	}
	
	renderType(type){
				
		let labels = [];
		let datasets = [];
		let icon = '';
		
		let data = {
			impressions: [],
			clicks: [],
			engagement: [],
			conversion: [],
			value: []
		}
		
		let types = {}
				
		_.forEach(this.props.stats.analytics[type], (day, date) => {
					
			labels.push(moment(date).format('D/M'));
			
			if(day.impression){
				data.impressions.push(day.impression);
			}else{
				data.impressions.push(0);
			}
			
			if(day.click){
				data.clicks.push(day.click);
			}else{
				data.clicks.push(0);
			}
			
			if(day.engagement){
				data.engagement.push(day.engagement);
			}else{
				data.engagement.push(0);
			}
			
			if(day.conversion){
				data.conversion.push(day.conversion);
			}else{
				data.conversion.push(0);
			}
			
			if(day.value){
				data.value.push(day.value);
			}else{
				data.value.push(0);
			}
		});
		
		switch(type){
			
			case 'facebook':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					engagement: 'Engagement',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fab fa-facebook-f';
				break;
				
			case 'instagram':
				types = {
					impressions: 'Impressions',
					engagement: 'Engagement',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fab fa-instagram';
				break;
				
			case 'twitter':
				types = {
					engagement: 'Engagement',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fab fa-x-twitter';
				break;
				
			case 'email':
				types = {
					impressions: 'Opens',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fas fa-envelope';
				break;
				
			case 'landing':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fas fa-laptop';
				break;
				
			case 'slider':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fas fa-arrows-h';
				break;
				
			case 'advert':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fas fa-ad';
				break;
				
			default:
			
				break;
		}
		
		if(types.impressions){
			datasets.push({
				label: types.impressions,
				backgroundColor: '#2083fe',
				borderColor: '#2083fe',
				borderWidth: 2,
				data: data.impressions,
				fill: false,
				pointRadius: 0
			})
		}
		
		if(types.clicks){
			datasets.push({
				label: types.clicks,
				fill: false,
				backgroundColor: '#28c76f',
				borderColor: '#28c76f',
				borderWidth: 2,
				data: data.clicks,
				pointRadius: 0
			})
		}
		
		if(types.engagement){
			datasets.push({
				label: types.engagement,
				fill: false,
				backgroundColor: '#ffc343',
				borderColor: '#ffc343',
				borderWidth: 2,
				data: data.engagement,
				pointRadius: 0
			})
		}
		
		if(types.conversion){
			datasets.push({
				label: types.conversion,
				fill: false,
				backgroundColor: '#768093',
				borderColor: '#768093',
				borderWidth: 2,
				data: data.conversion,
				pointRadius: 0
			})
		}
		
		if(types.value){
			datasets.push({
				label: types.value,
				fill: false,
				backgroundColor: '#ee4343',
				borderColor: '#ee4343',
				borderWidth: 2,
				data: data.value,
				pointRadius: 0
			})
		}
		
		return (
			<div className="col-md-4" key={type}>
				<div className={`c-card equalize ${this.state.types.inactive.includes(type) ? 'disabled' : ''}`}>
					<h4>
						<i className={icon}></i>
						{i18n.t(`campaigns:event_type_${type}`)}
						
						{this.state.types.inactive.includes(type) &&
							<span className="status c-badge c-badge--warning">
								No Events
							</span>
						}
					</h4>
					
					<div style={{ height: '150px', width: 'calc(100% + 20px)', marginLeft: '-10px' }}>
						<Line
							data={{
								labels: labels,
								datasets: datasets
							}}
							options={{
								responsive: true,
								maintainAspectRatio: false,
								title: {
									display: false,
								},
								legend: {
									display: false,
								},
								layout: {
						            padding: {
						                left: 10,
						                right: 10,
						                top: 30,
						                bottom: 30
						            }
						        },
								tooltips: {
									mode: 'index',
									intersect: false,
									callbacks: {
						                title: (tooltipItem, data) => {
							                
							                return moment(tooltipItem[0].xLabel, 'D/M').format('LL');
							               
							            }
								    }
								},
								hover: {
									mode: 'nearest',
									intersect: true
								},
								scales: {
									xAxes: [{
										display: false,
										scaleLabel: {
											display: true,
											scaleLabel: {
												display: false
											}
										}
									}],
									yAxes: [{
										display: false,
										scaleLabel: {
											display: false
										},
										ticks: {
											min: 0
										}
									}]
								}
							}}
						/>
					</div>
					
					<ul className="stat-totals">
					
						{types.impressions && 
							<li>
								<span className="c-icon c-icon--info" data-tip={types.impressions}>
									<i className="fal fa-eye"></i>
								</span>
										
								<h4>
									<CountUp 
										delay={0} 
										duration={1} 
										end={_.sum(data.impressions)} 
										separator="," 
										preserveValue={true}
									/>
								</h4>
							</li>
						}
						
						{types.clicks && 
							<li>
								<span className="c-icon c-icon--success" data-tip={types.clicks}>
									<i className="fal fa-mouse-pointer"></i>
								</span>
										
								<h4>
									<CountUp 
										delay={0} 
										duration={1} 
										end={_.sum(data.clicks)} 
										separator="," 
										preserveValue={true}
									/>
								</h4>
							</li>
						}
						
						{types.engagement && 
							<li>
								<span className="c-icon c-icon--warning" data-tip={types.engagement}>
									<i className="fal fa-thumbs-up"></i>
								</span>
										
								<h4>
									<CountUp 
										delay={0} 
										duration={1} 
										end={_.sum(data.engagement)} 
										separator="," 
										preserveValue={true}
									/>
								</h4>
							</li>
						}
						
						{types.conversion && 
							<li>
								<span className="c-icon c-icon--secondary" data-tip={types.conversion}>
									<i className="fal fa-receipt"></i>
								</span>
										
								<h4>
									<CountUp 
										delay={0} 
										duration={1} 
										end={_.sum(data.conversion)} 
										separator="," 
										preserveValue={true}
									/>
								</h4>
							</li>
						}
												
						{types.value && 
							<li>
								<span className="c-icon c-icon--danger" data-tip={types.value}>
									<i className="fal fa-money-bill"></i>
								</span>
										
								<h4>
									<CountUp 
										delay={0} 
										duration={1} 
										end={_.sum(data.value)} 
										separator="," 
										preserveValue={true}
										decimals={2}
									/>
								</h4>
							</li>
						}
					</ul>										
				</div>
			</div>
		);
	}
	
	renderAnalytics(){
		
		if(!this.props.stats.analytics){
			return (
				<Loading />
			);
		}
		
		let campaign = this.props.campaigns.campaigns[this.state.id];
		let filters = [];
		let filters_label = [];
		
		if(this.props.retailers.groups){
			filters_label.push('retailer groups');
			filters.push({
				label: 'Retailer Groups',
				options: _.map(_.orderBy(this.props.retailers.groups, ['name'],['asc']), (item, key2) => {
					return {
						key: key2,
						label: item.name,
						labelDropdown: `${item.name} (${item.retailers ? item.retailers.length : 0} retailer${item.retailers.length === 1 ? '' : 's'})`,
						value: `group|${item.id}`,
						icon: 'fal fa-link'
					}
				})
			});
		}
				
		if(this.props.retailers.retailers && this.props.retailers.retailers.length > 0){
			filters_label.push('individual retailers');
			filters.push({
				label: 'Retailers',
				options: _.map(_.orderBy(this.props.retailers.retailers, ['name'],['asc']), (item, key2) => {
					return {
						key: key2,
						label: item.name,
						value: `retailer|${item.id}`,
						icon: 'fal fa-store-alt'
					}
				})
			});
		}
		
		if(filters.length > 0){
			if(filters_label.length > 1){
				filters_label = `${filters_label.slice(0, -1).join(', ')} or ${filters_label.slice(-1)}`;
			}else{
				filters_label = filters_label[0]
			}
		}
		
		return (
			<div className="c-card">
				<h4 className="u-mb-medium">
					Daily Overview
					<div className="options">
		         		<InputField
							name="date_range"
							type="datepicker"
							range={{
								months: 2
							}}
							minDate={moment(campaign.date_start.date).toDate()}
							maxDate={moment().toDate()}
							selected={this.state.date}
							alignRight={true}
							onChangeFunc={this.onInputDateChange.bind(this)}
						/>
	         		</div>
				</h4>
				
				{filters.length > 0 && 
					<div className="">
			         		
		         		<InputField
							placeholder={`Filter by ${filters_label}`} 
							name="retailers"
							type="suggest"
							options={filters}
							multiple={true}
							selected={this.state.retailers}
							onChangeFunc={this.onInputRetailerChange.bind(this)}
						/>
	         		</div>
         		}
				
				{this.renderOverview()}
				
				{this.state.types && 
					<div className="row">
						
						{_.map(this.state.types.active, (type, key) => {
							return this.renderType(type)
						})}
						
						{_.map(this.state.types.inactive, (type, key) => {
							return this.renderType(type)
						})}
					</div>
				}
			</div>
		);
	}
	
	render() {
		
		if(!this.props.campaigns.campaigns || !this.props.campaigns.campaigns[this.state.id]){
			return (
				<Loading />
			);
		}
		
		let campaign = this.props.campaigns.campaigns[this.state.id];
		
		return (

			<React.Fragment>
				<CampaignsToolbar
					id={this.state.id}
					section="analytics"
					readonly={true}
					campaign={campaign}
					{...this.props}
				/>
				
				<div className="container">
					{this.renderAnalytics()}
				</div>
				<ReactTooltip 
					effect="solid"
				/>
			</React.Fragment>
			
			/*
				
			<div className="col-md-3">
				<div className="c-card stats-box">
					<span className="c-icon c-icon--secondary u-mb-small">
						<i className="fal fa-award"></i>
					</span>
							
					<h3 className="c-text--subtitle">Performance</h3>
					<h1>
						<CountUp delay={0} duration={1} end={0} />/10
					</h1>
				</div>
			</div>
							
			<div className="col-md-6">
				<div className="c-card stats-box">
					<span className="c-icon c-icon--danger u-mb-small">
						<i className="feather icon-shopping-cart"></i>
					</span>
					
					<h3 className="c-text--subtitle">Conversions</h3>
					<h1>
						<CountUp delay={0} duration={1} end={1710} separator="," />
					</h1>
				</div>
			</div>						
			<div className="col-md-6">
				<div className="c-card stats-box">
					<span className="c-icon c-icon--warning u-mb-small">
						<i className="feather icon-activity"></i>
					</span>
					
					<h3 className="c-text--subtitle">Revenue</h3>
					<h1>
						<CountUp delay={0} duration={1} end={50890} separator="," prefix="£" />
					</h1>
				</div>
			</div>
			
			*/
		);
	}
}

function mapStateToProps({ campaigns, account, stats, retailers }, ownProps){
	return {
		campaigns,
		account,
		stats,
		retailers
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchCampaignsList,
	fetchAnalytics,
	clearAnalytics,
	fetchRetailersList, 
	fetchRetailersGroups,
})(CampaignsAnalytics);