import { userConstants } from '../constants/users';
import _ from 'lodash';

const defaultState = {
	authenticated: localStorage.getItem('user_id') ? true : false,
	id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : null,
	delegate_id: localStorage.getItem('delegate_id') ? localStorage.getItem('delegate_id') : null,
	token: localStorage.getItem('user_token') ? localStorage.getItem('user_token') : null
};

export default function(state = defaultState, action) {
	switch (action.type) {
		case userConstants.AUTHENTICATED:			
			localStorage.setItem('user_id', action.payload.data.id);
			localStorage.setItem('user_token', action.payload.data.token);
			localStorage.setItem('delegate_id', null);

			return { 
				...state, 
				authenticated: true,
				id: action.payload.data.id,
				token: action.payload.data.token,
				//account: action.payload.data.account
			};
			
		case userConstants.LOGOUT:
			return { 
				...state, 
				authenticated: false,
				id: null,
				token: null,
				group: false,
				role: false,
				permissions: false
			};
			
		case userConstants.USER_LIST:
			return { 
				...state, 
				users: { ...state.users, ..._.mapKeys(action.payload.data, 'id') }
			};
		
		case userConstants.USER_SAVE:
		case userConstants.USER_SAVE_PREFERENCES:
			return { 
				...state, 
				users: { ...state.users, ..._.mapKeys(action.payload.data.users, 'id') }
			};
						
		case userConstants.KEY_CHECK:
		
			let key = false;
			
			if(action.payload && action.payload.data.user){
				key = {...action.payload.data.user, ...{token: action.token}};
			}
			
			return { 
				...state, 
				key: key
			};		
			
		case userConstants.RESET:
		case userConstants.WELCOME:
			
			localStorage.setItem('user_id', action.payload.data.id);
			localStorage.setItem('user_token', action.payload.data.token);
			localStorage.setItem('delegate_id', null);
			
			return { 
				...state, 
				key: false,
				authenticated: true,
				id: action.payload.data.id,
				token: action.payload.data.token,
			};
			
		case userConstants.DELEGATE_AUTHENTICATED:
			localStorage.setItem('delegate_id', action.payload.data.id);
			return state;
			
		case userConstants.DELEGATE_LOGOUT:
			localStorage.setItem('delegate_id', null);
			return state;
			
		case userConstants.PREDICT_COUNTRY:
			return { 
				...state, 
				country_prediction: action.payload.data.country_code
			};
		
		default:
			return state;
	}
}