import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import { startSubmission, stopSubmission } from '../../actions/forms';
import _ from 'lodash';
import Loading from '../../components/chrome/loading';
import { imageUrl } from '../../helpers/s3';
//import ReactTooltip from 'react-tooltip';
import { showNotification } from '../../actions/notifications';
import stripHtml from "string-strip-html";
import Swal from 'sweetalert2';
import Toolbar from '../../components/chrome/toolbar';

import { saveProduct, getProductImage, fetchProductList, searchProducts } from '../../actions/products';
import { checkPermission } from '../../helpers/permissions';
import { makeSelection } from '../../actions/library';
import getSymbolFromCurrency from 'currency-symbol-map';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';

import * as Doka from '../../packages/react-doka/lib/doka.esm.min';
import '../../packages/react-doka/lib/doka.min.css';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginFileEncode, FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFilePoster, FilePondPluginFileValidateType, FilePondPluginImageCrop, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImageEdit);

var formName = 'formProductsEdit';

class ProductsEdit extends Component {

	constructor(props){
		super(props);
		
		this.props.stopSubmission(formName);	
		
		const { id } = this.props.match.params;
		
		this.state = {
            id: id, 
            files: [],
            files_changed: false,
            sale: false,
            search: false,
            searchMin: 3,
            products: [],
            content: {
	            sku: false,
	            title: false,
	            brand: false,
	            description: false,
	            price: false
            }
        };

		if(id){
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'PRODUCTS_EDIT')){
				this.props.history.push('/');
			}
					       	
			this.props.fetchProductList(id, () => {
		       	
		       	if(this.props.products.products && !this.props.products.products[id]){
					this.props.history.push(this.props.parentPath);
				}else{
					this.updateDomElements();
				}
	       	});
			
			this.updateDomElements();
			
		}else{
					
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'PRODUCTS_ADD')){
				this.props.history.push('/');
			}
		
	        // DO THIS SO THE VALIDATION IS RE-RENDERED AS SOMETIMES FAILS IN OVERLAY!
	        this.props.change('force_validation', 'rerender now!');	  
		}
				
		this.searchProductsBouncer = _.debounce((value, callback) => {
			if(this.state.search && this.state.search.length >= this.state.searchMin){
				this.searchProducts(value, callback);
			}else{
				callback([]);
			}
		}, this.state.search && this.state.search.length >= this.state.searchMin ? 250 : 0);
	}
	
	updateDomElements(){
		
		if(this.props.products.products && this.state.id){
		
			let product = this.props.products.products[this.state.id];	
			let sale = false;
			let files = [];
			
			if(product.price_sale !== null){
				sale = true;
			}					
			
			if(product.image){
				
				files = [
					{
						source: imageUrl(product.image),
						options: {
			              	type: 'local'
			            }
					}
				];
			}		
			
			let content = {
	            sku: product.sku ? true : false,
	            title: product.title ? true : false,
	            brand: product.brand ? true : false,
	            description: product.description ? true : false,
	            price: product.price ? true : false
            }	
			
			this.setState({
				files: files,
				sale: sale,
				search: { label: product.sku, value: product.sku },
				content: content
			});	
						
			this.props.initialize(product);
		}
	}
	
	searchProducts(value, callback){
						
		this.props.searchProducts(value, 'search', (products) => {
			
			this.setState({
				products: products
			});
			
			callback(_.map(products, (product, key) => {
			  	
			  	let item = {
				  	value: product.sku,
				  	label: product.sku,
				  	labelDropdown: product.title
				}
				
				let labelDropdownSub = []
								
				if(product.year !== '' && product.year !== '0'){
					labelDropdownSub.push(product.year);
				}
				
				if(product.colour !== ''){
					labelDropdownSub.push(product.colour);
				}
				
				if(labelDropdownSub.length > 0){					
					item.labelDropdownSub = labelDropdownSub.join(', ');
				}
					
				if(product.manuf_image){
					item.imageDropdown = {
						background: product.manuf_image,
						size: 'contain'
					}
				}else{
					item.iconDropdown = 'fal fa-box';
				}		
								
				return item;
			}));
		});
	}
	
	onInputSearchChange(selected){
					
		this.setState({
			search: selected
		});
	}
	
	onInputSearchSelect(selected){
					
		this.setState({
			search: selected
		}, () => {
			this.populateProduct(false);
		});
	}
	
	onInputChangeContent(type, e){
		
		let content = this.state.content;
		
		content[type] = e.target.value === '' ? false : true;
		
		this.setState({
			content: content
		});
	}
	
	populateProduct(notify){
	
		let product = _.filter(this.state.products, {sku: this.state.search.value});
		
		const updateValues = () => {
			
			const updateValuesSet = () => {
				
				this.props.change('sku', product[0].sku);
				this.props.change('title', product[0].title);
				this.props.change('brand', product[0].brand);
				this.props.change('description', stripHtml(product[0].manuf_description));
				this.props.change('price', product[0].rrp);	
				
				if(product[0].manuf_image){
					
					// WE GET IMAGE FROM API TO GET AROUND CORS
					this.props.getProductImage(product[0].manuf_image, (url) => {
						
						let files = [
							{
								source: url,
								options: {
				                    type: 'local'
				                }
							}
						];
						
						this.setState({
							files: files,
							files_changed: true
						});
					});
				}
				
				let content = {
		            sku: product[0].sku ? true : false,
		            title: product[0].title ? true : false,
		            brand: product[0].brand ? true : false,
		            description: product[0].description ? true : false,
		            price: product[0].price ? true : false
	            }
	            
	            this.setState({
					content: content
				});
			}
			
			if(this.state.content.sku || this.state.content.title || this.state.content.brand || this.state.content.description || this.state.content.price || this.state.files.length > 0){
			
				Swal.fire({
					title: i18n.t('products:product_sim_replace_dialog_title'),
					text: i18n.t('products:product_sim_replace_dialog_description'),
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: i18n.t('dialog_confirm'),
					cancelButtonText: i18n.t('dialog_cancel'),
					focusConfirm: false
				}).then((result) => {
	
					if (result.value) {
						updateValuesSet();
					}
				});	
			}else{
				updateValuesSet();
			}								
		}
		
		if(product.length > 0){
			updateValues();
		}else{
		
			this.props.searchProducts(this.state.search.value, 'sku', (products) => {
				
				this.setState({
					products: products
				}, () => {
					product = _.filter(this.state.products, {sku: this.state.search.value});
					
					if(product.length > 0){
						updateValues();
					}else if(notify){
						
						this.props.showNotification(
							'error',
							i18n.t('products:product_label_sim_repopulate_failed_notification_title'),
							i18n.t('products:product_label_sim_repopulate_failed_notification_description', { sku: this.state.search.value })
						);						
					}
				});
			});
		}
	}
	
	onInputSaleChange(selected){
				
		this.setState({
			sale: selected.target.checked
		});
	}

	onSubmit(values) {
		
	  	this.props.startSubmission(formName);
	  	
	  	let posting = _.clone(values);
	  	
	  	if(posting.image){
			delete(posting.image);
		}
	  	
		if(this.state.files_changed){
			
			let files = _.map(this.pond.getFiles(), (file, key) => {
			  	return {
				  	filename: file.filename,
				  	encoded: file.getFileEncodeBase64String()
				}
			});
						
			posting.image = files[0].encoded;
		}
		
		if(this.state.sale){
			posting.sale = 1;
		}else{
			posting.sale = 0;
			posting.price_sale = '';
		}
		
		/*if(this.state.sku.value){
			posting.sku = this.state.sku.value;
		}else{
			posting.sku = this.state.sku;
		}*/
				  	
	  	this.props.saveProduct(formName, this.state.id, posting, (products) => {
			
			if(this.props.overlay.back){
				  	
			  	switch(this.props.overlay.back.action){
				  	
			  		case 'library':				  			
			  			this.props.makeSelection('product', products)
			  			this.props.history.push(this.props.overlay.back.url);
			  			break;
			  			
			  		default:
			  			this.props.history.push(this.props.overlay.back.url);	
			  			break;
		  		}
			  	
		  	}else{
			  	this.props.history.push(this.props.parentPath);	
		  	}
		});
	}
	
	render() {

		if(this.state.id && (!this.props.products.products || !this.props.products.products[this.state.id])){
			return (
				<Loading />
			);
		}
		
		const { handleSubmit } = this.props;
		let title = i18n.t('products:product_add_title');
		let action = i18n.t('products:product_add_submit');
		let product = false;
		
		if(this.state.id){
			product = this.props.products.products[this.state.id];
			title = product.title;
			action = i18n.t('products:product_edit_submit');
		}
		
		/*let labelIcon = '';
		
		if(this.state.search){
			
			labelIcon = (
				<React.Fragment>
					<i className="fal fa-redo" onClick={() => { this.populateProduct(true); }} data-tip={i18n.t('products:product_label_sku_repopulate')}></i>
					<ReactTooltip 
						effect="solid"
					/>
				</React.Fragment>				
			);
		}*/
				
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{title}
					</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={[{
						label: i18n.t('products:product_toolbar_settings'),
						url: `${this.props.parentPath}/product${this.state.id ? '/' + this.state.id : ''}`,
						selected: true
					}]}
					buttons={[{
						label: action,
						submit: formName
					}]}
				/>	
				
				{product && product.readonly === 1 && 
					<div className="c-card c-alert u-mb-medium c-alert--danger">
		                <span className="c-alert__icon u-mb-small u-mr-small c-alert--danger">
							<i className="fal fa-inbox-in" style={{ color: '#FFF' }}></i>
						</span>
					
						<div className="c-alert__content">
							<h4 className="c-alert__title">Received product</h4>
							<p className="u-mb-small">This product was created for you by {this.props.brand.details.name}. You can edit this product but in doing so, all future updates from {this.props.brand.details.name} will not be reflected for this product.</p>
						</div>
					</div>
				}			
				
				<div className="row">
					<div className="col-xl-8 col-md-12">
						<div className="c-card">
														
							<div className="row">
							
								<div className="col-xl-6 col-md-12">
									<h4 className="u-mb-medium">{i18n.t('products:product_general_title')}</h4>
								</div>
								
								{checkPermission(this.props.account.permissions, 'PRODUCTS_SIM') && 
									
									<div className="col-xl-6 col-md-12">
										<Field
											placeholder={i18n.t('products:product_sim_search_placeholder')} 
											//labelIcon={labelIcon}
											name="search"
											loadOptions={this.searchProductsBouncer.bind(this)}
											//custom={true}
											type="suggest"
											emptyMessage={this.state.search && this.state.search.length >= this.state.searchMin ? i18n.t('products:product_sim_search_empty') : i18n.t('products:product_sim_search_min', { min: this.state.searchMin })} 
											//selected={this.state.search}
											onChangeFunc={this.onInputSearchSelect.bind(this)}
											onInputChange={this.onInputSearchChange.bind(this)}
											component={InputField}
											minLength={3}
										/>
									</div>
								}
							</div>
							
							<div className="row">
							
								<div className="col-xl-6 col-md-12">										
									
									<Field
										label={i18n.t('products:product_label_title')} 
										name="title"
										type="text"
										component={InputField}
										onChangeFunc={(e) => { this.onInputChangeContent('title', e); }}
									/>
									
									<Field
										label={i18n.t('products:product_label_sku')} 
										name="sku"
										type="text"
										component={InputField}
										onChangeFunc={(e) => { this.onInputChangeContent('sku', e); }}
									/>
																			
									<Field
										label={i18n.t('products:product_label_description')} 
										name="description"
										type="textarea-auto"
										component={InputField}
										onChangeFunc={(e) => { this.onInputChangeContent('description', e); }}
									/>											
									
									<Field
										label={i18n.t('products:product_label_barcode')} 
										name="barcode"
										type="text"
										component={InputField}
										onChangeFunc={(e) => { this.onInputChangeContent('barcode', e); }}
									/>
								</div>
								<div className="col-xl-6 col-md-12">
									<Field
										label={i18n.t('products:product_label_url')} 
										name="url"
										type="text"
										component={InputField}
									/>
									
									<Field
										label={i18n.t('products:product_label_brand')} 
										name="brand"
										type="text"
										component={InputField}
										onChangeFunc={(e) => { this.onInputChangeContent('brand', e); }}
									/>
																			
									<Field
										label={i18n.t('products:product_label_price')} 
										prepend={getSymbolFromCurrency(this.props.account.group.currency)}
										name="price"
										type="text"
										component={InputField}
										onChangeFunc={(e) => { this.onInputChangeContent('price', e); }}
									/>
									
									<Field
										name="sale"
										labelSwitch={i18n.t('products:product_label_price_sale')} 
										type="switch"
										selected={this.state.sale}
										onChangeFunc={this.onInputSaleChange.bind(this)}
										component={InputField}
									/> 
									
									{this.state.sale && 
										<Field
											name="price_sale"
											label={i18n.t('products:product_label_price_sale_price')} 
											prepend={getSymbolFromCurrency(this.props.account.group.currency)}
											type="text"
											component={InputField}
										/>
									}
									
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-12">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('products:product_image_title')}</h4>
			
							<div className="filepond-container minimal">
								<FilePond 
									maxFiles="1"
									ref={ref => this.pond = ref}
									acceptedFileTypes="image/*"
									allowFileEncode={true}
									itemInsertLocation="after"
			                      	labelIdle={i18n.t('filepond_idle', { count: this.state.maxFiles })}
			                      	labelFileLoading={i18n.t('filepond_fileLoading')}
			                      	labelTapToCancel={i18n.t('filepond_tapToCancel')}
			                      	labelFileWaitingForSize={i18n.t('filepond_waitingForSize')}
			                      	files={this.state.files}
			                      	server={{
										process: null,
										load: (source, load, error, progress, abort, headers) => {
											
											fetch(source)
												.then((res) => {
										            return res.blob();
										        }).then((res) => {
											      	load(res);
										        });
										}
									}}
									onupdatefiles={(files) => {

										if(files.length > 0){

											let fileItems = files.map(fileItem => fileItem.file);

											if(files[0].origin !== 3){ // NOT LOADED ON INIT
												this.setState({
													files: fileItems,
													files_changed: true
												});
											}
											
										}else{
											this.setState({
												files: [],
												files_changed: true
											});
										}
									}}
									imageEditEditor={Doka.create({
										onconfirm: (output) => {
											this.setState({
												files_changed: true
											});
										}
									})}
								/>
							</div>
						</div>
					</div>
				</div>
			</form>				
		);
	}
}

const validate = (values) => {	
	
	const errors = {};

	if (!values.title) {
		errors.title = 'Enter the product title!';
	}
	
	if (!values.sku) {
		errors.sku = 'Enter the product sku!';
	}
	
	if (!values.price) {
		errors.price = 'Enter the product price!';
	}
	
	return errors;
}

function mapStateToProps({ products, account, overlay, brand }, ownProps){
	return {
		products,
		account,
		overlay,
		brand
	};
}

export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		startSubmission, 
		stopSubmission,
		saveProduct,
		fetchProductList,
		makeSelection,
		searchProducts,
		showNotification,
		getProductImage
	} )(ProductsEdit)
);