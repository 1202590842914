import React, { Component } from "react";
import { connect } from "react-redux";
import OverlaySettings from "../../components/chrome/overlay_settings";
import i18n from "../../helpers/i18n";
import _ from "lodash";
import Toolbar from "../../components/chrome/toolbar";
import Papa from "papaparse";

import { checkPermission } from "../../helpers/permissions";
import {
  importProductsCSV,
} from "../../actions/products";

class ProductsImportCSV extends Component {
  constructor(props) {
    super(props);

    if (
      !checkPermission(this.props.account.permissions, "PRODUCTS_ADD") ||
      !checkPermission(this.props.account.permissions, "PRODUCTS_SIM")
    ) {
      this.props.history.push("/products");
    }

    this.state = {
      csvData: [],
    };
  }

  handleFileUpload = (event) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        this.setState({ csvData: results.data });
      },
    });
  };

  handleSubmit = async () => {
    this.props.importProductsCSV(this.state.csvData, () => {
      this.props.history.push(this.props.parentPath);	
    });
  };

  render() {
    const buttons = [];
    buttons.push({
      label: i18n.t("products:action_import_csv_submit"),
      onClick: () => this.handleSubmit(),
    });

    return (
      <>
        <OverlaySettings closeURL={this.props.parentPath} />

        <header className="c-navbar u-mb-medium">
          <h2 className="c-navbar__title">
            {i18n.t("products:import_csv_title")}
          </h2>
        </header>

        <Toolbar
          offset={0}
          tabs={[
            {
              label: i18n.t("products:import_toolbar_settings"),
              url: `${this.props.parentPath}/product/sku`,
              selected: true,
            },
          ]}
          buttons={buttons}
        />

        <div className="row">
          <div className="col-xl-12 col-md-12">
            <div className="c-card">
              <h4 className="u-mb-medium">
                {i18n.t("products:import_sku_title")}{" "}
              </h4>
              <div>
                <input
                  type="file"
                  accept=".csv"
                  onChange={this.handleFileUpload}
                  style={{
                    padding: "10px 15px",
                    border: "2px solid #ced4da",
                    borderRadius: "4px",
                    backgroundColor: "#f8f9fa",
                    color: "#495057",
                    cursor: "pointer",
                    fontSize: "16px",
                    outline: "none",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ users, account, products }, ownProps) {
  return {
    users,
    account,
    products,
  };
}

export default connect(mapStateToProps, {
  importProductsCSV,
})(ProductsImportCSV);
