import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import { startSubmission, stopSubmission } from '../../actions/forms';
import _ from 'lodash';
import Loading from '../../components/chrome/loading';
import Table from '../../components/chrome/table';
import EmptySection from '../../components/chrome/empty';
import Toolbar from '../../components/chrome/toolbar';

import { checkPermission } from '../../helpers/permissions';
import { fetchProductList, searchProducts, importProducts } from '../../actions/products';

var formName = 'formProductsImport';

class ProductsImport extends Component {

	constructor(props){
		super(props);
		
		this.props.stopSubmission(formName);	
		
		if(!checkPermission(this.props.account.permissions, 'PRODUCTS_ADD') || !checkPermission(this.props.account.permissions, 'PRODUCTS_SIM')){
			this.props.history.push('/products');
		}
		
		this.props.fetchProductList();
		
		this.state = {
			sku: [],
			search: [],
			searching: 0
		}
		
		this.skuBouncer = _.debounce(() => {
									
			if(this.state.sku && this.state.sku.length > 0){	
				
				const existing = this.props.products.search;
				let search = [];
								
				_.forEach(this.state.sku, (sku) => {
					
					if(!existing || !existing[sku]){
						search.push(sku);
					}
				});
				
				if(search.length > 0){
					
					this.setState({
						searching: ++this.state.searching
					});
					
					this.props.searchProducts(search, 'sku', () => {
						this.setState({
							search: this.state.sku,
							searching: --this.state.searching
						});
					});
				}else{
					this.setState({
						search: this.state.sku
					});
				}
				
			}else{
				this.setState({
					sku: [],
					search: []
				});
			}
			
		}, 500);
	}
	
	onInputSkuChange(event){

		this.setState({
			sku: _.map(_.uniqBy(_.compact(event.target.value.split('\n'))), (sku) => { return _.trim(sku) })
		});		
		
		this.skuBouncer();
	}
	
	onSubmit(values) {
				
		const products = this.props.products;
		
		if(products.search && this.state.search && !this.state.searching){
			
			let skus = [];
			
			_.forEach(this.state.search, (sku, key) => {	
				if(products.search[sku] && !_.find(products.products, {sku: sku})){
					skus.push(sku);
				}
			});
		
			if(skus.length > 0){
				
				this.props.startSubmission(formName);

			  	this.props.importProducts(formName, skus, () => {
				  	this.props.history.push(this.props.parentPath);	
			  	});			  	
			}
		}	
	}
	
	renderList() {
		
		if(this.state.search.length > 0){
			
			const products = this.props.products;

			if(products.search){
			
				return (
											
					<div className="u-mt-medium">
						<Table 
							columns={[
								{
									key: "icon",
									label: "",
									format: 'icon',
									className: 'tight'
								},
								{
									key: "sku",
									label: "SKU",
									sortable: true
								},
								{
									key: "title",
									label: "Title",
									sortable: true
								}
							]}
							data={_.map(this.state.search, (sku, key) => {	
								
								let valid = false;
								let tooltip = i18n.t('products:import_status_notfound');
								
								if(products.search[sku]){
									
									if(_.find(products.products, {sku: sku})){
										tooltip = i18n.t('products:import_status_exists');
									}else{
										tooltip = i18n.t('products:import_status_found');
										valid = true;
									}
								}
								
								return ({
									id: key,
									icon: {
										icon: `fal fa-${valid ? 'check' : 'times'}`,
										color: valid ? '#28c76f' : '#FF3333',
										tooltip: tooltip
									},
									sku: sku,
									title: products.search[sku] ? products.search[sku].title : 'Not found'							
								});
							})}
							/*sort={{
								column: 'retailer',
								order: 'asc'
							}}*/
						/>					
					</div>
				);
			}
		}
		
		return (
			<EmptySection
				icon="fa-exclamation-circle"
				description={i18n.t('products:import_preview_pending', { type: this.state.type })}
			/>
		);
	}

	render() {

		const { products } = this.props;

		if(!products.products){
			return (
				<Loading />
			);
		}	

		const { handleSubmit } = this.props;
		
		let buttons = [];
	
		if(products.search && this.state.search && !this.state.searching){
			
			let matches = 0;
			
			_.forEach(this.state.search, (sku, key) => {	
				if(products.search[sku] && !_.find(products.products, {sku: sku})){
					++matches;
				}
			});
					
			if(matches > 0){
				buttons.push({
					label: i18n.t('products:action_import_submit', { count: matches }),
					submit: formName
				});
			}
		}
		
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('products:import_title')}
					</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={[{
						label: i18n.t('products:import_toolbar_settings'),
						url: `${this.props.parentPath}/product/sku`,
						selected: true
					}]}
					buttons={buttons}
				/>
					
				<div className="row">
					<div className="col-xl-6 col-md-12">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('products:import_sku_title')} </h4>
							
							<Field
								label={i18n.t('products:import_label_sku')} 
								name="sku"
								type="textarea-auto"
								onChangeFunc={this.onInputSkuChange.bind(this)}
								component={InputField}
							/>
						</div>
					</div>
					
					<div className="col-xl-6 col-md-12">
						<div className="c-card preview">
							<h4>
								{i18n.t('products:import_title_preview')}
								
								{this.state.searching > 0 && 
									<div className="options">
										<i className="fas fa-spinner-third fa-spin"></i>
									</div>
								}
							</h4>
							{this.renderList()}
						</div>
					</div>						
				</div>
			</form>				
		);
	}
}

const validate = (values) => {	
	
	const errors = {};

	return errors;
}

function mapStateToProps({ users, account, products }, ownProps){
	return {
		users,
		account,
		products
	};
}

export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		startSubmission, 
		stopSubmission,
		fetchProductList,
		searchProducts,
		importProducts
	} )(ProductsImport)
);