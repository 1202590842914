// REWORK i18n.t params
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSectionOptions } from '../../actions/chrome';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import Table from '../../components/chrome/table';
import _ from 'lodash';
import { fetchCampaignsList } from '../../actions/campaigns';
import { fetchStatsOverview, fetchStatsCampaigns, fetchAccountTotals } from '../../actions/stats';
import { fetchSuggestions } from '../../actions/messages';
import Loading from '../../components/chrome/loading';
import moment from 'moment';
import 'moment/min/locales';
import { checkPermission } from '../../helpers/permissions';
import { imageResize } from '../../helpers/s3';
import { fetchAssetsList } from '../../actions/assets';
import { fetchBillingOverview } from '../../actions/billing';
import EmptySection from '../../components/chrome/empty';
import Soon from '../../components/chrome/soon';
import getSymbolFromCurrency from 'currency-symbol-map';

class Dashboard extends Component {

	constructor(props){
		super(props);
		
		this.props.setSectionOptions('dashboard', i18n.t('dashboard:title', { name: this.props.account.profile.forename }))
		this.props.fetchSuggestions();
		
		this.state = {
			fetched_assets: []
		}
		
		if(checkPermission(this.props.account.permissions, 'CAMPAIGNS')){
			this.props.fetchCampaignsList(false, () => {
				this.fetchAssets();				
			});
		}		
		
		this.props.fetchAccountTotals();
		
		if(checkPermission(this.props.account.permissions, 'RETAILERS_BILLING')){
			this.props.fetchBillingOverview();
		}
		
		moment.locale(i18n.language);
		
		let startDate = moment().subtract(1,'month').toDate();
		let endDate = moment().toDate();
		
		this.state = {
			date: {
	            startDate: startDate,
	            endDate: endDate
            },
            campaigns: {
	            draft: 'imported',
	            active: 'imported'
	        }
		}
		
		if(checkPermission(this.props.account.permissions, 'ANALYTICS')){
			this.props.fetchStatsOverview(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
			this.props.fetchStatsCampaigns(() => {
				this.fetchAssets();				
			});
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);

		this.statsRefreshTimer = setInterval(() => {
			
			// 12/05/2021 - Disabled due to FB limits usage
			/*this.props.fetchStatsOverview(moment(this.state.date.startDate).format('YYYY-MM-DD'), moment(this.state.date.endDate).format('YYYY-MM-DD'));
			this.props.fetchStatsCampaigns(() => {
				this.fetchAssets();				
			});*/
			
			this.props.fetchSuggestions();
			this.props.fetchAccountTotals();
			
			if(checkPermission(this.props.account.permissions, 'RETAILERS_BILLING')){
				this.props.fetchBillingOverview();
			}
		}, 60000); // EVERY MIN
	}
	
	componentWillUnmount(){
		clearInterval(this.statsRefreshTimer);
	}
	
	fetchAssets(){	
			
		if(checkPermission(this.props.account.permissions, 'ASSETS')){
	
			// LOAD IN THE FIRST ASSET
			let assets = [];
			
			if(this.props.campaigns.campaigns){
				
				_.forEach(this.props.campaigns.campaigns, (campaign) => {
					
					if(campaign.items && campaign.items.assets && !_.isEmpty(campaign.items.assets)){
						assets.push(campaign.items.assets[0]);
					}
				});
			}
			
			if(this.props.campaigns.import){
				
				_.forEach(this.props.campaigns.import, (campaign) => {
					
					if(campaign.items && campaign.items.assets && !_.isEmpty(campaign.items.assets)){
						assets.push(campaign.items.assets[0]);
					}
				});
			}
			
			if(this.props.stats.campaigns){
				
				if(this.props.stats.campaigns.deployed){
					
					_.forEach(this.props.stats.campaigns.deployed, (campaign) => {
					
						if(campaign.items && campaign.items.assets && !_.isEmpty(campaign.items.assets)){
							assets.push(campaign.items.assets[0]);
						}
					});					
				}
				
				if(this.props.stats.campaigns.created){
					
					_.forEach(this.props.stats.campaigns.created, (data) => {
						
						_.forEach(data, (campaign) => {
					
							if(campaign.items && campaign.items.assets && !_.isEmpty(campaign.items.assets)){
								assets.push(campaign.items.assets[0]);
							}
						});	
					});				
				}
				
				if(this.props.stats.campaigns.imported){
					
					_.forEach(this.props.stats.campaigns.imported, (data) => {
						
						_.forEach(data, (campaign) => {
					
							if(campaign.items && campaign.items.assets && !_.isEmpty(campaign.items.assets)){
								assets.push(campaign.items.assets[0]);
							}
						});	
					});				
				}
			}
			
			assets = _.difference(_.uniq(assets), this.state.fetched_assets);
			
			if(assets.length > 0){
				this.props.fetchAssetsList(assets);
			}
			
			this.setState({
				fetched_assets: this.state.fetched_assets ? this.state.fetched_assets.concat(assets) : assets
			});
		}			
	}
	
	onInputDateChange(date){
		
		this.setState({
			date: date
		});
		
		this.props.fetchStatsOverview(moment(date.startDate).format('YYYY-MM-DD'), moment(date.endDate).format('YYYY-MM-DD'));
	}
	
	toggleCampaignType(type, tab){
		
		let campaigns = this.state.campaigns;
		
		campaigns[type] = tab;
		
		this.setState({
			campaigns: campaigns
		});
	}
	
	renderDraftCampaigns(){
		
		let stats = this.props.stats.campaigns;
		let description = i18n.t('dashboard:campaigns_draft_description');
		
		let data = [];
		let tabs = '';
		
		if(stats.deployed){			
			data = stats.deployed.draft;
			description = i18n.t('dashboard:campaigns_draft_description_deploy');
		}else{
			
			if(this.state.campaigns.draft === 'imported'){
				data = stats.imported.draft;
			}else{
				data = stats.created.draft;
			}		
			
			data = _.orderBy(data, ['created.date'],['desc']);
			
			tabs = (
				<div className="c-tabs tabs"> 
					<button className={`c-tabs__link ${this.state.campaigns.draft === 'imported' ? 'active' : ''}`} onClick={() => { this.toggleCampaignType('draft', 'imported'); }}>
						{i18n.t('dashboard:campaigns_recent_tab_imported')}
					</button>
					<button className={`c-tabs__link ${this.state.campaigns.draft === 'created' ? 'active' : ''}`} onClick={() => { this.toggleCampaignType('draft', 'created'); }}>
						{i18n.t('dashboard:campaigns_recent_tab_created')}
					</button>
				</div>
			);	
		}
		
		return (
			<React.Fragment>
				<h4>{i18n.t('dashboard:campaigns_draft_title')}</h4>
				<p className="u-mb-medium">{description}</p>
				
				{tabs}
				
				{_.isEmpty(data) &&
					
					<EmptySection
						icon="fa-exclamation-circle"
						title={i18n.t('dashboard:campaigns_empty_title')}
						description={i18n.t('dashboard:campaigns_empty_description')}
						cta={{
							label: i18n.t('dashboard:campaigns_action_add'),
							url: '/campaigns/add'
						}}
					/>
				}
				
				{!_.isEmpty(data) &&
					
					<Table 
						columns={[
							{
								key: "image",
								label: "",
								format: 'image_circle',
								className: 'tight'
							},
							{
								key: "name",
								label: "Name",
								sortable: true
							}
						]}
						data={_.map(data, (campaign, key) => {
																							
							let image = false;	
							let url = false;
													
							if(campaign.items){
											
								if(campaign.items.assets && !_.isEmpty(campaign.items.assets) && this.props.assets.assets && this.props.assets.assets[campaign.items.assets[0]]){
									
									let asset = this.props.assets.assets[campaign.items.assets[0]];
									image = imageResize(asset.thumbnail, 100, 100);	
									url = `/asset/${asset.id}`;				
								}
							}
													
							return ({
								id: campaign.id,
								image: {
									src: image,
									icon: 'fal fa-hashtag',
									alt: campaign.internal_name,
									url: url
								},
								name: campaign.internal_name,
								actions: ['edit']
							});
						})}
						actions={{
							/*analytics: {
								tooltip: i18n.t('dashboard:campaigns_action_analytics'),
								icon: 'fal fa-analytics',
								url: '/campaigns/[ID]/analytics'
							},*/
							edit: {
								tooltip: i18n.t('dashboard:campaigns_action_edit'),
								icon: 'fal fa-edit',
								url: '/campaigns/[ID]'
							}
						}}
						sort={{
							column: 'name',
							order: 'asc'
						}}
						rowClick={(row) => {
							
							if(row.actions.includes('analytics')){
								this.props.history.push(`/campaigns/${row.id}/analytics`);
							}else{
								this.props.history.push(`/campaigns/${row.id}`);
							}
						}}
					/>	
				}
			</React.Fragment>
		)
	}
	
	renderActiveCampaigns(){
		
		let stats = this.props.stats.campaigns;
		
		let description = i18n.t('dashboard:campaigns_active_description');		
		let data = [];
		let tabs = '';
		
		if(stats.deployed){			
			data = stats.deployed.active;
			description = i18n.t('dashboard:campaigns_active_description_deployed');
		}else{
			
			if(this.state.campaigns.active === 'imported'){
				data = stats.imported.active;
			}else{
				data = stats.created.active;
			}		
			
			data = _.orderBy(data, ['date_start.date'],['desc']); // TODO - here order by highest numbers?
			
			tabs = (
				<div className="c-tabs tabs"> 
					<button className={`c-tabs__link ${this.state.campaigns.active === 'imported' ? 'active' : ''}`} onClick={() => { this.toggleCampaignType('active', 'imported'); }}>
						{i18n.t('dashboard:campaigns_recent_tab_imported')}
					</button>
					<button className={`c-tabs__link ${this.state.campaigns.active === 'created' ? 'active' : ''}`} onClick={() => { this.toggleCampaignType('active', 'created'); }}>
						{i18n.t('dashboard:campaigns_recent_tab_created')}
					</button>
				</div>
			);	
		}
		
		return (
			<React.Fragment>
				<h4>{i18n.t('dashboard:campaigns_active_title')}</h4>
				<p className="u-mb-medium">{description}</p>
				
				{tabs}
				
				{_.isEmpty(data) &&
					
					<EmptySection
						icon="fa-exclamation-circle"
						title={i18n.t('dashboard:campaigns_empty_title')}
						description={i18n.t('dashboard:campaigns_empty_description')}
						cta={{
							label: i18n.t('dashboard:campaigns_action_add'),
							url: '/campaigns/add'
						}}
					/>
				}
				
				{!_.isEmpty(data) &&
					
					<Table 
						columns={[
							{
								key: "image",
								label: "",
								format: 'image_circle',
								className: 'tight'
							},
							{
								key: "name",
								label: "Name",
								sortable: true
							},
							{
								key: "facebook",
								label: {
									icon: 'fab fa-facebook-f',
									tooltip: 'Facebook Engagement',
								},
								sortable: true,
								className: "tight icon"
							},
							{
								key: "instagram",
								label: {
									icon: 'fab fa-instagram',
									tooltip: 'Instagram Engagement',
								},
								sortable: true,
								className: "tight icon"
							},
							{
								key: "twitter",
								label: {
									icon: 'fab fa-x-twitter',
									tooltip: 'Twitter Engagement',
								},
								sortable: true,
								className: "tight icon"
							},
							{
								key: "email",
								label: {
									icon: 'fas fa-envelope',
									tooltip: 'Email Opens',
								},
								sortable: true,
								className: "tight icon"
							},
							{
								key: "landing",
								label: {
									icon: 'fas fa-laptop',
									tooltip: 'Landing Page Impressions',
								},
								sortable: true,
								className: "tight icon"
							},
							{
								key: "slider",
								label: {
									icon: 'fas fa-arrows-h',
									tooltip: 'Slider Impressions',
								},
								sortable: true,
								className: "tight icon"
							},
							{
								key: "advert",
								label: {
									icon: 'fas fa-ad',
									tooltip: 'Advert Impressions',
								},
								sortable: true,
								className: "tight icon"
							}
						]}
						data={_.map(data, (campaign, key) => {
																							
							let image = false;	
							let url = false;
													
							if(campaign.items){
											
								if(campaign.items.assets && !_.isEmpty(campaign.items.assets) && this.props.assets.assets && this.props.assets.assets[campaign.items.assets[0]]){
									
									let asset = this.props.assets.assets[campaign.items.assets[0]];
									image = imageResize(asset.thumbnail, 100, 100);	
									url = `/asset/${asset.id}`;				
								}
							}
													
							return ({
								id: campaign.id,
								image: {
									src: image,
									icon: 'fal fa-hashtag',
									alt: campaign.internal_name,
									url: url
								},
								name: campaign.internal_name,
								facebook: campaign.stats.type.facebook ? campaign.stats.type.facebook.engagement : 0,
								instagram: campaign.stats.type.instagram ? campaign.stats.type.instagram.engagement : 0,
								twitter: campaign.stats.type.twitter ? campaign.stats.type.twitter.engagement : 0,
								email: campaign.stats.type.email ? campaign.stats.type.email.impression : 0,
								landing: campaign.stats.type.landing ? campaign.stats.type.landing.impression : 0,
								slider: campaign.stats.type.slider ? campaign.stats.type.slider.impression : 0,
								advert: campaign.stats.type.advert ? campaign.stats.type.advert.impression : 0,								
								actions: ['analytics']
							});
						})}
						actions={{
							/*analytics: {
								tooltip: i18n.t('dashboard:campaigns_action_analytics'),
								icon: 'fal fa-analytics',
								url: '/campaigns/[ID]/analytics'
							},*/
							edit: {
								tooltip: i18n.t('dashboard:campaigns_action_edit'),
								icon: 'fal fa-edit',
								url: '/campaigns/[ID]'
							}
						}}
						sort={{
							column: 'name',
							order: 'asc'
						}}
						rowClick={(row) => {
							
							if(row.actions.includes('analytics')){
								this.props.history.push(`/campaigns/${row.id}/analytics`);
							}else{
								this.props.history.push(`/campaigns/${row.id}`);
							}
						}}
					/>	
				}
			</React.Fragment>
		)
		
		
		
		
		
		
		/*
		<p className="u-mb-xsmall">R5</p>
		<div className="c-progress c-progress--info c-progress--medium u-mb-small">
			<div className="c-progress__bar" style={{ width: '90%' }}></div>
		</div>

		<p className="u-mb-xsmall">P5</p>
		<div className="c-progress c-progress--info c-progress--medium u-mb-small">
			<div className="c-progress__bar" style={{ width: '75%' }}></div>
		</div>
		
		<p className="u-mb-xsmall">R2 </p>
		<div className="c-progress c-progress--info c-progress--medium u-mb-small">
			<div className="c-progress__bar" style={{ width: '45%' }}></div>
		</div>
		
		<p className="u-mb-xsmall">S3</p>
		<div className="c-progress c-progress--info c-progress--medium">
			<div className="c-progress__bar" style={{ width: '5%' }}></div>
		</div>
		*/
		
		return (
			<React.Fragment>
				<h4>{i18n.t('dashboard:performance_title')}</h4>
				<p className="u-mb-medium">{description}</p>
		
				<Soon
					icon="fa-hourglass-half"
					title={i18n.t('translation:soon_title')}
					description={i18n.t('translation:soon_description')}
				/>
				
			</React.Fragment>
		)
	}
	
	renderOverview(){
		
		let title = '';
		let icon = '';
		let className = '';
		let prefix = '';
		let sub = '';
		let url = '';
		let decimals = 0;
		let cols = 2;
		
		let data = [];
		
		if(this.props.stats.overview.deployed){			
			data = this.props.stats.overview.deployed;
		}else{
			data = this.props.stats.overview.all;
		}
				
		return _.map(data, (value, key) => {
			
			sub = '';
			url = false;
			decimals = 0;
									
			switch(key){
				
				case 'impression':
					title = 'Impressions';
					icon = 'eye';
					className = 'c-icon--info';
					
					if(checkPermission(this.props.account.permissions, 'ANALYTICS')){
						url = `/analytics?from=${moment(this.state.date.startDate).format('YYYY-MM-DD')}&to=${moment(this.state.date.endDate).format('YYYY-MM-DD')}`;
					}
					break;
					
				case 'retailers':
					title = 'Retailers';
					icon = 'store-alt';
					className = 'c-icon--secondary';
					url = '/retailers';
					break;
				
				case 'click':
					title = 'Clicks';
					icon = 'mouse-pointer';
					className = 'c-icon--success';
					
					if(checkPermission(this.props.account.permissions, 'ANALYTICS')){
						url = `/analytics?from=${moment(this.state.date.startDate).format('YYYY-MM-DD')}&to=${moment(this.state.date.endDate).format('YYYY-MM-DD')}`;
					}
					break;
					
				case 'engagement':
					title = 'Engagements';
					icon = 'thumbs-up';
					className = 'c-icon--warning';
					
					if(checkPermission(this.props.account.permissions, 'ANALYTICS')){
						url = `/analytics?from=${moment(this.state.date.startDate).format('YYYY-MM-DD')}&to=${moment(this.state.date.endDate).format('YYYY-MM-DD')}`;
					}
					break;
					
				case 'conversion':
					title = 'Conversions';
					icon = 'receipt';
					className = 'c-icon--secondary';
					
					/*sub = (
						<CountUp 
							className="sub"
							delay={0} 
							duration={1} 
							end={value/100} 
							preserveValue={true}
							suffix="%"
						/>
					)*/
					
					if(checkPermission(this.props.account.permissions, 'ANALYTICS')){
						url = `/analytics?from=${moment(this.state.date.startDate).format('YYYY-MM-DD')}&to=${moment(this.state.date.endDate).format('YYYY-MM-DD')}`;
					}
					break;
					
				case 'value':
					title = 'Revenue';
					icon = 'money-bill';
					className = 'c-icon--danger';
					//prefix = '£';
					decimals = 2;
					
					if(checkPermission(this.props.account.permissions, 'ANALYTICS')){
						url = `/analytics?from=${moment(this.state.date.startDate).format('YYYY-MM-DD')}&to=${moment(this.state.date.endDate).format('YYYY-MM-DD')}`;
					}
					break;
					
				default:
				
					break;
			}		
			
			let element = (
				<React.Fragment>
					<span className={`c-icon u-mb-small ${className}`}>
						<i className={`fal fa-${icon}`}></i>
					</span>
							
					<h3 className="c-text--subtitle">{title}</h3>
					<h2 className="counter">
						<CountUp 
							delay={0} 
							duration={1} 
							end={value} 
							separator="," 
							preserveValue={true}
							prefix={prefix}
							decimals={decimals}
						/>
						
						{sub}
					</h2>
				</React.Fragment>
			);																
							
			return (
				<div className={`col-sm-6 col-md-4 col-xl-2`} key={key}>
					
					{url && 
						<Link to={url} className="c-card equalize stats-box">
							{element}
						</Link>
					}
					
					{!url && 
						<div className="c-card equalize stats-box">
							{element}
						</div>
					}
				</div>
			);
		});
	}
	
	renderSnapshot(){
		
		if(!this.props.stats.totals){
			return null;
		}		
		
		let parentCols = 12;
		
		if(checkPermission(this.props.account.permissions, 'RETAILERS_BILLING')){
			parentCols = 9;
		}
		
		let title = '';
		let icon = '';
		let url = '';
		let suffix = '';
		let cols = '';
		let data = this.props.stats.totals;
		
		switch(Object.keys(data).length){
			
			case 1:
				cols = 12;
				break;
				
			case 2:
				cols = 6;
				break;
				
			case 3:
				cols = 4;
				break;
				
			case 4:
				cols = 3;
				break;
				
			case 5:
			case 6:
				if(parentCols == 12){
					cols = 2;
				}else{
					cols = 4;
				}
				break;			
				
			default:
				cols = 3;
				break;
		}
		
		return (
			<div className={`col-sm-12 col-md-${parentCols} col-xl-${parentCols}`}>
				<div className="c-card u-pb-zero equalize">
					<h4 className="u-mb-medium">{i18n.t('dashboard:snapshot_title')}</h4>	
					<div className="row">
				
						{_.map(data, (value, key) => {
						
							url = false;
							suffix = '';
													
							switch(key){
								
								case 'campaigns':
									title = i18n.t('dashboard:snapshot_title_campaigns');
									icon = 'hashtag';	
									url = '/campaigns';				
									break;
									
								case 'assets':
									title = i18n.t('dashboard:snapshot_title_assets');
									icon = 'images';
									url = '/assets';
									break;
								
								case 'articles':
									title = i18n.t('dashboard:snapshot_title_articles');
									icon = 'file-alt';
									url = '/articles';
									break;
									
								case 'products':
									title = i18n.t('dashboard:snapshot_title_products');
									icon = 'bicycle';
									url = '/products';
									break;
									
								case 'subscribers':
									title = i18n.t('dashboard:snapshot_title_subscribers');
									icon = 'at';
									url = '/subscribers';
									break;
									
								case 'retailers':
									title = i18n.t('dashboard:snapshot_title_retailers');
									icon = 'store-alt';
									url = '/retailers';
									break;
									
								case 'regional':
									title = i18n.t('dashboard:snapshot_title_regional');
									icon = 'users';
									url = '/regional';
									break;
				
									
								case 'account':
									title = 'Account';
									suffix = '%';
									icon = 'user';
									url = '/account';
									break;
									
								default:
								
									break;
							}		
							
							let element = (
								<React.Fragment>
									<span className="c-icon u-mb-small">
										<i className={`fal fa-${icon}`}></i>
									</span>
											
									<h3 className="c-text--subtitle">{title}</h3>
									<h2 className="counter">
										<CountUp 
											delay={0} 
											duration={1} 
											end={value} 
											separator="," 
											suffix={suffix}
											preserveValue={true}
										/>
									</h2>
								</React.Fragment>
							);																
											
							return (
								<div className={`col-sm-6 col-md-4 col-xl-${cols}`} key={key}>
								
									{url && 
										<Link to={url} className="c-card equalize stats-box">
											{element}
										</Link>
									}
									
									{!url && 
										<div className="c-card equalize stats-box">
											{element}
										</div>
									}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
	
	renderSuggestions(){
		
		let title = '';
		let icon = '';
		let className = '';
		let description = '';
		let cta = '';
		let url = '';

		if(this.props.messages.suggestions && this.props.messages.suggestions.length > 0){
			
			let suggestion = this.props.messages.suggestions[0];

			switch(suggestion.type){
				
				case 'import':
					icon = 'plus';
					className = 'c-alert--danger';
					title = i18n.t('dashboard:suggestion_import_title', { count: suggestion.count });
					description = i18n.t('dashboard:suggestion_import_description', { count: suggestion.count });
					cta = i18n.t('dashboard:suggestion_import_button', { count: suggestion.count });
					url = `/campaigns?type=import`;
					break;
					
				case 'deploy':
					let days = moment().diff(moment(suggestion.last), "days");
					icon = 'clock';
					className = 'c-alert--danger';
					title = i18n.t('dashboard:suggestion_deploy_title');
					description = i18n.t('dashboard:suggestion_deploy_description', { days: days });
					cta = i18n.t('dashboard:suggestion_deploy_button');
					url = `/campaigns/add`;
					break;
					
				case 'deploy_first':
					icon = 'plus';
					className = 'c-alert--danger';
					title = i18n.t('dashboard:suggestion_deploy_first_title');
					description = i18n.t('dashboard:suggestion_deploy_first_description');
					cta = i18n.t('dashboard:suggestion_deploy_first_button');
					url = `/campaigns/add`;
					break;
					
				default:
				
					break;
			}

			return (
				<div className={`c-card c-alert u-mb-medium ${className}`}>
	                <span className={`c-alert__icon u-mb-small u-mr-small ${className}`}>
						<i className={`fal fa-${icon}`} style={{ color: '#FFF' }}></i>
					</span>
				
					<div className="c-alert__content">
						<h4 className="c-alert__title">{title}</h4>
						<p className="u-mb-small">{description}</p>
						<Link to={url} className="c-btn c-btn--info">{cta}</Link>
					</div>
				</div>
			);
		}
	}
	
	renderCampaigns() {
		
		if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS')){
			return false;
		}
		
		if(!this.props.stats.overview || !this.props.stats.campaigns || !this.props.campaigns.campaigns){
			return (
				<Loading />
			);
		}
		
		let title = i18n.t('dashboard:overview_title');
		
		if(this.props.stats.overview.deployed){			
			title = i18n.t('dashboard:overview_title_deploy');
		}
		
		return (
			<React.Fragment>
				<div className="c-card u-pb-zero">
					<h4 className="u-mb-medium">
		         		{title}
		         		
		         		<div className="options">
			         		<InputField
								name="date_range"
								type="datepicker"
								alignRight={true}
								range={{
									months: 2
								}}
								maxDate={moment().toDate()}
								selected={this.state.date}
								autoWidth={true}
								onChangeFunc={this.onInputDateChange.bind(this)}
							/>
		         		</div>
		         	</h4>	
					<div className="row col-xl-flex">
						{this.renderOverview()}
					</div>
				</div>
				
				<div className="row">
					<div className="col-md-4">
						<div className="c-card equalize">
							{this.renderDraftCampaigns()}
						</div>
					</div>
				
					<div className="col-md-8">
						<div className="c-card equalize">
							{this.renderActiveCampaigns()}							
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
	
	renderBilling(){
		
		let { billing } = this.props;
		
		if(!billing.overview){
			return (
				<Loading />
			);
		}	
		
		const currency = getSymbolFromCurrency(billing.overview.currency);
		
		let invoiceTotal = '';
		
		if(billing.overview.invoices.due.total > 0){
			
			invoiceTotal = (
				<CountUp 
					className="sub"
					delay={0} 
					duration={1} 
					end={billing.overview.invoices.due.total} 
					preserveValue={true}
					prefix={currency}
				/>
			);
		}
				
		return (
			<div className={`col-sm-12 col-md-3 col-xl-3`}>
				<div className="c-card u-pb-zero equalize">
					<h4 className="u-mb-medium">{i18n.t('dashboard:billing_title')}</h4>	
					
					<Link to="/settings/billing" className="c-card stats-box">
						<span className="c-icon u-mb-small">
							<i className="fal fa-receipt"></i>
						</span>
								
						<h3 className="c-text--subtitle">{i18n.t('dashboard:billing_title_invoices')}</h3>
						<h2 className="counter">
							<CountUp 
								delay={0} 
								duration={1} 
								end={billing.overview.invoices.due.count} 
								separator="," 
								preserveValue={true}
							/>
							
							{invoiceTotal}
						</h2>
					</Link>
				
					<Link to="/settings/billing" className="c-card stats-box">
						<span className="c-icon u-mb-small">
							<i className="fal fa-money-bill"></i>
						</span>
								
						<h3 className="c-text--subtitle">{i18n.t('dashboard:billing_title_estimate', { month: moment().format('MMM') })}</h3>
						<h2 className="counter">
							<CountUp 
								delay={0} 
								duration={1} 
								end={billing.overview.month.total} 
								separator="," 
								prefix={currency}
								preserveValue={true}
							/>
						</h2>
					</Link>
				</div>
			</div>
		);
	}
	
	render() {
		
		if(!this.props.messages.suggestions || !this.props.stats.totals){
			return (
				<Loading />
			);
		}		

		return (
			<div className="container">
							
				{this.renderSuggestions()}
				{this.renderCampaigns()}
				
				<div className="row">
					{this.renderSnapshot()}
					{this.renderBilling()}
				</div>
			</div>
		);
	}
}

function mapStateToProps({ account, brand, stats, messages, campaigns, assets, billing }, ownProps){
	return {
		account,
		brand,
		stats,
		messages,
		campaigns,
		assets,
		billing
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchStatsOverview,
	fetchSuggestions,
	fetchStatsCampaigns,
	fetchCampaignsList,
	fetchAssetsList,
	fetchAccountTotals,
	fetchBillingOverview
})(Dashboard);