// REWORK i18n.t params
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSectionOptions } from '../../actions/chrome';
import i18n from '../../helpers/i18n'; 
import { checkPermission } from '../../helpers/permissions';
import { fetchSchedule } from '../../actions/calendar';
import _ from 'lodash';

import ReactTooltip from 'react-tooltip';

import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';

import moment from 'moment-timezone';
import 'moment/min/locales';
import { dateString } from '../../helpers/dates';
import { imageResize } from '../../helpers/s3';

class Calendar extends Component {

	calendarRef = React.createRef();
	
	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'CALENDAR')){
			this.props.history.push('/');
		}
		
		this.state = {
			view: 'month',
			title: moment().format('MMM YYYY'),
			clear: true,
			hover: false
		}
		
		this.props.setSectionOptions('calendar', i18n.t('calendar:title'))	
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	setView(view){
		
		const calendarApi = this.calendarRef.current.getApi();

		switch(view){
			
			case 'month':
				calendarApi.changeView('dayGridMonth');
				break;
				
			case 'week':
				calendarApi.changeView('dayGridWeek');
				break;
				
			default:
			
				break;
		}
							
		this.setState({
			view: view
		});
	}
	
	setPeriod(dir){
		
		const calendarApi = this.calendarRef.current.getApi();
		
		switch(dir){
			
			case 'previous':
				calendarApi.prev();
				break;
				
			case 'next':
				calendarApi.next();
				break;
				
			case 'today':
				calendarApi.today();
				break;
				
			default:
			
				break;
		}
	}
	
	render() {	
		
		let events = [];
		let type = '';
		let end = '';
		let color = '';
		let icon = '';
		let issues = []	
				
		_.forEach(this.props.calendar.schedule, (data, scheduleType) => {
				
			_.forEach(data, (event, key) => {
			
				switch(scheduleType){
					
					case 'deploy':
						events.push({ 
							id: event.id, 
							start: moment(event.deploy.date).format('YYYY-MM-DD HH:mm:ss'), 
							end: false, 
							type: event.type,
							status: 'deploy',
							color: '#0000FF',
			      			icon: 'fal fa-share',
			      			title: event.internal_name
						});
						break;
						
					case 'upcoming':
						events.push({ 
							id: event.id, 
							start: moment(event.deploy.date).format('YYYY-MM-DD HH:mm:ss'), 
							end: false, 
							type: event.type,
							status: 'upcoming',
							color: '#0000FF',
			      			icon: 'fal fa-alarm-clock'
						});
						break;
						
					case 'events':
						
						switch(event.type){
				
							case 'twitter':
								type = i18n.t('campaigns:event_type_twitter');
								//color = '#38A1F3';
								icon = 'fab fa-x-twitter';
								break;
								
							case 'facebook':
								type = i18n.t('campaigns:event_type_facebook');
								//color = '#4267b2';
								icon = 'fab fa-facebook';
								break;
								
							case 'instagram':
								type = i18n.t('campaigns:event_type_instagram');
								//color = '#231F20';
								icon = 'fab fa-instagram';
								break;
								
							case 'email':
								type = i18n.t('campaigns:event_type_email');
								//color = '#BD081C';
								icon = 'fal fa-envelope';
								break;
								
							case 'landing':
								type = i18n.t('campaigns:event_type_landing');
								///color = '#5F9EA0';
								icon = 'fal fa-browser';
								break;
								
							case 'slider':
								type = i18n.t('campaigns:event_type_slider');
								//color = '#5F9EA0';
								icon = 'fal fa-arrows-h';
								break;	
								
							case 'advert':
								type = i18n.t('campaigns:event_type_advert');
								//color = '#5F9EA0';
								icon = 'fal fa-ad';
								break;		
								
							default:
								type = 'Unknown';
								//color = '#CCCCCC';
								icon = '';
								break;
						}	
						
						switch(event.status){
							
							case 'pending':
								color = '#f5a155';
								break;
								
							case 'active':
								color = '#28c76f';
								break;
								
							case 'failed':
								color = '#ee4343';
								break;
								
							case 'complete':
								color = '#768093';
								break;		
								
							default:
							
								break;			
						}
						
						switch(event.type){
							
							case 'landing':
							case 'slider':
							case 'advert':
								end = moment(event.end.date).format('YYYY-MM-DD HH:mm:ss');
								break;		
								
							default:
								end = false;
								break;
						}
						
						events.push({ 
							id: event.id, 
							campaign_id: event.campaign_id, 
							title: type, 
							start: moment(event.start.date).format('YYYY-MM-DD HH:mm:ss'), 
							end: end, 
							type: event.type,
							status: event.status,
							color: color,
			      			icon: icon,
			      			issues: event.issues ? event.issues : false,
			      			screenshot: event.screenshot ? event.screenshot : false
						});
						
						if(event.issues){
							issues.push(event.id);
						}
						break;
						
					default:
					
						break;
				}
			});	
		});
				
		return (
			<div className="container flex-fill">
				<div className="c-card u-mb-zero flex-strech">
				

					<h4 className="u-mb-medium">

						{this.state.title}
						
						<div className="options">
							<i className={`fal fa-chevron-left`} onClick={() => { this.setPeriod('previous'); }} data-tip={i18n.t(`calendar:tooltip_period_previous_${this.state.view}`)}></i>
							<i className={`fal fa-dot-circle`} onClick={() => { this.setPeriod('today'); }} data-tip={i18n.t(`calendar:tooltip_period_current_${this.state.view}`)}></i>
							<i className={`fal fa-chevron-right`} onClick={() => { this.setPeriod('next'); }} data-tip={i18n.t(`calendar:tooltip_period_next_${this.state.view}`)}></i>
							
							<div className="spacer"></div>
							
							<i className={`fal fa-calendar ${this.state.view === 'month' ? 'selected' : ''}`} onClick={() => { this.setView('month'); }} data-tip={i18n.t('calendar:tooltip_view_month')}></i>
							<i className={`fal fa-calendar-week ${this.state.view === 'week' ? 'selected' : ''}`} onClick={() => { this.setView('week'); }} data-tip={i18n.t('calendar:tooltip_view_week')}></i>
						</div>
					</h4>	
													
					<FullCalendar
				    	ref={this.calendarRef}
				    	now={moment().tz(this.props.account.profile.timezone).toDate()}
				        initialView="dayGridMonth"
				        plugins={[
							dayGridPlugin, 
							interactionPlugin
						]}
						events={events}
						expandRows={true}
						nowIndicator={true}
						eventClick={(info)=> { 
														
							switch(info.event.extendedProps.status){
								
								case 'deploy':
									this.props.history.push(`campaigns/${info.event.id}`);
									break;
									
								case 'upcoming':

									break;
									
								default:
									if(info.event.id){
										this.props.history.push(`${this.props.location.pathname}/event/${info.event.extendedProps.campaign_id}/${info.event.id}`);
									}
									break;
							}
						}}
						eventMouseEnter={(info)=> { 
														
							switch(info.event.extendedProps.status){
								
								case 'deploy':
								case 'upcoming':

									break;
									
								default:
									if(!this.state.hover || this.state.hover !== info.event.id){
										this.setState({
											hover: info.event.id
										});
									}
									break;
							}
						}}
						eventMouseLeave={(info)=> { 
							if(this.state.hover){						
								this.setState({
									hover: false
								});
							}
						}}
						headerToolbar={false}
						locales={allLocales}
						locale={i18n.language}
						timeZone={this.props.account.profile.timezone}
						height="calc(100% - 60px)"
						eventDisplay='block'
						eventContent={(info) => {
																																			
							let eventItem = document.createElement('div');
											
							let icon = document.createElement('i');
							icon.setAttribute('aria-hidden', "true");
							icon.setAttribute('class', `type ${info.event.extendedProps.icon}`);
							eventItem.appendChild(icon);

							let time = document.createElement('span');
							time.setAttribute('class', `time`);
							time.innerHTML = moment(info.event.start).format('HH:mm');
							eventItem.appendChild(time);	
							
							eventItem.setAttribute("data-tip", `${info.event.extendedProps.status}:${info.event.id}`);
							
							if(issues.includes(parseFloat(info.event.id))){
								eventItem.classList.add('issue');
							}
																					
							return { domNodes: [ eventItem ] } 
						}}
						eventDidMount={() => {
							ReactTooltip.rebuild();
						}}
						eventClassNames={(info) => {
						  
						  	if(this.state.hover && this.state.hover === info.event.id){
							  	return ['hover'];
						  	}else{
							  	return [];
						  	}
						}}
						datesSet={(dateInfo) => {
							
							let title = dateInfo.view.title;
							
							if(dateInfo.view === 'dayGridWeek'){
								title = dateString('date', moment(dateInfo.startStr).format('YYYY-MM-DD'), moment(dateInfo.endStr).subtract(1, 'day').format('YYYY-MM-DD'))
							}
							
							if(this.state.title !== title){								
								
								this.props.fetchSchedule(
									moment(dateInfo.startStr).format('YYYY-MM-DD'), 
									moment(dateInfo.endStr).subtract(1, 'day').format('YYYY-MM-DD'),
									this.state.clear
								);
								
								this.setState({
									title: title,
									clear: false
								});
							}
						}}
						dayHeaderDidMount={(info) => {
					
							let content = info.el.innerHTML;
							content = content.replace('<a class=\"fc-col-header-cell-cushion \">', '');
							content = content.replace('</a>', '');
							
							info.el.innerHTML = content;
						}}
						dateClick={(info) => {
							this.setView('week');
							this.calendarRef.current.getApi().gotoDate(info.date);
						}}
				    />
				    <ReactTooltip 
						effect="solid"
						effect="float"
						delayShow={100}
						getContent={(info) => { 
							
							if(info){
								
								info = info.split(':');
							
								let event = _.find(events, { status: info[0], id: parseFloat(info[1]) });
								
								if(event){
									
									let tooltip = ``;
									
									switch(event.status){
										
										case 'deploy':
											tooltip = (
												<React.Fragment>
													Campaign {event.title} deploying to retailers
												</React.Fragment>
											);
											break;
											
										case 'upcoming':
											tooltip = (
												<React.Fragment>
													{i18n.t(`campaigns:campaign_type_${event.type}`)} campaign incoming!
												</React.Fragment>
											);
											break;
											
										default:
											if(event.screenshot){
												tooltip = (
													<img 
														alt={event.title}
														src={imageResize(event.screenshot, 250)}
														width="250"
													/>
												);
											}else{
												tooltip = (
													<div className="soon">
														Preview Queued
													</div>
												);
											}
											break;
									}
									
									return (
										<div className="preview-popup">
											<i className={event.icon}></i>
											{moment(event.start).format('MMM D, HH:mm')}
											
											{event.end && 
												<React.Fragment>
													{` - ${moment(event.end).format('MMM D, HH:mm')}`}
												</React.Fragment>
											}

											<br/><br/>
											{tooltip}
										</div>
									);
								}
							}
							
							return null;
						}}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps({ account, brand, calendar }, ownProps){
	return {
		account,
		brand,
		calendar
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchSchedule
})(Calendar);