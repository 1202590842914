import { assetsConstants } from '../constants/assets';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';
import { showNotification } from './notifications';
import i18n from '../helpers/i18n'; 
import _ from 'lodash';

export function fetchCollectionsList(id, callback) {

    return async (dispatch) => {
		
		let url = '/collection/list';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: assetsConstants.COLLECTION_LIST,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {

			const errorMessage = error.response ? error.response.data : 'Network error or server did not respond';
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: errorMessage
			});
		};
	}
}

export function fetchCollectionAssets(id, callback) {

    return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get(`/collection/assets/${id}`);
										
			dispatch({ 
				type: assetsConstants.ASSET_LIST,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			const errorMessage = error.response ? error.response.data : 'Network error or server did not respond';
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: errorMessage
			});
		};
	}
}

export function fetchAssetsList(id, callback) {

    return async (dispatch) => {
		
		let url = '/asset/list';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: assetsConstants.ASSET_LIST,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			const errorMessage = error.response ? error.response.data : 'Network error or server did not respond';
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: errorMessage
			});
		};
	}
}

export function searchAssetsList(type, offset, limit, keyword, callback) {

    return async (dispatch) => {
		
		let criteria = {
			type: type,
			limit: limit,
			offset: offset
		};
		
		if(keyword){
			criteria.keyword = keyword;
		}

		try {
			const request = await axiosCreate().post('/asset/search', criteria);
										
			dispatch({ 
				type: assetsConstants.ASSET_SEARCH,
				payload: request.data.assets
			});
			
			if(callback)
				callback(request.data.total, _.map(request.data.assets, 'id'));
			 			
		} catch(error) {
			const errorMessage = error.response ? error.response.data : 'Network error or server did not respond';
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: errorMessage
			});
		};
	}
}

export function saveCollection(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
		
		try {
						
			if(id){
				request = await axiosCreate().patch(`/collection/save/${id}`, values);
			}else{
				request = await axiosCreate().post('/collection/save', values);
			}

			dispatch({
				type: assetsConstants.COLLECTION_SAVE,
				payload: request
			});

			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('assets:notification_collections_updated_title'),
					i18n.t('assets:notification_collections_updated_description')
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('assets:notification_collections_added_title'),
					i18n.t('assets:notification_collections_added_description')
				));
			}
			
			callback(request.data.collections[0].id);
			dispatch(stopSubmission(formName));

		} catch (error) {

			const errorMessage = error.response ? error.response.data : 'Network error or server did not respond';
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: errorMessage
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function saveAsset(formName, id, values, uploadProgress, callback, callbackError) {

	return async (dispatch) => {

		let request = '';
		
		try {
						
			if(id){
				request = await axiosCreate(uploadProgress).post(`/asset/save/${id}`, values); // post not patch, to support formData
			}else{
				request = await axiosCreate(uploadProgress).post('/asset/save', values);
			}	
			
			let timer = 0;		
					
			if(request.data.collections){
				
				dispatch({
					type: assetsConstants.COLLECTION_SAVE,
					payload: request
				});
				
				timer = 1000; // small delay to allow store to catchup
			}
			
			setTimeout(() => {
			
				dispatch({
					type: assetsConstants.ASSET_SAVE,
					payload: request
				});
				
				if(id){
					dispatch(showNotification(
						'success',
						i18n.t('assets:notification_updated_title'),
						i18n.t('assets:notification_updated_description'),
						request.data.assets[0].thumbnail
					));
				}else{
					
					if(request.data.assets.length > 0){
						
						let count = request.data.assets.length;
						
						dispatch(showNotification(
							'success',
							i18n.t('assets:notification_added_title', { count: count }),
							i18n.t('assets:notification_added_description', { count: count }),
							request.data.assets[0].thumbnail
						));
					}else{
						dispatch(showNotification(
							'success',
							i18n.t('assets:notification_added_title'),
							i18n.t('assets:notification_added_description'),
							request.data.assets[0].thumbnail
						));
					}
				}
				
				if(callback)
					callback(request.data.assets);				
				
				if(formName)
					dispatch(stopSubmission(formName));
			
			}, timer);
			
		} catch (error) {			
			if(callbackError)
				callbackError();
			
			const errorMessage = error.response ? error.response.data : 'Network error or server did not respond';
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: errorMessage
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function toggleAssetHeadline(id, status, callback) {

	return async (dispatch) => {
		
		try {
									
			const request = await axiosCreate().patch(`/asset/save/${id}`, { headline: status });			
		
			dispatch({
				type: assetsConstants.ASSET_HEADLINE,
				payload: request
			});
			
			if(status === 1){
				dispatch(showNotification(
					'success',
					i18n.t('assets:notification_headline_enabled_title'),
					i18n.t('assets:notification_headline_enabled_description'),
					request.data.assets[0].thumbnail
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('assets:notification_headline_disabled_title'),
					i18n.t('assets:notification_headline_disabled_description'),
					request.data.assets[0].thumbnail
				));
			}	
			
			if(callback)
				callback();

		} catch (error) {

			const errorMessage = error.response ? error.response.data : 'Network error or server did not respond';
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: errorMessage
			});
		};
	}
}

export function deleteAsset(id, callback) {

	return async (dispatch) => {
		
		try {
						
			await axiosCreate().delete('/asset/delete');

			dispatch({
				type: assetsConstants.ASSET_DELETE,
				id: id
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('assets:notification_deleted_title'),
				i18n.t('assets:notification_deleted_description')
			));

			if(callback)
				callback();

		} catch (error) {

			const errorMessage = error.response ? error.response.data : 'Network error or server did not respond';
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: errorMessage
			});
		};
	}
}

export function downloadAsset(id, callback) {

    return async (dispatch) => {
		
		try {
			const request = await axiosCreate().post('/asset/download', { files: id });
										
			dispatch({ 
				type: assetsConstants.ASSET_DOWNLOAD,
				payload: request
			});
						
			if(callback)
				callback(request.data.url, request.data.filename)
			 			
		} catch(error) {
			const errorMessage = error.response ? error.response.data : 'Network error or server did not respond';
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: errorMessage
			});
		};
	}
}

export function fetchRemoteVideoDetails(source, id, callback) {

    return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get(`/asset/remote-video?source=${source}&id=${id}`);
										
			dispatch({ 
				type: assetsConstants.REMOTE_VIDEO_DETAILS,
				payload: request
			});
			
			if(callback)
				callback(request.data.data);
			 			
		} catch(error) {

			const errorMessage = error.response ? error.response.data : 'Network error or server did not respond';
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: errorMessage
			});
		};
	}
}

export function saveAssetCrop(id, campaign_id, type, values, callback) {

    return async (dispatch) => {
	    
	    const posting = {
			campaign_id: campaign_id ? campaign_id : false,
			type: type,
			values: values ? {
				x: values.x,
				y: values.y,
				width: values.width,
				height: values.height
			} : false
		};
		
		try {
			const request = await axiosCreate().post(`/asset/crop/${id}`, posting);
										
			dispatch({ 
				type: assetsConstants.ASSET_CROP,
				payload: request
			});
			
			if(campaign_id){
				
				if(values){
					dispatch(showNotification(
						'success',
						i18n.t('assets:notification_crop_campaign_updated_title'),
						i18n.t('assets:notification_crop_campaign_updated_description')
					));
				}else{
					dispatch(showNotification(
						'success',
						i18n.t('assets:notification_crop_campaign_default_title'),
						i18n.t('assets:notification_crop_campaign_default_description')
					));
				}
			
			}else{
				
				if(values){
					dispatch(showNotification(
						'success',
						i18n.t('assets:notification_crop_updated_title'),
						i18n.t('assets:notification_crop_updated_description')
					));
				}else{
					dispatch(showNotification(
						'success',
						i18n.t('assets:notification_crop_default_title'),
						i18n.t('assets:notification_crop_default_description')
					));
				}
			}
			
			if(callback)
				callback(request.data.assets[0].id);
			 			
		} catch(error) {

			const errorMessage = error.response ? error.response.data : 'Network error or server did not respond';
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: errorMessage
			});
		};
	}
}