import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';
import Table from '../../components/chrome/table';
import EmptySection from '../../components/chrome/empty';
import moment from 'moment-timezone';
import 'moment/min/locales';
import _ from 'lodash';
import i18n from '../../helpers/i18n'; 
import { imageResize } from '../../helpers/s3';

import { fetchAssetsList } from '../../actions/assets';
import { fetchCampaignsList, fetchCampaignsImportList, importCampaign } from '../../actions/campaigns';
import { setSectionOptions } from '../../actions/chrome';

import { checkPermission } from '../../helpers/permissions';

class Campaigns extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS')){
			this.props.history.push('/');
		}
		
		moment.locale(i18n.language);
		
		this.props.setSectionOptions('campaigns', i18n.t('campaigns:title'));
		this.props.fetchCampaignsList(false, () => {
			
			// LOAD IN THE FIRST ASSET
			let assets = [];
			
			if(this.props.campaigns.campaigns){
				
				_.forEach(this.props.campaigns.campaigns, (campaign) => {
					
					if(campaign.items && campaign.items.assets && !_.isEmpty(campaign.items.assets)){
						assets.push(campaign.items.assets[0]);
					}
				});
			}
			
			if(this.props.campaigns.import){
				
				_.forEach(this.props.campaigns.import, (campaign) => {
					
					if(campaign.items && campaign.items.assets && !_.isEmpty(campaign.items.assets)){
						assets.push(campaign.items.assets[0]);
					}
				});
			}
			
			this.props.fetchAssetsList(_.uniq(assets));
		});
		
		
		if(checkPermission(this.props.account.permissions, 'CAMPAIGNS_IMPORT')){
			this.props.fetchCampaignsImportList();
		}
		
		let type = 'active';
		
		if(checkPermission(this.props.account.permissions, 'CAMPAIGNS', 'active')){
			type = 'active';
		}else if(checkPermission(this.props.account.permissions, 'CAMPAIGNS', 'upcoming')){
			type = 'upcoming';
		}else if(checkPermission(this.props.account.permissions, 'CAMPAIGNS', 'draft')){
			type = 'draft';
		}else if(checkPermission(this.props.account.permissions, 'CAMPAIGNS', 'deployed')){
			type = 'deployed';
		}else if(checkPermission(this.props.account.permissions, 'CAMPAIGNS', 'completed')){
			type = 'completed';
		}else if(checkPermission(this.props.account.permissions, 'CAMPAIGNS_IMPORT')){
			type = 'import';
		}
		
		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		
		if(queryString.type){
			type = queryString.type;
		}
		
		this.state = {
			type: type,
			search: false,
			filters: {
				type: false,
				status: false,
				keyword: false
			}
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	onChangeType(type){
		this.setState({
			type: type,
		});
	}
	
	filterData(type){
		
		let now = moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
		let campaigns = this.props.campaigns.campaigns;
		
		if(this.props.campaigns.import && !_.isEmpty(this.props.campaigns.import)){
			campaigns = {...campaigns, ..._.mapValues(this.props.campaigns.import, function(val, key) {
			    val.import = true;
			    return val;
			})};
		}
				
		let data = {
			active: [],
			upcoming: [],
			draft: [],
			completed: [],
			deactivated: [],
			import: [],
			deployed: [],
			deployed_pending: []
		};
				
		// APPLY FILTERS
		if(this.state.search){
			
			if(this.state.filters.keyword){
				campaigns = _.filter(campaigns, campaign => (campaign.internal_name.toLowerCase().includes(this.state.filters.keyword.toLowerCase()) ));
			}
			
			
			// TODO
			
			
			
		}
				
		if(type === 'counter' || this.state.type === 'active'){
			data.active = _.filter(campaigns, campaign => (!campaign.import && (campaign.status === 'launched' || campaign.status === 'deployed') && moment(campaign.date_start.date).format('YYYY-MM-DD HH:mm:ss') <= now && moment(campaign.date_end).format('YYYY-MM-DD HH:mm:ss') >= now));

		}
		
		if(type === 'counter' || this.state.type === 'upcoming'){
			data.upcoming = _.filter(campaigns, campaign => (!campaign.import && (campaign.status === 'launched' || campaign.status === 'deployed') && moment(campaign.date_start.date).format('YYYY-MM-DD HH:mm:ss') > now));
		}
		
		if(type === 'counter' || this.state.type === 'draft'){
			data.draft = _.filter(campaigns, campaign => (!campaign.import && campaign.status === 'draft') );
		}
		
		if(type === 'counter' || this.state.type === 'completed'){
			data.completed = _.filter(campaigns, campaign => (!campaign.import && (campaign.status === 'launched' || campaign.status === 'deployed') && moment(campaign.date_end).format('YYYY-MM-DD HH:mm:ss') < now));
		}
		
		if(type === 'counter' || this.state.type === 'deactivated'){
			data.deactivated = _.filter(campaigns, campaign => campaign.status === 'deactivated');
		}
		
		if(type === 'counter' || this.state.type === 'import'){
			data.import = _.filter(campaigns, campaign => (campaign.import));
		}
		
		if(type === 'counter' || this.state.type === 'deployed'){
			data.deployed = _.filter(campaigns, campaign => (!campaign.import && campaign.status === 'deployed'));
		}
		
		if(type === 'counter' || this.state.type === 'deployed_pending'){
			data.deployed_pending = _.filter(campaigns, campaign => (!campaign.import && campaign.status === 'deploy'));
		}
		
		if(type === 'counter'){
			
			return {
				active: data.active.length,
				upcoming: data.upcoming.length,
				draft: data.draft.length,
				completed: data.completed.length,
				deactivated: data.deactivated.length,
				import: data.import.length,
				deployed: data.deployed.length,
				deployed_pending: data.deployed_pending.length
			};
					
		}else{
			return data[this.state.type];
		}
	}
	
	renderTable(){

		let data = this.filterData();
				
		if(_.isEmpty(data)){
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t(`campaigns:empty_${this.state.type}_title`)}
					description={i18n.t(`campaigns:empty_${this.state.type}_description`)}
					cta={{
						label: i18n.t('campaigns:action_add'),
						url: '/campaigns/add'
					}}
				/>
			);			
		}
		
		let performance = false;
		let actions = [];
		let rowClick = (row) => {
			this.props.history.push(`/campaigns/${row.id}`);
		};
		
		let cols = [
			{
				key: "image",
				label: "",
				format: 'image_circle',
				className: 'tight'
			},
			{
				key: "name",
				label: "Name",
				sortable: true
			},
			{
				key: "type",
				label: "Type",
				sortable: true
			},
			{
				key: "date_start",
				label: "Start",
				sortable: true,
				format: 'datetime'
			},
			{
				key: "date_end",
				label: "End",
				sortable: true,
				format: 'datetime'
			}
		];
				
		switch(this.state.type){
			
			case 'active':
				//performance = true;
				actions = ['analytics'];
				
				cols.push({
					key: "events",
					label: "Events",
					cols: [{
						key: "events_pending",
						label: "Pending",
						sortable: true,
						format: "image_pre",
						highlight: {
							color: '#FF8C00',
							rule: 'greater_zero'
						}
					},
					{
						key: "events_active",
						label: "Active",
						sortable: true,
						highlight: {
							color: '#32CD32',
							rule: 'greater_zero'
						}
					},
					{
						key: "events_failed",
						label: "Failed",
						sortable: true,
						highlight: {
							color: '#FF3333',
							rule: 'greater_zero'
						}
					},
					{
						key: "events_completed",
						label: "Completed",
						sortable: true
					}]
				});
			
				break;
				
			case 'upcoming':
				actions = ['view'];
				
				cols.push({
					key: "events_pending",
					label: "Events",
					sortable: true,
					format: "image_pre",
					highlight: {
						color: '#FF8C00',
						rule: 'greater_zero'
					}
				});
				
				break;
				
			case 'draft':
				actions = ['edit'];
				
				cols.push({
					key: "events_pending",
					label: "Events",
					sortable: true,
					format: "image_pre",
					highlight: {
						color: '#FF8C00',
						rule: 'greater_zero'
					}
				});
				break;
				
			case 'completed':
				//performance = true;
				actions = ['analytics'];
				
				cols.push({
					key: "events",
					label: "Events",
					cols: [{
						key: "events_failed",
						label: "Failed",
						sortable: true,
						highlight: {
							color: '#FF3333',
							rule: 'greater_zero'
						}
					},
					{
						key: "events_completed",
						label: "Completed",
						sortable: true
					}]
				});
				
				break;
				
			case 'deactivated':
				//performance = true;
				actions = ['analytics'];
				
				cols.push({
					key: "date_deactivated",
					label: "Deactivated",
					sortable: true,
					format: 'datetime'
				})
				break;
				
			case 'import':
				actions = ['import'];
				rowClick = false;
				
				cols.push({
					key: "events_total",
					label: "Events",
					sortable: true
				});
				break;
				
			case 'deployed':
				actions = ['analytics'];
				
				cols.push({
					key: "events_total",
					label: "Events",
					sortable: true
				});
				break;
				
			case 'deployed_pending':
				cols.push({
					key: "date_process",
					label: "Deployment Date",
					sortable: true,
					format: 'datetime'
				});
				
				actions = ['view'];
				
				cols.push({
					key: "events_total",
					label: "Events",
					sortable: true
				});
				break;
				
			default:
			
				break;
		}
		
		if(performance){
			cols.push({
				key: "performance",
				label: "Performance",
				sortable: true,
				format: 'percent_bar'
			});
		}
		
		cols.push({
			key: "actions",
			label: "Actions",
			className: "tight right"
		});
		
		return (
			<div className="c-card">
				<Table 
					columns={cols}
					data={_.map(data, (campaign, key) => {
									
						let image = false;	
						let url = false;
						
						if(campaign.items){
										
							if(this.props.assets.assets && campaign.items.assets && !_.isEmpty(campaign.items.assets) && this.props.assets.assets[campaign.items.assets[0]]){
								let asset = this.props.assets.assets[campaign.items.assets[0]];
								image = imageResize(asset.thumbnail, 100, 100);	
								url = `${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/asset/${asset.id}`;				
							}
						}
						
						let events_total = campaign.events ? campaign.events.length : 0;
						let events_pending = 0;
						let events_failed = 0;
						let events_active = 0;
						let events_completed = 0;
						let events_issues = 0;
						
						if(campaign.events){
							
							_.forEach(campaign.events, (event, key) => {	
								
								switch(event.status){
									
									case 'pending':
										++events_pending;
																			
										if(event.issues){
											++events_issues;
										}
										break;
										
									case 'failed':
										++events_failed;
										break;
										
									case 'complete':
										++events_completed;
										break;
										
									case 'active':
										++events_active;
										break;
										
									default:
									
										break;
								}								
							});
						}
										
						return ({
							id: campaign.id,
							image: {
								src: image,
								icon: 'fal fa-hashtag',
								alt: campaign.internal_name,
								url: url
							},
							name: campaign.internal_name,
							type: i18n.t(`campaigns:campaign_type_${campaign.type}`),
							date_start: campaign.date_start.date,
							date_end: campaign.date_end,
							date_process: campaign.date_process ? campaign.date_process.date : false,
							date_deactivated: campaign.date_deactivated ? campaign.date_deactivated.date : false,
							events_total: events_total,
							events_pending: {
								value: events_pending,
								icon: events_issues > 0 ? 'fal fa-exclamation-triangle' : false,
								color: '#FF3333'
							},
							events_failed: events_failed,
							events_active: events_active,
							events_completed: events_completed,
							events_issues: events_issues,
							
							performance: campaign.performance,
							actions: actions								
						});
					})}
					actions={{
						edit: {
							tooltip: i18n.t('campaigns:action_edit'),
							icon: 'fal fa-edit',
							url: '/campaigns/[ID]'
						},
						view: {
							tooltip: i18n.t('campaigns:action_view'),
							icon: 'fal fa-eye',
							url: '/campaigns/[ID]'
						},
						analytics: {
							tooltip: i18n.t('campaigns:action_analytics'),
							icon: 'fal fa-analytics',
							url: '/campaigns/[ID]/analytics'
						},
						import: {
							label: i18n.t('campaigns:action_import'),
							onClick: (id, e) => {
								this.props.importCampaign(id, (id) => {
									this.props.history.push(`/campaigns/${id}`);
								});
							}
						}
					}}
					sort={{
						column: 'name',
						order: 'asc'
					}}
					rowClick={rowClick}
				/>
			</div>
		);
	}

	render() {

		let { campaigns } = this.props;

		if(!campaigns.campaigns){
			return (
				<Loading />
			);
		}
		
		let counter = this.filterData('counter');
		let tabs = [];
		
		if(checkPermission(this.props.account.permissions, 'CAMPAIGNS', 'active')){
			tabs.push({
				label: i18n.t('campaigns:toolbar_active'),
				counter: String(counter.active),
				onClick: () => { this.onChangeType('active') },
				selected: this.state.type === 'active' ? true : false
			});
		}
		
		if(checkPermission(this.props.account.permissions, 'CAMPAIGNS', 'upcoming')){
			tabs.push({
				label: i18n.t('campaigns:toolbar_upcoming'),
				counter: String(counter.upcoming),
				onClick: () => { this.onChangeType('upcoming') },
				selected: this.state.type === 'upcoming' ? true : false
			});
		}
		
		if(checkPermission(this.props.account.permissions, 'CAMPAIGNS', 'draft')){
			tabs.push({
				label: i18n.t('campaigns:toolbar_draft'),
				counter: String(counter.draft),
				onClick: () => { this.onChangeType('draft') },
				selected: this.state.type === 'draft' ? true : false
			});
		}
		
		if(checkPermission(this.props.account.permissions, 'CAMPAIGNS', 'deployed')){
			tabs.push({
				label: i18n.t('campaigns:toolbar_deployed'),
				counter: String(counter.deployed),
				onClick: () => { this.onChangeType('deployed') },
				selected: this.state.type === 'deployed' ? true : false
			});
			
			tabs.push({
				label: i18n.t('campaigns:toolbar_deployed_embargo'),
				counter: String(counter.deployed_pending),
				onClick: () => { this.onChangeType('deployed_pending') },
				selected: this.state.type === 'deployed_pending' ? true : false
			});
		}
		
		if(checkPermission(this.props.account.permissions, 'CAMPAIGNS', 'completed')){
			tabs.push({
				label: i18n.t('campaigns:toolbar_completed'),
				counter: String(counter.completed),
				onClick: () => { this.onChangeType('completed') },
				selected: this.state.type === 'completed' ? true : false
			});
		}
		
		if(checkPermission(this.props.account.permissions, 'CAMPAIGNS', 'deactivated')){
			tabs.push({
				label: i18n.t('campaigns:toolbar_deactivated'),
				counter: String(counter.deactivated),
				onClick: () => { this.onChangeType('deactivated') },
				selected: this.state.type === 'deactivated' ? true : false
			});
		}
		
		if(checkPermission(this.props.account.permissions, 'CAMPAIGNS_IMPORT')){
			
			tabs.push({
				label: i18n.t('campaigns:toolbar_import'),
				counter: String(counter.import),
				onClick: () => { this.onChangeType('import') },
				selected: this.state.type === 'import' ? true : false
			})
		}

		return (
			<React.Fragment>
							
				<Toolbar
					tabs={tabs}
					buttons={[
						{
							label: i18n.t('campaigns:action_add'),
							url: '/campaigns/add'
						}
					]}
					filter={{
						toggleFunc: (status) => {
							this.setState({
								search: status
							});
						},
						onChangeFunc: (event, type) => { 
							
							let filters = this.state.filters;
							
							switch(type){
								
								case 'keyword':
									filters.keyword = event.target.value;
									break;
									
								case 'type':
								case 'status':
									filters[type] = event.value;
									break;	
								
								default:
									return;
							}
					
							this.setState({
								filters: filters
							})
						},
						fields: [
							/*{
								placeholder: i18n.t('campaigns:filter_type'),
								name: "type",
								type: 'suggest',
								options: [
									{
										value: 1,
										label: 'Item 1'
									},
									{
										value: 2,
										label: 'Item 2'
									}
								]
							},
							{
								placeholder: i18n.t('campaigns:filter_status'),
								name: "status",
								type: 'suggest',
								options: [
									{
										value: 1,
										label: 'Item 1'
									},
									{
										value: 2,
										label: 'Item 2'
									}
								]
							},*/
							{
								placeholder: i18n.t('campaigns:filter_keyword'),
								name: "keyword",
								type: 'text'
							}
						],
						focus: 'keyword'
					}}
				/>	
								
				<div className="container">	
					{this.state.search}
					{this.renderTable()}
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ campaigns, assets, account }, ownProps){
	return {
		campaigns,
		assets,
		account
	};
}

export default connect(mapStateToProps, { 
	fetchCampaignsList,
	setSectionOptions,
	fetchAssetsList,
	fetchCampaignsImportList,
	importCampaign
})(Campaigns);